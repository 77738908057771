export const actionpermission=(page)=>{
    let permissionsArray = []
    let permissionsLocal = JSON.parse(localStorage.getItem('role_permission')).action_permission
    let filteredPermssions = permissionsLocal.filter(perm=>{
      return perm.includes(page)
    })
    filteredPermssions.forEach(perm=>{
      let split = perm.split(' - ')
      permissionsArray.push(split[1])
    })
    return permissionsArray
}
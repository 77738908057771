import Api from '../utils/Api';
export const getShareLink = async (screen, item, eventTitle) => {
    let shareURL;
    try {
        shareURL = await buildLink(screen,item);
        return shareURL
    } catch (error) {
        console.log("error", error);
    }
    
};
const buildLink = async (screen,item) => {
    const res = await Api.POSTSHARELINK(screen, item);
    let base_url = ''
    if(!window.location.href.includes('https://app.medisetter.com/')){
        base_url = 'https://staging-web-app-udxaienpqq-uc.a.run.app/redirectpost/?url=';
    }else{
        base_url = 'https://app.medisetter.com/redirectpost/?url=';
    }
    // return res.data.shortLink
    // const res = await Api.POSTSHARELINK(id,screen);
    let reeee = res.data.shortLink.replace('https:','')
  //  let reeee = res.data.shortLink
    let temp  = `${base_url}${reeee}`
    // return res.data.shortLink
    return temp
};










import React, { useState, useRef, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import LeftSideBar from '../../SideBar/LeftSideBar'
import Button from 'react-bootstrap/Button';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'react-quill/dist/quill.snow.css';
import './Events.css';
import { MultiSelect } from 'react-multi-select-component';
import Multiselect from 'multiselect-react-dropdown';
import * as AiIcons from 'react-icons/ai';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { apiCall } from '../../../../action/loginAction';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const EditUserManagementUser = (props) => {
    const { state } = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [userData, setUserData] = useState({})
    const [fullName, setFullName] = useState('')
    // enterprise user
    const [fullCompanyName, setFullCompanyName] = useState('')
    const [emailAddress, setEmailAddress] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [officeAddress, setOfficeAddress] = useState('')
    const [emailAddressPersonal, setEmailAddressPersonal] = useState('')
    const [designation, setDesignation] = useState('')
    const [roles, setRoles] = useState([])
    const [firstNameCoAdmin, setFirstNameCoAdmin] = useState('')
    const [lastNameCoAdmin, setLastNameCoAdmin] = useState('')
    const [logoImageUrl, setLogoImageUrl] = useState('');
    const [logoImage, setLogoImage] = useState({ fileName: '', media_id: '' });
    const email = localStorage.getItem('email');
    const [islogoImageUrl, setIslogoImageUrl] = useState(false)
    const [oldEmail, setOldEmail] = useState('')
    const [changedRole, setChangedRole] = useState([])
    const regex = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/

    // get data
    useEffect(() => {
        let rolesLocal = JSON.parse(localStorage.getItem('role_permission')).internal_permission.ADD_USER
        setRoles(rolesLocal);
        if (state.data.role === 'ENTERPRISE') {
            setFullCompanyName(state.data?.company_name)
            setLogoImageUrl(state.data?.company_logo)
            setOfficeAddress(state.data.office_address)
            setFullName(state.data?.contact_person_name)
            setEmailAddressPersonal(state.data?.contact_person_email)
            setDesignation(state.data?.contact_person_designation)
        } else if (state.data.role === 'CO-ADMIN' || state.data.role === 'GUEST') {
            setFirstNameCoAdmin(state.data.first_name)
            setLastNameCoAdmin(state.data.last_name)
        }
        setEmailAddress(state.data?.email)
        setPhoneNumber(state.data?.phone)
        setChangedRole(state.data.role)
        setOldEmail(state.data.email)
    }, [userData])
    const userRoleSelected = (item) => {
        if (changedRole === item) {
            setChangedRole('GUEST')
        } else {
            setChangedRole(item)
        }
    }
    const addUser = async () => {
        if (changedRole == 'CO-ADMIN' || changedRole === 'GUEST') {

            if (emailAddress && firstNameCoAdmin && lastNameCoAdmin && regex.test(emailAddress)) {
                var data = {
                    is_edit_user: true,
                    email: oldEmail,
                    new_email_id: oldEmail === emailAddress ? null : emailAddress,
                    role_name: changedRole,
                    preferred_language: "EN",
                    first_name: firstNameCoAdmin,
                    last_name: lastNameCoAdmin,
                    phone: phoneNumber
                }
                let res = await dispatch(apiCall(data,'/user/add-user'));
                if (res.data.message.MSG_CODE === 100861) {
                    Swal.fire({
                        icon: 'success',
                        text: 'User editted successfully',
                        timer: 3000
                    })
                    navigate('/admin/user-management')
                } else if (res.data.message.MSG_CODE === 100950) {
                    Swal.fire({
                        icon: 'error',
                        text: "User Information Not Edited",
                        timer: 3000
                    })

                } else if (res.data.message.MSG_CODE === 100380) {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Channel name already exist please change it',
                        timer: 3000
                    })
                } else if (res.data.message.MSG_CODE === 100620) {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Channel name already exist please change it',
                        timer: 3000
                    })
                } else if (res.data.message.MSG_CODE === 100010) {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Details not entered correctly',
                        timer: 3000
                    })
                }
            } else {
                if (!emailAddress) {
                    Swal.fire({
                        icon: 'error',
                        text: 'Fill email address',
                        timer: 4000
                    })
                } else if (!firstNameCoAdmin) {
                    Swal.fire({
                        icon: 'error',
                        text: 'Fill first name',
                        timer: 4000
                    })
                } else if (!lastNameCoAdmin) {
                    Swal.fire({
                        icon: 'error',
                        text: 'Fill last name',
                        timer: 4000
                    })
                } else if (!regex.test(emailAddress)) {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Email not entered correctly',
                        timer: 3000
                    })
                }

            }
        } else if (changedRole === 'ENTERPRISE') {

            if (fullCompanyName !== '' && emailAddress !== '' && regex.test(emailAddress))  {

                let data = {
                    is_edit_user: true,
                    email: oldEmail,
                    new_email_id: oldEmail === emailAddress ? null : emailAddress,
                    company_name: fullCompanyName,
                    phone: phoneNumber,
                    office_address: officeAddress,
                    role_name: changedRole,
                    preferred_language: "EN",
                    contact_person_name: fullName,
                    contact_person_email: emailAddressPersonal,
                    contact_person_designation: designation,
                    logo_image: logoImage.media_id,
                }
                let res = await dispatch(apiCall(data,'/user/add-user'));
                if (res.data.message.MSG_CODE === 100861) {
                    Swal.fire({
                        icon: 'success',
                        text: 'User editted successfully',
                        timer: 3000
                    })
                    navigate('/admin/user-management')
                } else if (res.data.message.MSG_CODE === 100950) {
                    Swal.fire({
                        icon: 'error',
                        text: "User Information Not Edited",
                        timer: 3000
                    })
                }
            } else {
                if (fullCompanyName === '') {
                    Swal.fire({
                        icon: 'error',
                        text: 'Fill company name',
                        timer: 4000
                    })
                } else if (emailAddress === '') {
                    Swal.fire({
                        icon: 'error',
                        text: 'Fill email address',
                        timer: 4000
                    })
                }  else if (!regex.test(emailAddress)) {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Email not entered correctly',
                        timer: 3000
                    })
                }

            }
        }

    }
    const goBack = () => {
        navigate('/admin/user-management')
    }
    // base64 conversion
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = (() => {
                resolve(fileReader.result);
            });
            fileReader.onerror = ((error) => {
                reject(error)
            });
        });
    };
    const uploadFile = async (e) => {
        setLogoImage({ fileName: e.name })
        const convertString = await convertBase64(e);
        const base64Array = convertString.split(",");
        const base64 = base64Array[1];
        var data = {
            email: email,
            file_path: "channel",
            file_data: base64,
            file_name: e.name,
        };
        let res = await dispatch(apiCall(data,'/user/upload'));
        if (res.data.message.MSG_CODE === 100231) {

            setLogoImage({ media_id: res.data.data.media_id })
            setLogoImageUrl(res.data.data.url)

        } else if (res.data.message.MSG_CODE === 100270) {
            Swal.fire({
                icon: 'error',
                text: 'Not valid extension please upload again (accept only JPEG, PNG, JPG)',
                timer: 3000
            })
        } else if (res.data.message.MSG_CODE === 100580 || res.data.message.MSG_CODE === 100010) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please upload again',
                timer: 3000
            })
        }
        else if (res.data.message.MSG_CODE === 100580 || res.data.message.MSG_CODE === 100010) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please upload again',
                timer: 3000
            })
        }
    }
    return (
        <>
            <section>
                <Row style={{ height: '100vh' }}>
                    <Col className='' xxl={3} xl={3} lg={3} md={4} style={{ background: 'linear-gradient(90deg, #277AFC 0%, #27B3FC 100%)', height: '100%' }} >
                        <LeftSideBar />
                    </Col>
                    <Col className='bg-prime p-0 eventScreen' xxl={9} xl={9} lg={9} md={8}>
                        <Container className='log-wrap p-0 d-flex justify-content-center eventScreenPage' fluid style={{ height: '100vh' }}>
                            <>
                                <section className='w-50'>
                                    <div className='eventScreenHeading'>
                                        <h4>Edit {changedRole} user's information </h4>
                                    </div>                        <Row>
                                        <div className='d-flex align-items-center'>
                                        </div>
                                        <Col xxl={12} xl={12} lg={12} md={12}>
                                            <DropdownButton id="dropdown-basic-button px-1" title={changedRole.length > 10 ? changedRole.slice(0, 10) + '...' : changedRole} className='mb-2'>
                                                {
                                                    roles && roles.length > 0 && roles.map((item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <Dropdown.Item className={changedRole === item ? 'active' : ''} onClick={() => userRoleSelected(item)} >{item}</Dropdown.Item>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </DropdownButton>
                                            {changedRole === 'ENTERPRISE' &&
                                                <div className='userManagmentScreenBox'>
                                                    <div className='my-1'>
                                                        <p>
                                                            Full name of company <span className="redStar">&#42;</span>
                                                        </p>
                                                        <input type="text" className="form-control"
                                                            placeholder="Company name" aria-label="Title"
                                                            aria-describedby="basic-addon1"
                                                            value={fullCompanyName}
                                                            onChange={(e) => setFullCompanyName(JSON.parse(JSON.stringify(e.target.value)))}
                                                        />
                                                    </div>

                                                    <div className='my-2'>
                                                        <div className='d-flex justify-content-between'>
                                                            <p>Update logo (accept only jpeg and png )
                                                                <span className='mx-3'>{logoImageUrl ? <a href={logoImageUrl} target="_blank" rel="noreferrer">Preview</a> : ''}</span>
                                                            </p>
                                                            <div onClick={() => { setIslogoImageUrl(!islogoImageUrl) }} style={{ color: 'green', cursor: 'pointer' }} className='px-4'>
                                                                <AiIcons.AiFillEdit />
                                                            </div>
                                                        </div>
                                                        {
                                                            islogoImageUrl === true ?
                                                                <input type="file" accept="image/png, image/jpeg" className="form-control" aria-describedby="basic-addon1" onChange={(event) => uploadFile(event.target.files[0])} />
                                                                : null
                                                        }
                                                    </div>
                                                    <div className='my-1'>
                                                        <p>
                                                            Email Address of Company <span className="redStar">&#42;</span>
                                                        </p>
                                                        <input type="text" className="form-control"
                                                            placeholder="Email address " aria-label="Title"
                                                            aria-describedby="basic-addon1"
                                                            value={emailAddress}
                                                            onChange={(e) => setEmailAddress(JSON.parse(JSON.stringify(e.target.value)))}
                                                        />
                                                    </div>

                                                    <div className='my-1'>
                                                        <p>
                                                            Phone Number of Company
                                                        </p>

                                                        <PhoneInput

                                                            country={'vn'}
                                                            placeholder="Enter phone number"
                                                            value={phoneNumber}
                                                            onChange={setPhoneNumber} />
                                                    </div>
                                                    <div className='my-1'>
                                                        <p>
                                                            Office Address
                                                        </p>
                                                        <input type="text" className="form-control"
                                                            placeholder="Office address " aria-label="Title"
                                                            aria-describedby="basic-addon1"
                                                            value={officeAddress}
                                                            onChange={(e) => setOfficeAddress(JSON.parse(JSON.stringify(e.target.value)))}
                                                        />
                                                    </div>
                                                    <hr />
                                                    <div className='my-1'>
                                                        <p>
                                                            Full name of contact person
                                                        </p>
                                                        <input type="text" className="form-control"
                                                            placeholder="Full name" aria-label="Title"
                                                            aria-describedby="basic-addon1"
                                                            value={fullName}
                                                            onChange={(e) => setFullName(JSON.parse(JSON.stringify(e.target.value)))}
                                                        />
                                                    </div>
                                                    <div className='my-1'>
                                                        <p>
                                                            Email Address of contact person
                                                        </p>
                                                        <input type="text" className="form-control"
                                                            placeholder="Email address" aria-label="Title"
                                                            aria-describedby="basic-addon1"
                                                            value={emailAddressPersonal}
                                                            onChange={(e) => setEmailAddressPersonal(JSON.parse(JSON.stringify(e.target.value)))}
                                                        />
                                                    </div>
                                                    <div className='my-1'>
                                                        <p>
                                                            Designation of contact person
                                                        </p>
                                                        <input type="text" className="form-control"
                                                            placeholder="Designation" aria-label="Title"
                                                            aria-describedby="basic-addon1"
                                                            value={designation}
                                                            onChange={(e) => setDesignation(JSON.parse(JSON.stringify(e.target.value)))}
                                                        />
                                                    </div>
                                                    <div className='my-3' style={{ float: 'right' }}>
                                                        <Button className='mx-2' onClick={goBack} style={{ background: 'white', color: 'blue' }}>Close</Button>
                                                        <Button onClick={addUser}>Save</Button>
                                                    </div>
                                                </div>
                                            }
                                            {(changedRole === 'CO-ADMIN' || changedRole === 'GUEST') &&
                                                <div className='userManagmentScreenBox'>
                                                    <div className='my-1'>
                                                        <p>
                                                            Email of Co-Admin <span className="redStar">&#42;</span>
                                                        </p>
                                                        <input type="text" className="form-control"
                                                            placeholder="Email address" aria-label="Title"
                                                            aria-describedby="basic-addon1"
                                                            value={emailAddress}
                                                            onChange={(e) => setEmailAddress(JSON.parse(JSON.stringify(e.target.value)))}
                                                        />
                                                    </div>
                                                    <div className='my-1'>
                                                        <p>
                                                            Phone Number of Co-Admin
                                                        </p>

                                                        <PhoneInput

                                                            country={'vn'}
                                                            placeholder="Enter phone number"
                                                            value={phoneNumber}
                                                            onChange={setPhoneNumber} />
                                                    </div>
                                                    <div className='my-1'>
                                                        <p>
                                                            First Name <span className="redStar">&#42;</span>
                                                        </p>
                                                        <input type="text" className="form-control"
                                                            placeholder="First name " aria-label="Title"
                                                            aria-describedby="basic-addon1"
                                                            value={firstNameCoAdmin}
                                                            onChange={(e) => setFirstNameCoAdmin(JSON.parse(JSON.stringify(e.target.value)))}
                                                        />
                                                    </div>


                                                    <div className='my-1'>
                                                        <p>
                                                            Last Name <span className="redStar">&#42;</span>
                                                        </p>
                                                        <input type="text" className="form-control"
                                                            placeholder="Last name " aria-label="Title"
                                                            aria-describedby="basic-addon1"
                                                            value={lastNameCoAdmin}
                                                            onChange={(e) => setLastNameCoAdmin(JSON.parse(JSON.stringify(e.target.value)))}
                                                        />
                                                    </div>

                                                    <div className='my-3' style={{ float: 'right' }}>
                                                        <Button className='mx-2' onClick={goBack} style={{ background: 'white', color: 'blue' }}>Close</Button>
                                                        <Button onClick={addUser}>Save</Button>
                                                    </div>
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </section>
                            </>
                        </Container>
                    </Col>
                </Row>
            </section>
        </>
    )
}
export default EditUserManagementUser
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import LeftSideBar from '../../SideBar/LeftSideBar';
import './UserManagment.css';
import { apiCall } from '../../../../action/loginAction';
import Swal from 'sweetalert2';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AllUsers from './UserMangement/AllUsers';
import AddRole from './UserMangement/AddRole';
import AddPermission from './UserMangement/AddPermission';
import { actionpermission } from '../../../../constant/commonFunctions'
import Unauthorized from './Unauthorized';
const UserManagement = () => {
  const dispatch = useDispatch();
  const [userManagementData, setUserManagementData] = useState(['Change Password', 'All User', 'Add New User', 'Add Permission', 'All Role']);
  const [isChangePasswordModal, setIsChangePasswordModal] = useState(false);
  const [passwordData, setPassWordData] = useState({ oldPassword: '', newPassword: '', confirmPassword: '' })
  const [showOldPassInput, setShowOldPassInput] = useState(false);
  const [showNewPassInput, setShowNewPassInput] = useState(false);
  const [showConfirmPassInput, setShowConfirmPassInput] = useState(false);
  const [permissions, setPermissions] = useState([])
  const [userType,setUserType] = useState("Admin");
  useEffect(() => {
    let permissionsArray = actionpermission("User Management")
    checkRole()
    setPermissions(permissionsArray)
  }, [])
  const checkRole = async () => {
    var data = {
      preferred_language: 'EN'
    };
    let res = await dispatch(apiCall(data,'/user/role-management'));
    if (res.data.message.MSG_CODE === 100130){
      setUserType("Enterprise")
    }
  }
  const empty = () => {
    setShowOldPassInput(false);
    setShowNewPassInput(false);
    setShowConfirmPassInput(false);
    setIsChangePasswordModal(false);
    setPassWordData({ oldPassword: '', newPassword: '', confirmPassword: '' })
  }
  return (
    <>
      <section>
        <Row style={{ height: '100vh' }}>
          <Col className='' xxl={2} xl={2} lg={3} md={3} style={{ background: 'linear-gradient(90deg, #277AFC 0%, #27B3FC 100%)', height: '100%' }} >
            <LeftSideBar />
          </Col>
          {
            userType === "Admin" ? (        
            <Col className='bg-prime p-0 managmentScreen' xxl={10} xl={10} lg={9} md={9}>
            <Container className='log-wrap p-0' fluid style={{ height: '100%' }}>
              <>
                <section className='px-3 pt-3' style={{ height: '100%' }} >
                  <div style={{ height: '8%' }}>
                    <h5>User Managment</h5>
                    <hr></hr>
                  </div>
                  <Row className='w-100' style={{ height: '92%' }}>
                    <Tabs
                      defaultActiveKey="allUser"
                      id="justify-tab-example"
                      className=""
                      justify
                    >
                      <Tab eventKey="allUser" title="All Users" className='tabClassDiv'>
                        <AllUsers />
                      </Tab>
                      <Tab eventKey="role" title="Add Role">
                        <AddRole />
                      </Tab>
                      <Tab eventKey="permission" title="Permissions">
                        <AddPermission />
                      </Tab>
                    </Tabs>
                  </Row>
                </section>
              </>
            </Container>
          </Col>) : (
            <Col className='bg-prime p-0 managmentScreen' xxl={10} xl={10} lg={9} md={9}>
              <Unauthorized/>
              </Col>
          )
          }
  
        </Row>
      </section>
    </>

  )
}

export default UserManagement
import React, { useEffect, useState, useRef } from 'react';
import image from '../../../../../constant/image';
import './AddRole.css'
import moment from 'moment';
import Button from 'react-bootstrap/Button';

const EditRole = ({editRolevalue, role, editRoleval, roleNamesList}) => {
  const [isExist, setIsExist] = useState(false);
  const [roleName, setRoleName] = useState(role.role_name);
  const [roleNameList, setRoleListName] = useState(roleNamesList);
    useEffect(() => {
        if (roleName !== ''){
        checkValuePresent(roleName);
        }else{
          setIsExist(false)
        }
    }, [roleName])
        // on key change
    const checkValuePresent = (val) => {
        let isInclude = roleNameList.includes(val)
        if (isInclude === true) {
        setIsExist(true)
        } else {
        setIsExist(false)
        }
    }
    return (
      <>
        <td className='w-25'>
          <div className='d-flex align-items-center'>
            <img role='button' src={image.porfilePicture} alt='logo' style={{ height: '2rem', width: '2rem', borderRadius: '50%', marginRight: '1em' }} />
            <input type="text" placeholder="Title"
              aria-label="Title" aria-describedby="basic-addon1"
              value={roleName}
              className={`form-control ${isExist ? 'reBorderClass' : ''}`}
              onChange={(e) => setRoleName(e.target.value.toUpperCase())}
              />
            </div>
            <div className='warningRight'>{isExist && <span className="redStar m-0 p-0">Role title should be unique</span>}</div>

        </td>
        <td className=' w-25'>{moment(role.created_on).format('MM/DD/YYYY')}</td>
        <td className=' w-25'>{role.requested_by}</td>
        <td className='w-25 text-center'>
          <Button className={` mx-2 btn-sm ${isExist ? 'disabled' : ''}`} onClick = {() => editRolevalue(roleName,role.role_id)} >
            update
          </Button>
          <Button className='btn-sm' onClick = {() => editRoleval(role.role_id)}>
            Cancel
          </Button>
        </td>
      </>
    )
}

  export default EditRole

import React, { useEffect, useState, useRef } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import LeftSideBar from '../../SideBar/LeftSideBar';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import './Analytics.css'

const PostStatistics = () => {



  return (
    <>
      <section>
        <Row style={{ height: '100vh' }} xxl={12} xl={12} lg={12} md={12}>
          <Col className='' xxl={3} xl={3} lg={4} md={4} style={{ background: 'linear-gradient(90deg, #277AFC 0%, #27B3FC 100%)', height: '100%' }} >
            <LeftSideBar />
          </Col>
          <Col className='bg-prime p-0 adminPannel' xxl={9} xl={9} lg={8} md={8}>
            <Container className='log-wrap p-0' fluid style={{ height: '100vh' }}>
              <>
              
                <section className='px-3 pt-3 p-4' style={{ height: '100%' , overflow:'scroll' }} >
                <div>
                    <h3>Post statistics</h3>
                    <hr></hr>
                  </div>
                  {/* <Table hover striped size="sm" className='table-responsive'>
                          <thead>
                            <tr style={{textAlign:'center'}}>
                              <th className='5' >Sr. no</th>
                              <th className='10' >User</th>
                              <th className='25' style={{width:'25%'}}>Content</th>
                              <th className='10' >Views</th>
                              <th className='10' >Likes</th>
                              <th className='10'>Comments</th>
                              <th className='10' >Share</th>
                              <th className='10' >Creation Date</th>
                              <th className='10'>Update Date</th>
                              
                            </tr>
                          </thead>

                        </Table> */}
                    <h4 style={{textAlign:'center'}}>NO DATA AVAILABLE</h4>
                </section>
              </>
            </Container>
          </Col>
        </Row>
      </section>
    </>
  )
}

export default PostStatistics
import React, { useState, useRef, useEffect } from 'react'


const PrivacyPolicyVt = () =>{
    return(
        <>
            <div className='mx-5 my-4' style={{textAlign : 'justify'}}>
                <h4 className='d-flex justify-content-center fs-1'>
                Chính sách bảo mật
                </h4>
                <div className='mt-2 '>
                CÔNG TY CỔ PHẦN MEDISETTER VIỆT NAM đã xây dựng ứng dụng Medisetter dưới dạng
ứng dụng miễn phí. Dịch vụ này do CÔNG TY CỔ PHẦN MEDISETTER VIỆT NAM cung cấp
miễn phí và được thiết kế để sử dụng như mục đích ban đầu.
Trang này được sử dụng để thông báo cho người dùng truy cập về các chính sách của chúng tôi
liên quan đến việc thu thập, sử dụng và tiết lộ thông tin cá nhân nếu bất kỳ ai quyết định sử dụng
Dịch vụ của chúng tôi.
Nếu bạn chọn sử dụng Dị
                </div>
                <div className='mt-2' >
                Nếu bạn chọn sử dụng Dịch vụ của chúng tôi, thì bạn đồng ý với việc thu thập và sử dụng thông
tin liên quan đến chính sách này. Thông tin cá nhân mà chúng tôi thu thập được sử dụng để cung
cấp và cải thiện Dịch vụ. Chúng tôi sẽ không sử dụng hoặc chia sẻ thông tin của bạn với bất kỳ ai
trừ khi được mô tả trong Chính sách quyền riêng tư này.
                </div>
                <div className='mt-2' >
                Các thuật ngữ được sử dụng trong Chính sách Quyền riêng tư này có cùng ý nghĩa như trong
Điều khoản và Điều kiện của chúng tôi, có thể truy cập được tại Medisetter trừ khi được quy
định khác trong Chính sách Quyền riêng tư này.
                </div>
                <h5 className='d-flex justify-content-start fs-5 mt-3'>
                Thu thập và sử dụng thông tin
                </h5>
                <div className='mt-2' >
                Để có trải nghiệm tốt hơn, trong khi sử dụng Dịch vụ của chúng tôi, chúng tôi có thể yêu cầu bạn
cung cấp cho chúng tôi một số thông tin nhận dạng cá nhân, bao gồm nhưng không giới hạn ở họ
tên, thông tin công việc, địa chỉ email, điện thoại. Thông tin mà chúng tôi yêu cầu sẽ được chúng
tôi lưu giữ và sử dụng như được mô tả trong chính sách bảo mật này.
                </div>
                <div className='mt-2' >
                Ứng dụng sử dụng các dịch vụ của bên thứ ba có thể thu thập thông tin được sử dụng để nhận
dạng bạn</div>
<div className='mt-2' >
Liên kết với chính sách quyền riêng tư của nhà cung cấp dịch vụ bên thứ ba mà ứng dụng sử
dụng
                </div>
                <div className='mt-2' >
                    <a className='text-decoration-none' href={"https://policies.google.com/privacy"} > Dịch vụ của Google Play</a>
                </div>
                <h5 className='d-flex justify-content-start fs-5 mt-3'>
                Nhật ký dữ liệu
                </h5>
                <div className='mt-2' >
                Chúng tôi muốn thông báo cho bạn rằng bất cứ khi nào bạn sử dụng Dịch vụ của chúng tôi, trong
trường hợp xảy ra lỗi trong ứng dụng, chúng tôi sẽ thu thập dữ liệu và thông tin (thông qua các
sản phẩm của bên thứ ba) trên điện thoại của bạn có tên là Nhật ký dữ liệu. Nhật ký dữ liệu này
có thể bao gồm các thông tin như địa chỉ Giao thức Internet (“IP”) trên thiết bị của bạn, tên thiết
bị, phiên bản hệ điều hành, cấu hình của ứng dụng khi sử dụng Dịch vụ của chúng tôi, ngày giờ
bạn sử dụng và các số liệu thống kê khác .
                </div>
                <h5 className='d-flex justify-content-start fs-5 mt-3'>
                Cookie
                </h5>
                <div className='mt-2' >
                Cookie là các tệp có lượng dữ liệu nhỏ thường được sử dụng làm số nhận dạng duy nhất ẩn danh.
Chúng được gửi đến trình duyệt của bạn từ các trang web mà bạn truy cập và được lưu trữ trên
bộ nhớ trong của thiết bị.
                </div>
                <div className='mt-2' >
                Dịch vụ này không sử dụng các “cookie” này một cách rõ ràng. Tuy nhiên, ứng dụng có thể sử
dụng mã của bên thứ ba và các thư viện sử dụng “cookie” để thu thập thông tin và cải thiện dịch 
vụ của họ. Bạn có tùy chọn chấp nhận hoặc từ chối các cookie này và biết khi nào cookie được
gửi đến thiết bị của bạn. Nếu bạn chọn từ chối cookie của chúng tôi, bạn không thể sử dụng một
số phần của Dịch vụ này.
                </div>

                <h5 className='d-flex justify-content-start fs-5 mt-3'>
                Các nhà cung cấp dịch vụ
                </h5>
                <div className='mt-2' >
                Chúng tôi có thể thuê các công ty và cá nhân bên thứ ba vì những lý do sau:
                </div>
                <ul >
                    <li style={{listStyle : 'initial'}}>
                    Để tạo điều kiện thuận lợi cho Dịch vụ của chúng tôi;
                    </li>
                    <li style={{listStyle : 'initial'}}>
                    Để cung cấp Dịch vụ thay mặt chúng tôi;
                    </li>
                    <li style={{listStyle : 'initial'}}>
                    Để thực hiện các dịch vụ liên quan đến Dịch vụ; hoặc
                    </li>
                    <li style={{listStyle : 'initial'}}>
                    Để hỗ trợ chúng tôi phân tích cách Dịch vụ của chúng tôi được sử dụng.
                    </li>
                </ul>
                <div className='mt-2' >
                Chúng tôi muốn thông báo cho người dùng Dịch vụ này rằng các bên thứ ba này có quyền truy
cập vào Thông tin cá nhân của họ. Lý do là để thay mặt chúng tôi thực hiện các nhiệm vụ được
giao cho họ. Tuy nhiên, họ có nghĩa vụ không tiết lộ hoặc sử dụng thông tin cho bất kỳ mục đích
nào khác.
                </div>
                <h5 className='d-flex justify-content-start fs-5 mt-3'>
                Bảo vệ
                </h5>
                <div className='mt-2' >
                Chúng tôi đánh giá cao sự tin tưởng của bạn trong việc cung cấp thông tin cá nhân của bạn cho
chúng tôi, do đó chúng tôi đang cố gắng sử dụng các phương tiện được chấp nhận về mặt thương
mại để bảo vệ thông tin đó. Nhưng hãy nhớ rằng không có phương thức truyền qua internet hoặc
phương thức lưu trữ điện tử nào an toàn và đáng tin cậy 100% và chúng tôi không thể đảm bảo
tính bảo mật tuyệt đối của nó.
                </div>
                <h5 className='d-flex justify-content-start fs-5 mt-3'>
                Liên kết đến các trang web khác
                </h5>
                <div className='mt-2' >
                Dịch vụ này có thể chứa các liên kết đến các trang web khác. Nếu bạn nhấp vào liên kết của bên
thứ ba, bạn sẽ được chuyển hướng đến trang web đó. Lưu ý rằng các trang web bên ngoài này
không được điều hành bởi chúng tôi. Do đó, chúng tôi thực sự khuyên bạn nên xem lại Chính
sách quyền riêng tư của các trang web này. Chúng tôi không kiểm soát và không chịu trách
nhiệm về nội dung, chính sách bảo mật hoặc thông lệ của bất kỳ trang web hoặc dịch vụ của bên
thứ ba nào.
                </div>
                <h5 className='d-flex justify-content-start fs-5 mt-3'>
                Quyền riêng tư của trẻ em
                </h5>
                <div className='mt-2' >
                Dịch vụ này không dành cho bất kỳ ai dưới 13 tuổi. Chúng tôi không cố ý thu thập thông tin cá
nhân từ trẻ em dưới 13 tuổi. Trong trường hợp chúng tôi phát hiện ra rằng một trẻ nhỏ dưới 13
tuổi đã cung cấp cho chúng tôi thông tin cá nhân, chúng tôi sẽ ngay lập tức xóa thông tin này
khỏi máy chủ của chúng tôi. Nếu bạn là cha mẹ hoặc người giám hộ và bạn biết rằng con trẻ đã
cung cấp cho chúng tôi thông tin cá nhân, vui lòng liên hệ với chúng tôi để chúng tôi có thể thực
hiện các hành động cần thiết.
                </div>
                <h5 className='d-flex justify-content-start fs-5 mt-3'>
                Những thay đổi đối với Chính sách quyền riêng tư này
                </h5>
                <div className='mt-2' >
                Thỉnh thoảng chúng tôi có thể cập nhật Chính sách quyền riêng tư của mình. Vì vậy, bạn nên
xem lại trang này định kỳ để biết bất kỳ thay đổi nào. Chúng tôi sẽ thông báo cho bạn về bất kỳ
thay đổi nào bằng cách đăng Chính sách quyền riêng tư mới trên trang này.
                </div>
                <div className='mt-2' >
                Chính sách này có hiệu lực kể từ ngày 29-12-2022
                </div>
                <h5 className='d-flex justify-content-start fs-5 mt-3'>
                Liên hệ
                </h5>
                <div className='mt-2' >
                Nếu bạn có bất kỳ câu hỏi hoặc đề xuất nào về Chính sách quyền riêng tư của chúng tôi, vui lòng
liên hệ với chúng tôi tại <a lassName='text-decoration-none ' href={"mailto: help@medisetter.com"}> help@medisetter.com.</a>
                </div>

            </div>
        </>
    )
}
export default PrivacyPolicyVt
import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import Swal from 'sweetalert2';
import CloseButton from 'react-bootstrap/CloseButton';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { MultiSelect } from "react-multi-select-component";
import 'react-quill/dist/quill.snow.css';
import * as AiIcons from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import ChannelsFeed from '../ChannelsFeed';
import { apiCall } from '../../../../../action/loginAction';
import selectedTab from  "../../../../../GlobalFunctions/global";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactPaginate from 'react-paginate';
const SearchChannels = (props) => {
  const { isCallData } = useSelector(state => state.channelData);
  const dispatch = useDispatch();
  const { searchFeedData, feedPermissions } = useSelector(state => state.feedData);
  //model hide or show
  const [editModalShow, setEditModalShow] = React.useState(false);
  const [profileDetails, setPorfileDetails] = useState({});
  //model details
  const [channelsData, setChannelsData] = useState([]);
  const [specialitiesList, setSpecialitiesList] = useState([]);
  const [topicTagList, setTopicTagList] = useState([]);
  const [editTopicTag, setEditTopicTag] = useState([]);
  const [editSecondarySpeciality, setEditSecondarySpeciality] = useState([]);
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState('');
  const [bannerImageUrl, seBannerImageUrl] = useState('');
  const [isEditBannerImage, setIsEditBannerImage] = useState(false);
  const [isEditThumbnailImage, setIsEditThumbnailImage] = useState(false);
  const [bannerImage, setBannerImage] = useState({ fileName: '', media_id: '' });
  const [thumbnailImage, seThumbnailImage] = useState({ fileName: '', media_id: '' });
  const email = localStorage.getItem('email');
  const [sponsors, setSponsor] = useState([]);
  const [isDeleteChannelId, setIsDeleteChannelId] = useState('');
  const [isEditChannel, setIsEditChannel] = useState({});
  const [totalPage, setTotalPage] = useState(0);
  const [newFeedData , setNewFeedData] = useState();
  const [isDataLoading , setIsDataLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [searchedValue, setSearchedValue] = useState(props.searchedValue)
  // redirect to page
  // useEffect(() => {
  //   setChannelsData(searchFeedData.groups)
  // }, [searchFeedData])
  useEffect(() => {
    getchannelData(1);
  }, [])
  useEffect(() => {
    if (isDeleteChannelId !== '') {
      deleteChannelApi(isDeleteChannelId)
    }
  }, [isDeleteChannelId])
  useEffect(() => {
    if (Object.keys(isEditChannel).length !== 0) {
      openEditModel(isEditChannel)
    }
  }, [isEditChannel])

  // useEffect(() => {
  //   console.log("use effect called");
  //   getFeedData(1);
  // }, []);
  // update key
  const closeEditModel = () => {
    setEditModalShow(false);
    empty();
  }
  // edit model 
  const openEditModel = (profile) => {
    setPorfileDetails(profile)
    updateKeyList(profile.specialities, profile.topic_tags);
    setTimeout(() => {
      setEditModalShow(true);
    }, 500)
  }
  // update key
  const updateKeyList = (list1, list2) => {
    let newSpecArray = [];
    for (let i = 0; i < list1.length; i++) {
      newSpecArray.push({ 'label': list1[i].label_name, 'value': list1[i].identifier_id })
    }
    setEditSecondarySpeciality(newSpecArray);
    let newTopicArray = [];
    for (let i = 0; i < list2.length; i++) {
      newTopicArray.push({ 'label': list2[i].name, 'value': list2[i].topic_tag_id })
    }
    setEditTopicTag(newTopicArray)
  }
  //delete channel 
  const deleteChannelApi = async (id) => {
    var data = {
      delete_channel: true,
      channel_id: id
    };
    let res = await dispatch(apiCall(data, '/user/channel-admin'));
    setIsDeleteChannelId('')
    dispatch({ type: "callChannelData" });
    if (res.data.message.MSG_CODE === 100371) {
      Swal.fire({
        icon: 'success',
        text: 'Channel has been deleted successfully',
        timer: 3000
      })
    } else if (res.data.message.MSG_CODE === 100410) {
      Swal.fire({
        icon: 'error',
        text: 'Channel has been deleted unsuccessfully ',
        timer: 3000
      })
    } else if (res.data.message.MSG_CODE === 100400) {
      Swal.fire({
        icon: 'error',
        text: 'Channel does not exist',
        timer: 3000
      })
    }
  }
  // upload api
  const uploadFile = async (e, fileName) => {
    if (fileName === 'Banner') {
      setBannerImage({ fileName: e.name })
    } else {
      seThumbnailImage({ fileName: e.name })
    }
    const convertString = await convertBase64(e);
    const base64Array = convertString.split(",");
    const base64 = base64Array[1];
    var data = {
      email: email,
      file_path: "channel",
      file_data: base64,
      file_name: e.name,
    };
    let res = await dispatch(apiCall(data, '/user/upload'));
    if (res.data.message.MSG_CODE === 100231) {
      if (fileName === 'Banner') {
        setBannerImage({ media_id: res.data.data.media_id })
        seBannerImageUrl(res.data.data.url)
      } else {
        seThumbnailImage({ media_id: res.data.data.media_id })
        setThumbnailImageUrl(res.data.data.url)
      }
    } else if (res.data.message.MSG_CODE === 100270) {
      Swal.fire({
        icon: 'error',
        text: 'Not valid extension please upload again (accept only JPEG, PNG, JPG)',
        timer: 3000
      })
    } else if (res.data.message.MSG_CODE === 100580 || res.data.message.MSG_CODE === 100010) {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please upload again',
        timer: 3000
      })
    }
    else if (res.data.message.MSG_CODE === 100580 || res.data.message.MSG_CODE === 100010) {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please upload again',
        timer: 3000
      })
    }
  }
  // base64 conversion
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = (() => {
        resolve(fileReader.result);
      });
      fileReader.onerror = ((error) => {
        reject(error)
      });
    });
  };
  // change height 
  const changeHeightTextArea = (id) => {
    setTimeout(() => {
      var textarea = document.querySelector(id);
      if (textarea !== null && textarea !== undefined) {

        textarea.addEventListener("keydown", e => {
          let scroll_height = e.target.scrollHeight;
          textarea.style.height = `${scroll_height}px`;
        });
      }
    }, 10);
  }
  // push to sponspor array 
  const sponsporArrayPush = (e) => {
    if (e.key !== 'Enter') return
    const value = e.target.value
    if (!value.trim()) return
    if (profileDetails.sponsor === undefined) {
      setSponsor([...sponsors, value])
    } else {
      setPorfileDetails({ ...profileDetails, sponsor: [...profileDetails.sponsor, value] })
    }
    e.target.value = ''
  }
  //remove tag edit
  const removeEditTag = (index) => {
    setSponsor(sponsors.filter((el, i) => i !== index))
  }
  // changeKeyName
  const getNewTopicTagList = (list) => {
    let newSpecArray = [];
    for (let i = 0; i < list.length; i++) {
      newSpecArray.push({ 'name': list[i].label, 'topic_tag_id': list[i].value })
    }
    return newSpecArray
  }
  const getSpecialityList = (list) => {
    let newSpecArray = [];
    for (let i = 0; i < list.length; i++) {
      newSpecArray.push({ 'label_name': list[i].label, 'identifier_id': list[i].value })
    }
    return newSpecArray
  }
  //edit channel 
  const editChanneldetails = async (id) => {
    if (profileDetails.channel_name !== '' && profileDetails.channel_type !== '') {
      let specialityList = getSpecialityList(editSecondarySpeciality);
      let newTopicTagList = getNewTopicTagList(editTopicTag);
      var data = {
        edit_channel: true,
        channel_id: id,
        channel_name: profileDetails.channel_name,
        banner_image: bannerImage.media_id === "" ? null : bannerImage.media_id,
        thumbnail_image: thumbnailImage.media_id === "" ? null : thumbnailImage.media_id,
        channel_type: profileDetails.channel_type,
        description: profileDetails.description,
        sponsor: profileDetails.sponsor,
        topic_tags: newTopicTagList,
        specialities: specialityList
      };
      let res = await dispatch(apiCall(data, '/user/channel-admin'));
      setIsEditChannel({})
      closeEditModel();
      dispatch({ type: "callChannelData" });
      if (res.data.message.MSG_CODE === 100371) {
        Swal.fire({
          icon: 'success',
          text: 'Changes has been done successfully',
          timer: 3000
        })
      } else if (res.data.message.MSG_CODE === 100410) {
        Swal.fire({
          icon: 'error',
          text: 'There has benn some issue while updating data, please try again later',
          timer: 3000
        })
      } else if (res.data.message.MSG_CODE === 100400) {
        Swal.fire({
          icon: 'error',
          text: 'Channel does not exist',
          timer: 3000
        })
      }
    } else {
      if (profileDetails.channel_name === '') {
        Swal.fire({
          icon: 'error',
          text: 'Please fill channel name',
          timer: 4000
        })
      } else if (profileDetails.channel_type === '') {
        Swal.fire({
          icon: 'error',
          text: 'Please select channel type ',
          timer: 4000
        })
      } else if (profileDetails.description === '' && profileDetails.description !== '<p><br></p>') {
        Swal.fire({
          icon: 'error',
          text: 'Please add description',
          timer: 4000
        })
      } else if (editSecondarySpeciality.length === 0) {
        Swal.fire({
          icon: 'error',
          text: 'Please select speciality ',
          timer: 4000
        })
      } else if (editTopicTag.length === 0) {
        Swal.fire({
          icon: 'error',
          text: 'Please select topic tag',
          timer: 4000
        })
      }

    }

  }
  //empty all data
  const empty = () => {
    setBannerImage({ fileName: '', media_id: '' });
    seThumbnailImage({ fileName: '', media_id: '' });
    setThumbnailImageUrl('')
    seBannerImageUrl('')
    setSponsor([]);
    setPorfileDetails({});
  }
  const getchannelData = async (pg) => {
    var data = {
      get_groups: true,
        search_data: searchedValue,
        per_page: 10,
        preferred_language: 'EN',
        page_number: pg
    }
    let res = await dispatch(apiCall(data, '/user/search-all', true));
    console.log("res" , res.data)
    if (isCallData === true) {
        dispatch({ type: "clearCallChannelData" });
    }
    if (res.data.message.MSG_CODE === 100351) {
        if (searchedValue.trim().length === 0) {
          setChannelsData([])
        } else {
          setChannelsData(res.data.data)
          console.log("res.data.data channels pages" , res.data.pagination.total_page)
          setTotalPage(res.data.pagination.total_page);
          setIsDataLoading(false);
        }
    }
    else {
      console.log("Something went, please try again later")
    }
}
const getNewchannelData = async (pg) => {
  var data = {
    get_groups: true,
      search_data: searchedValue,
      per_page: 10,
      preferred_language: 'EN',
      page_number: pg
  }
  let res = await dispatch(apiCall(data, '/user/search-all', true));
  if (isCallData === true) {
      dispatch({ type: "clearCallChannelData" });
  }
  if (res.data.message.MSG_CODE === 
    100351) {
      if (searchedValue.trim().length === 0) {
        setChannelsData([])
      } else {
        setChannelsData([...channelsData , ...res.data.data])
        setTotalPage(res.data.pagination.total_page);
        setIsDataLoading(false);
      }
  }
  else {
    console.log("Something went, please try again later")
  }
}
const onEndOfPage = () => {
  console.log("Search post data end of page called")
  console.log("activeTab " , selectedTab.selectedTab)
 if (totalPage >= page && selectedTab.selectedTab === "Channels"){
  setIsDataLoading(true)
  setPage(page + 1)
  getNewchannelData(page + 1)
 }
} 
const handlePageClick = (data) =>{
  setIsDataLoading(true)
  setChannelsData([])
  let currentPage = data.selected + 1 ;
  console.log("data.selected",data.selected)
  getchannelData(currentPage)
  setPage(currentPage)
}
  return (
    <>
      <div className='mt-3'>

        {
          channelsData && channelsData.length > 0 ?
        //   <InfiniteScroll
        //   dataLength={channelsData.length}
        //   style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
        //   hasMore={true}
        //   next={() => onEndOfPage()}
        //   scrollableTarget="scrollableDiv"
        // >
          <ChannelsFeed channelData={channelsData} sharePost={setIsEditChannel} deleteChannel={setIsDeleteChannelId} />
          // </InfiniteScroll>
          : isDataLoading ? null :
            <h5 style={{ textAlign: 'center', marginTop: '2rem', color: 'steelblue' }}>
              No Data available
            </h5>
        }
                 
      </div>
      <div className='mt-3 mb-3'>
      {
                          totalPage > 1 && (
                            <ReactPaginate
                              className='pagination m-0'
                              breakLabel= {'...'}
                              nextLabel="next >"
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={2}
                              marginPagesDisplayed={2}
                              pageCount={totalPage}
                              previousLabel="< prev"
                              renderOnZeroPageCount={null}
                              containerClassName="pagination"
                              activeClassName={'active'}
                              pageClassName = {'page-item'}
                              previousClassName = {'page-item'}
                              previousLinkClassName = {'page-link'}
                              pageLinkClassName = {'page-link'}
                              nextClassName = {'page-item'}
                              nextLinkClassName = { 'page-link'}
                              breakClassName = {'page-item'}
                              breakLinkClassName = {'page-link'}
                             />
                          )
                        }
                        </div>
      <Modal
        {...profileDetails}
        show={editModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='justify-content-between'
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
            Channel Detail
          </Modal.Title>
          <CloseButton onClick={closeEditModel} />
        </Modal.Header>
        <Modal.Body>
          <section>
            <Row>
              <Col xxl={12} xl={12} lg={12} md={12}>
                <div className='channelScreenBox'>
                  <div className='my-1'>
                    <p>
                      Channel name <span className="redStar">&#42;</span>
                    </p>
                    <input type="text" className="form-control"
                      placeholder="channel name" aria-label="Title"
                      aria-describedby="basic-addon1"
                      value={profileDetails.channel_name}
                      onChange={(name) => setPorfileDetails({ ...profileDetails, channel_name: name.target.value })}
                    />
                  </div>
                  <div className='my-2'>
                    <div className='d-flex justify-content-between'>
                      <div>
                        <p>Banner image <span>(jpeg,png)</span>
                          <span className='mx-3'>{bannerImageUrl !== '' ? <a href={bannerImageUrl} target="_blank" rel="noreferrer">Preview</a> : <a href={profileDetails.banner_image} target="_blank" rel="noreferrer">Preview</a>}</span>
                        </p>
                      </div>
                      <div onClick={() => { setIsEditBannerImage(!isEditBannerImage) }} style={{ color: 'green', cursor: 'pointer' }} className='px-4'>
                        <AiIcons.AiFillEdit />
                      </div>
                    </div>
                    {
                      isEditBannerImage === true ?
                        <input type="file" className="form-control"
                          aria-describedby="basic-addon1"
                          accept="image/png, image/jpeg"
                          onChange={(event) => uploadFile(event.target.files[0], 'Banner')}
                        /> : null
                    }

                  </div>
                  <div className='my-2'>
                    <div className='d-flex justify-content-between'>
                      <div>
                        <p>Thumbnail image <span>(jpeg,png)</span>
                          <span className='mx-3'>{thumbnailImageUrl !== '' ? <a href={thumbnailImageUrl} target="_blank" rel="noreferrer">Preview</a> : <a href={profileDetails.thumbnail_image} target="_blank" rel="noreferrer">Preview</a>}</span>
                        </p>
                      </div>
                      <div onClick={() => { setIsEditThumbnailImage(!isEditThumbnailImage) }} style={{ color: 'green', cursor: 'pointer' }} className='px-4'>
                        <AiIcons.AiFillEdit />
                      </div>
                    </div>
                    {
                      isEditThumbnailImage === true ?
                        <input type="file" className="form-control"
                          aria-describedby="basic-addon1"
                          accept="image/png, image/jpeg"
                          onChange={(event) => uploadFile(event.target.files[0], 'Thumbnail')}
                        /> : null
                    }
                  </div>
                  <div className='my-2'>
                    <p>
                      Channel type <span className="redStar">&#42;</span>
                    </p>
                    <div className='d-flex'>
                      <input type="radio" className="" name='channelType' value='Private' checked={profileDetails.channel_type === 'Private'}
                        onChange={e => setPorfileDetails({ ...profileDetails, channel_type: e.target.value })} />
                      <label className="mx-2" >Private</label><br></br>
                      <input className="mx-2" type="radio" name='channelType' value='Public' checked={profileDetails.channel_type === 'Public'}
                        onChange={e => setPorfileDetails({ ...profileDetails, channel_type: e.target.value })} />
                      <label className="mx-2" >Public</label><br></br>
                    </div>
                  </div>
                  <div className='my-2'>
                    <p>
                      Description
                    </p>
                    <textarea
                      name="searchValue" className='channelDiscInputBox'
                      id='channelDescriptionInputBox2'
                      rows="2" cols="200"
                      placeholder="What's happening"
                      value={profileDetails.description}
                      onChange={(e) => setPorfileDetails({ ...profileDetails, description: e.target.value })}
                      onKeyDown={changeHeightTextArea('#channelDescriptionInputBox2')}
                    >
                    </textarea>
                  </div >
                  <div className='my-2'>
                    <h6>
                      Specialities
                    </h6>
                    {
                      profileDetails.specialities !== undefined ?
                        <>
                          <MultiSelect
                            options={specialitiesList}
                            value={editSecondarySpeciality}
                            onChange={setEditSecondarySpeciality}
                            labelledBy="Select"
                          />
                        </> : null

                    }
                  </div>
                  {
                    profileDetails.topic_tags !== undefined ?
                      <div className='my-2'>
                        <span className='my-2'>Tags:- </span>
                        <MultiSelect
                          options={topicTagList}
                          value={editTopicTag}
                          onChange={setEditTopicTag}
                          labelledBy="Select"
                        />
                      </div> : null
                  }
                  {
                    profileDetails.sponsor !== undefined ?
                      <div className='my-2'>
                        <p className='my-2'>Sponsor:- (Enter) </p>
                        {profileDetails.sponsor.length > 0 ?
                          <span>[{
                            profileDetails.sponsor.map((sponsor, index) => {
                              return (
                                <div className='tagsItem' key={index}>
                                  <span>{sponsor}</span>
                                  <span className='close' onClick={() => { removeEditTag(index) }}> &#x2717;</span>
                                </div>
                              )
                            })
                          }]</span> : null
                        }
                        <input type="text" className="form-control"
                          placeholder=" Channel sponsor" aria-label="Title"
                          aria-describedby="basic-addon1"
                          onKeyDown={sponsporArrayPush}
                        />
                      </div> : null
                  }

                </div>
              </Col>
            </Row>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeEditModel} style={{ background: 'white', color: 'blue' }}>Close</Button>
          <Button onClick={() => { editChanneldetails(profileDetails.channel_id) }}>Update</Button>
        </Modal.Footer>
      </Modal>


    </>
  )
}

export default SearchChannels
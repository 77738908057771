import React, { useState, useRef, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import LeftSideBar from '../../SideBar/LeftSideBar'
import Button from 'react-bootstrap/Button';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { apiCall } from '../../../../action/loginAction';
import { useNavigate } from 'react-router-dom';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { MultiSelect } from "react-multi-select-component";
import Swal from 'sweetalert2';
import * as AiIcons from 'react-icons/ai';
import 'react-quill/dist/quill.snow.css';
import './Events.css';
import moment from 'moment';
import { compressionImage } from '../../../../constant/compressImage';
const EditEvents = (props) => {
    const { state } = useLocation();
    var navigate = useNavigate();
    const dispatch = useDispatch();
    const email = localStorage.getItem('email');
    const [tags, setTags] = useState([])
    const [specialty, setSpecialty] = useState([]);
    const [speakerlist, setSpeakerList] = useState(state.data.speaker_names)
    const [registrationPageData, setRegistrationPageData] = useState([]);
    const [value, onChange] = useState([new Date(`${state.data.start_event_datetime}${''}`), new Date(`${state.data.end_event_datetime}${''}`)]);
    // const [value, onChange] = useState([state.data.event_dates[0],state.data.event_dates[1]]);
    const editor = useRef(null);
    const [modalData, setModalData] = useState(state.data);
    const [bannerImage, setBannerImage] = useState({ fileName: '', media_id: '' });
    const [startDateTime, setStartDateTime] = useState('');
    const [endDateTime, setEndDateTime] = useState('')
    const [topicTag, setTopicTag] = useState([]);
    const [topicTagList, setTopicTagList] = useState([]);
    const [secondarySpeciality, setSecondarySpeciality] = useState([]);
    const [specialitiesList, setSpecialitiesList] = useState([]);
    const [bannerImageUrl, setBannerImageUrl] = useState('');
    const [isEditBannerImage, setIsEditBannerImage] = useState(false);
    const initialRender = useRef(true);
    const [isUploadText, setIsUploadText] = useState('');
    // on date value change
    useEffect(() => {
        if (value !== null) {
            let startDateTime = new Date(value[0]);
            let startDate = formatDateTime(startDateTime);
            setStartDateTime(startDate)
            let endDateTime = new Date(value[1]);
            let endDate = formatDateTime(endDateTime);
            setEndDateTime(endDate)
         }
    }, [value]);
    // get data
    useEffect(() => {
        getRegistrationPageData();
        getTopicTagData();
        updateKeyList(state.data.speciality, state.data.topic_tags);
    }, [])
    //get all data 
    const getRegistrationPageData = async () => {
        var data = {
            preferred_language: 'EN',
        };
        let res = await dispatch(apiCall(data,'/user/get-registration-page-info'));
        if (res.data.message.MSG_CODE === 100281) {
            setRegistrationPageData(res.data.data)
            let newSpecArray = [];
            for (let i = 0; i < res.data.data.specialities.length; i++) {
                newSpecArray.push({ 'label': res.data.data.specialities[i].label_name, 'value': res.data.data.specialities[i].identifier_id })
            }
            setSpecialitiesList(newSpecArray)
        } else {
            Swal.fire({
                icon: 'error',
                text: 'Post Update Failure',
                timer: 3000
            })
        }
    }
    // update key
    const updateKeyList = (list1, list2) => {
        let newSpecArray = [];
        for (let i = 0; i < list1.length; i++) {
            newSpecArray.push({ 'label': list1[i].label_name, 'value': list1[i].identifier_id })
        }
        setSecondarySpeciality(newSpecArray);
        let newTopicArray = [];
        for (let i = 0; i < list2.length; i++) {
            newTopicArray.push({ 'label': list2[i].name, 'value': list2[i].topic_tag_id })
        }
        setTopicTag(newTopicArray)
    }
    const addTagsToSpeakerList = () => {
        setSpeakerList([...speakerlist, isUploadText])
        setIsUploadText('')
    }
    const removeSpeaker = (index) => {
        setSpeakerList(speakerlist.filter((el, i) => i !== index))
    }
    // update event 
    const updateEvent = async (date) => {
        if (modalData.title !== '' && modalData.title !== null && modalData.language !== null && bannerImage.media_id !== null && modalData.fees !== null && modalData.fees !== '' && modalData.description !== '' && modalData.description !== null &&
            speakerlist.length !== 0 && modalData.location !== '' && modalData.location !== null && startDateTime !== null && startDateTime !=='' && endDateTime !==null && endDateTime !== '' &&
            modalData.language !== '' && modalData.joining_link !== '' && modalData.joining_link !== null 
        ) {
            let specialityList = getSpecialityList(secondarySpeciality);
            let newTopicTagList = getNewTopicTagList(topicTag);
            var data = {
                update_event: true,
                event_id: state.data.event_id,
                title: modalData.title,
                banner_image_id: bannerImage.media_id === "" ? null : bannerImage.media_id,
                description: modalData.description,
                speaker_names: speakerlist,
                location: modalData.location,
                mode: modalData.mode,
                language: modalData.language,
                speciality: specialityList,
                topic_tags: newTopicTagList,
                ticket_required: modalData.ticket_required,
                fees: modalData.fees,
                registration_link: modalData.registration_link,
                joining_link: modalData.joining_link,
                organizer: modalData.organizer,
                start_event_datetime:startDateTime,
                end_event_datetime:endDateTime,
            };
            let res = await dispatch(apiCall(data,'/user/organize-event'));
            if (res.data.message.MSG_CODE === 100411) {
                Swal.fire({
                    icon: 'success',
                    text: 'Event has been updated successfully',
                    timer: 3000
                })
                navigate("/admin/events")
            }
            else if (res.data.message.MSG_CODE === 100500) {
                Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong please try again!',
                    timer: 4000
                })
            }
            else if (res.data.message.MSG_CODE === 100460) {
                Swal.fire({
                    icon: 'error',
                    text: 'Details entred in invalid formate!',
                    timer: 4000
                })
            } else if (res.data.message.MSG_CODE === 100010) {
                Swal.fire({
                    icon: 'warning',
                    text: 'Details not entered correctly',
                    timer: 3000
                })
            }
        } else {
            if (modalData.title === null || modalData.title === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please fill event name',
                    timer: 4000
                })
            } else if (modalData.description === null || modalData.description === '' || modalData.description === '<p><br></p>') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please fill description',
                    timer: 4000
                })
            } else if (modalData.location === null || modalData.location === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please fill location',
                    timer: 4000
                })
            } else if (modalData.joining_link === null || modalData.joining_link === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please fill joining link',
                    timer: 4000
                })
            } else if (modalData.language === null || modalData.language === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please fill mode of communication',
                    timer: 4000
                })
            } else if (secondarySpeciality.length === 0) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please select start and end date',
                    timer: 4000
                })
            }
             else if ( startDateTime === null && startDateTime ==='' && endDateTime === null && endDateTime === '' ) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please select speciality',
                    timer: 4000
                })
            } else if (topicTag.length === 0) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please select topic tag',
                    timer: 4000
                })
            } else if (speakerlist.length === 0) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please add speaker name',
                    timer: 4000
                })
            } else if (modalData.fees === null || modalData.fees === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please select respected date',
                    timer: 4000
                })
            }
        }
    }
    // upload api
    const uploadImage = async (files,type) => {
        dispatch({ type: "showLoader"});
        let arrayFile = []
        for (let i = 0; i < files.length; i++) {
            if ( files[i].type === 'image/heif' || files[i].type === 'image/HEIF') {
                arrayFile.push(files[i]);
            }else{
                const image = await compressionImage(files[i]);
                arrayFile.push(image);  
            }
        }
        dispatch({ type: "hideLoader"});
        uploadFile(arrayFile[0]);
    };
    const uploadFile = async (e) => {
        setBannerImage({ fileName: e.name })
        const convertString = await convertBase64(e);
        const base64Array = convertString.split(",");
        const base64 = base64Array[1];
        var data = {
            email: email,
            file_path: "event",
            file_data: base64,
            file_name: e.name,
        };
        let res = await dispatch(apiCall(data,'/user/upload'));
        if (res.data.message.MSG_CODE === 100231) {
            setBannerImage({ media_id: res.data.data.media_id })
            setBannerImageUrl(res.data.data.url)
        } else if (res.data.message.MSG_CODE === 100270) {
            Swal.fire({
                icon: 'error',
                text: 'Not valid extension please upload again',
                timer: 3000
            })
        } else if (res.data.message.MSG_CODE === 100580) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please upload again',
                timer: 3000
            })
        }
    }
    // base64 conversion
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = (() => {
                resolve(fileReader.result);
            });
            fileReader.onerror = ((error) => {
                reject(error)
            });
        });
    };
    // format date and time
    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }
    const formatDateTime = (date) => {
        let  newDate = date.toISOString();
        return newDate;
    }
    // changeKeyName
    const getNewTopicTagList = (list) => {
        let newSpecArray = [];
        for (let i = 0; i < list.length; i++) {
            newSpecArray.push({ 'name': list[i].label, 'topic_tag_id': list[i].value })
        }
        return newSpecArray
    }
    const getSpecialityList = (list) => {
        let newSpecArray = [];
        for (let i = 0; i < list.length; i++) {
            newSpecArray.push({ 'label_name': list[i].label, 'identifier_id': list[i].value })
        }
        return newSpecArray
    }
    //get topic data 
    const getTopicTagData = async () => {
        var data = {
            get_topic_tags_list: true,
            paginated_result:false
        };
        let res = await dispatch(apiCall(data,'/user/topic-tags'));
        if (res.data.message.MSG_CODE === 100711) {
            let newTopicArray = [];
            for (let i = 0; i < res.data.data.length; i++) {
                newTopicArray.push({ 'label': res.data.data[i].name, 'value': res.data.data[i].topic_tag_id })
            }
            setTopicTagList(newTopicArray)
        }
        else {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please try again later',
                timer: 3000
            })
        }
    }
    // preview image
    const closeFilterModel = () => {
        navigate("/admin/events");
    }
    // change height 
    const changeHeightTextArea = (id) => {
        setTimeout(() => {
            var textarea = document.querySelector(id);
            textarea.addEventListener("keydown", e => {
                let scroll_height = e.target.scrollHeight;
                textarea.style.height = `${scroll_height}px`;
            });
        }, 10);
    }
    const goBack=()=>{
        navigate("/admin/events");
    }
    return (
        <>
            <section>
                <Row style={{ height: '100vh' }}>
                    <Col className='' xxl={2} xl={2} lg={3} md={3} style={{ background: 'linear-gradient(90deg, #277AFC 0%, #27B3FC 100%)', height: '100%' }} >
                        <LeftSideBar />
                    </Col>
                    <Col className='bg-prime p-0 eventScreen' xxl={10} xl={10} lg={9} md={9}>
                        <Container className='log-wrap p-0 d-flex justify-content-center eventScreenPage' fluid style={{ height: '100vh' }}>
                            <>
                                <section className='w-50'>
                                    <div className='eventScreenHeading'>
                                        <h5>Edit Event </h5>
                                    </div>
                                    <div className='eventScreenBox'>
                                        <div className='my-1'>
                                            <p>
                                                Title <span className="redStar">&#42;</span>
                                            </p>
                                            <input type="text" className="form-control" placeholder="Title"
                                                aria-label="Title" aria-describedby="basic-addon1"
                                                value={modalData.title}
                                                onChange={(e) => setModalData({ ...modalData, title: e.target.value })}
                                            />
                                        </div>
                                        <div className='my-2'>
                                            <p>
                                                Description <span className="redStar">&#42;</span>
                                            </p>
                                            <textarea
                                                name="searchValue" className='channelDiscInputBox'
                                                id='channelDescriptionInputBox'
                                                rows="2" cols="200"
                                                placeholder="What's happening"
                                                value={modalData.description}
                                                onChange={(e) => setModalData({ ...modalData, description: e.target.value })}
                                                onKeyDown={changeHeightTextArea('#channelDescriptionInputBox')}
                                            >
                                            </textarea>
                                            {/* <ReactQuill theme="snow" 
                                            ref={editor}
                                            value={modalData.description}
                                            tabIndex={1}
                                            onChange={newContent => setModalData({...modalData,description:newContent})}
                                        /> */}
                                        </div >
                                        <div className='my-2'>
                                            <p>
                                                Location <span className="redStar">&#42;</span>
                                            </p>
                                            <input type="text" className="form-control" placeholder="Location"
                                                aria-label="Title" aria-describedby="basic-addon1"
                                                value={modalData.location}
                                                onChange={(e) => setModalData({ ...modalData, location: e.target.value })}
                                            />
                                        </div>
                                        <div className='my-2'>
                                            <p>
                                                Registration link
                                            </p>
                                            <input type="url" className="form-control" placeholder="Registration link"
                                                aria-label="Registration link" aria-describedby="basic-addon1"
                                                value={modalData.registration_link}
                                                onChange={(e) => setModalData({ ...modalData, registration_link: e.target.value })}
                                            />
                                        </div>
                                        <div className='my-2'>
                                            <p>Joining link <span className="redStar">&#42;</span> </p>
                                            <input type="url" className="form-control" placeholder="Joining link"
                                                aria-label="Registration link" aria-describedby="basic-addon1"
                                                value={modalData.joining_link}
                                                onChange={(e) => setModalData({ ...modalData, joining_link: e.target.value })}
                                            />
                                        </div>
                                        <div className='my-2'>
                                            <div className='d-flex justify-content-between'>
                                                <p>Add image (accept only jpeg and png )
                                                    <span className='mx-3'>{bannerImageUrl === '' ? <a href={modalData.banner_image} target="_blank" rel="noreferrer">Preview</a> : <a href={bannerImageUrl} target="_blank" rel="noreferrer">Preview</a>}</span>
                                                </p>
                                                <div onClick={() => { setIsEditBannerImage(!isEditBannerImage) }} style={{ color: 'green', cursor: 'pointer' }} className='px-4'>
                                                    <AiIcons.AiFillEdit />
                                                </div>
                                            </div>
                                            {
                                                isEditBannerImage === true ?
                                                    <input type="file" accept="image/*" className="form-control" aria-describedby="basic-addon1"  onChange={(event) => uploadImage(event.target.files)} />
                                                    : null
                                            }
                                        </div>
                                        <div className='my-2'>
                                            <p>Event mode <span className="redStar">&#42;</span> </p>
                                            <div className='d-flex'>
                                                <input type="radio" className="" name='eventMode' style={{ cursor: 'pointer' }} checked={modalData.mode === 'Online'} value={'Online'} onChange={e => setModalData({ ...modalData, mode: e.target.value })} />
                                                <label className="mx-2" >Online</label><br></br>
                                                <input className="mx-2" type="radio" checked={modalData.mode === 'Offline'} style={{ cursor: 'pointer' }} name='eventMode' value={'Offline'} onChange={e => setModalData({ ...modalData, mode: e.target.value })} />
                                                <label className="mx-2" >Offline</label><br></br>
                                                <input className="mx-2" type="radio" checked={modalData.mode === 'Hybrid'} style={{ cursor: 'pointer' }} name='eventMode' value={'Hybrid'} onChange={e => setModalData({ ...modalData, mode: e.target.value })} />
                                                <label className="mx-2" >Hybrid</label><br></br>
                                            </div>
                                        </div>
                                        <div className='my-2'>
                                            <p>Date and time <span className="redStar">&#42;</span>  </p>
                                            <DateTimeRangePicker onChange={onChange} value={value} isValidStartDate = {new Date()}/>
                                        </div>
                                        <div className='my-2'>
                                            <p>Mode of communication <span className="redStar">&#42;</span>  </p>
                                            <input type="text" className="form-control"
                                                aria-describedby="basic-addon1" placeholder="Language"
                                                value={modalData.language}
                                                onChange={(e) => setModalData({ ...modalData, language: e.target.value })}
                                            />
                                        </div>
                                        <div className='my-2'>
                                            <p>Organizer  </p>
                                            <input type="text" className="form-control" aria-describedby="basic-addon1" placeholder="Organizer"
                                                onChange={e => setModalData({ ...modalData, organizer: e.target.value })}
                                                value={modalData.organizer}
                                            />
                                        </div>
                                        <div className='my-2'>
                                            <h6> Specialties</h6>
                                            {
                                                registrationPageData.specialities !== undefined ?
                                                    <>
                                                        <MultiSelect
                                                            options={specialitiesList}
                                                            value={secondarySpeciality}
                                                            onChange={setSecondarySpeciality}
                                                            labelledBy="Select"
                                                        />
                                                    </> : null
                                            }
                                        </div>
                                        <div className='my-2'>
                                            <h6> Topic tags </h6>
                                            <MultiSelect
                                                options={topicTagList}
                                                value={topicTag}
                                                onChange={setTopicTag}
                                                labelledBy="Select"
                                            />
                                        </div>
                                        <div className='my-1'>
                                            <p className='m-0'>Speaker name  <span className="redStar">&#42;</span> (please press enter to add speaker name in list)</p>
                                            {
                                                speakerlist.map((tag, index) => (
                                                    <div className='tagsItem' key={index}>
                                                        <span className='text'>{tag}</span>
                                                        <span className='close' onClick={() => { removeSpeaker(index) }}> &#x2717;</span>
                                                    </div>
                                                ))
                                            }
                                                <textarea
                                                    name="searchValue" className='postInputBox'
                                                    id='speakerInputBox'
                                                    rows="2" cols="200"
                                                    placeholder="Speaker name"
                                                    value={isUploadText}
                                                    onChange={(e) => setIsUploadText(e.target.value)}
                                                    onKeyDown={changeHeightTextArea('#speakerInputBox')}
                                                >
                                                </textarea>
                                                <Button onClick={() => {addTagsToSpeakerList()}}>Add</Button>
                                            {/* <input type="text" onKeyDown={addTagsToSpeakerList} className="form-control my-2" aria-describedby="basic-addon1" placeholder="add tags" /> */}
                                        </div>
                                        <div className='my-2'>
                                            <p>Type of event <span className="redStar">&#42;</span>  </p>
                                            <div className='d-flex'>
                                                <input type="radio" checked={modalData.ticket_required === false} style={{ cursor: 'pointer' }} className="" name='typeEvent' value={'free'} onChange={e => setModalData({ ...modalData, ticket_required: e.target.value === 'free' ? false : true })} />
                                                <label className="mx-2" >free</label><br></br>
                                                <input className="mx-2" checked={modalData.ticket_required !== false} style={{ cursor: 'pointer' }} type="radio" name='typeEvent' value={'paid'} onChange={e => setModalData({ ...modalData, ticket_required: e.target.value === 'free' ? false : true })} />
                                                <label className="mx-2" >paid</label><br></br>
                                            </div>
                                        </div>
                                        {
                                            modalData.ticket_required === true ?
                                                <div className='my-2'>
                                                    <p>Ticket price <span className="redStar">&#42;</span>  </p>
                                                    <input type="number" className="form-control"
                                                        aria-describedby="basic-addon1"
                                                        placeholder="Price"
                                                        value={modalData.fees}
                                                        onChange={(e) => setModalData({ ...modalData, fees: e.target.value })}
                                                    />
                                                </div> : null
                                        }
                                        <div className='my-2' style={{ float: 'right' }}>
                                            <Button className='mx-2' onClick={goBack} style={{ background: 'white', color: 'blue' }}>Cancel</Button>
                                            <Button variant="primary" style={{ height: '2.3rem' }} onClick={() => { updateEvent() }} >Update</Button>
                                        </div>
                                    </div>
                                </section>
                            </>
                        </Container>
                    </Col>
                </Row>
            </section>
        </>
    )
}

export default EditEvents
import { createReducer } from "@reduxjs/toolkit";

export const searchFeedData = createReducer({isSearchActive:false,searchFeedData:[],feedPermissions:[],isEditPostData:false,targetingPostId:{postId:''} , selectedTabId: 'all post'},{
    getFeedData: (state,action) => {
        state.isSearchActive = true;
        state.searchFeedData = action;
        state.feedPermissions = action;
    },
    clearFeedData: (state,) => {
        state.searchFeedData = [];
        state.feedPermissions = [];
        state.isSearchActive = false;
    },
    getFeedDataEdit: (state,action) => {
        state.isEditPostData = true;
    },
    doneFeedDataEdit: (state,action) => {
        state.isEditPostData = false;
    },
    getTargetingPara: (state,action) => {
        state.targetingPostId = action;
    },
    doneTargetingPara: (state,action) => {
        state.targetingPostId = {postId:''};
    },
    selectedTabkey : (state , action) => {
        console.log("action" ,action)
        state.selectedTabId = action.key;
    }
})
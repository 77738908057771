import React from "react"
import { Container} from 'react-bootstrap'
const Unauthorized = () => {
    return (
        <Container className='log-wrap p-0' fluid style={{ height: '100vh',display : "flex" , alignItems : "center" ,justifyContent : "center", textAlign : 'center'}}>
            <section >
                <h3>
                        You are not authorized to perform action on this page.<br/>Please contact system administrator.
                </h3>
            </section>
        </Container>
    )
    
}
export default Unauthorized
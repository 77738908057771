import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import CloseButton from 'react-bootstrap/CloseButton';
import * as AiIcons from 'react-icons/ai';
import ReactPaginate from 'react-paginate';
import { apiCall } from '../../../../../action/loginAction';
import image from '../../../../../constant/image';
import {actionpermission} from '../../../../../constant/commonFunctions'
import './AddRole.css'
import moment from 'moment';
import EditRole from './EditRole';
const AddRole = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10)
  const [addRoleList, setAddRoleList] = useState([]);
  const [roleNameList, setRoleListName] = useState([]);
  const [pageData, setPageData] = useState({});
  const [addRoleModal, setAddRoleModal] = useState(false);
  const [roleName, setRoleName] = useState('')
  const [isExist, setIsExist] = useState(false);
  const [permissions , setPermissions]=useState([])
 
  useEffect(() => {
    getRoleData()
    getRoleName()
    let permissionsArray = actionpermission("User Management")
    setPermissions(permissionsArray)
  }, [page])
  useEffect(() => {
    checkValuePresent(roleName);
  }, [roleName])
  //get role data
  const getRoleData = async () => {
    var data = {
      get_role: true,
      per_page: perPage,
      page_number: page,
      preferred_language: 'EN'
    };
    let res = await dispatch(apiCall(data,'/user/role-management'));
    if (res.data.message.MSG_CODE === 100791) {
      let newArray = res.data.data;
      newArray = newArray.map(v => ({ ...v, isActive: false }))
      setAddRoleList(newArray);
      setPageData(res.data.pagination);
    }
    else  if (res.data.message.MSG_CODE === 100130){
     
    }
    else {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again!',
        timer: 4000
      })
    }
  }
  //get role name list
  const getRoleName = async () => {
    var data = {
      get_role_names: true,
      preferred_language: 'EN'
    };
    let res = await dispatch(apiCall(data,'/user/role-management'));
    if (res.data.message.MSG_CODE === 100791) {
      setRoleListName(res.data.data)
    }
  }
  //add new role 
  const addNewRole = async () => {
    if (isExist === true) {
      return
    }
    var data = {
      is_add_role: true,
      requested_by: 'ADMIN',
      role_name: roleName,
      preferred_language: 'EN'
    };
    let res = await dispatch(apiCall(data,'/user/role-management'));
    if (res.data.message.MSG_CODE === 100761) {
      getRoleData()
      getRoleName();
      dispatch({ type: "getNewRoleList"});
      empty();
      Swal.fire({
        icon: 'success',
        text: 'Role has been created successfully',
        timer: 3000
      })
    } else  if (res.data.message.MSG_CODE === 100130){
     
    }else {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again',
        timer: 3000
      })
    }
  }
  //empty 
  const empty = () => {
    setAddRoleModal(false);
    setRoleName('')
  }
  // on key change
  const checkValuePresent = (val) => {
    let isInclude = roleNameList.includes(val)
    if (isInclude === true) {
      setIsExist(true)
    } else {
      setIsExist(false)
    }

  }
  // delete role 
  const deleteRoleSwal = (name, id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this Role",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed === true) {
        deleteRole(name, id)
      }
    })
  }
  const deleteRole = async (name, id) => {
    var data = {
      is_delete_role: true,
      requested_by: 'ADMIN',
      role_name: name,
      role_id: id,
      preferred_language: 'EN'
    };
    let res = await dispatch(apiCall(data,'/user/role-management'));
    if (res.data.message.MSG_CODE === 100771) {
      dispatch({ type: "deleteRoleList"});
      getRoleData();
      getRoleName();
      empty();
      Swal.fire({
        icon: 'success',
        text: 'Role has been created successfully',
        timer: 3000
      })
    } else if (res.data.message.MSG_CODE === 100130){
     
    }else {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again',
        timer: 3000
      })
    }
  }
  // edit Role
  const editRoleData = (id) => {
    setAddRoleList(addRoleList.map(role => role.role_id === id ? {
      ...role, isActive: !role.isActive
    } : role
    ))
  }
  // update role
  const updateRoleData = (id , value) => {
    setAddRoleList(addRoleList.map(role => role.role_id === id ? {
      ...role, role_name:value, isActive: !role.isActive
    } : role
    ))
  }
  // pagination task  
  const handlePageClick = (data) =>{
    let currentPage = data.selected + 1 ;
    setPage(currentPage)
  }
  return (
    <>
      {
        addRoleList.length > 0 &&
        <section className='px-3 pt-3 p-4 w-100' style={{ height: '100%', backgroundColor: '' }} >
          <div className='d-flex justify-content-end mb-4'>
            {permissions.includes('Add Role') && <Button className='mx-2' onClick={() => setAddRoleModal(true)}>Add Role</Button>}
          </div>
          <div className='tableAddRoleClass'>
            <Table hover striped size="sm" className='table-responsive'>
              <thead>
                <tr>
                  <th className='' >Name</th>
                  <th className='' >Date</th>
                  <th className='' >Created by</th>
                  {permissions.includes('Add Role') && <th className='w-25 text-center'> Delete</th>}
                </tr>
              </thead>
              <tbody>
                {
                  addRoleList.map((role, index) => {
                    return (
                      <>
                        {
                          role.isActive === false ?
                            <tr key={index} className='roleItem' >
                              <td className='w-25'>
                                <div className='d-flex align-items-center'>
                                  <img role='button' src={image.porfilePicture} alt='logo' style={{ height: '2rem', width: '2rem', borderRadius: '50%', marginRight: '1em' }} />
                                  <span className=''>{role.role_name}</span>
                                </div>
                              </td>
                              <td className=' w-25'>{moment(role.created_on).format('MM/DD/YYYY')}</td>
                              <td className=' w-25'>{role.requested_by}</td>
                              {permissions.includes('Add Role') && <td className='w-25 text-center'>
                                {/* <span onClick={() => { editRoleData(role.role_id) }} className='mx-2 editIcon'>
                                  <AiIcons.AiFillEdit />
                                </span> */}
                                <span onClick={() => { deleteRoleSwal(role.role_name, role.role_id) }} className='px-1 deleteIcon'>
                                  <AiIcons.AiFillDelete role ={role}/>
                                </span>
                              </td>}
                            </tr> :
                            <tr>
                              < EditRole editRolevalue ={updateRoleData} role={role} editRoleval = {editRoleData} roleNamesList = {roleNameList} />
                            </tr>
                        }

                      </>
                    )
                  })
                }
              </tbody>
            </Table>
            {
              pageData.total_page > 1 && (
                <ReactPaginate
                  className='pagination'
                  breakLabel= {'...'}
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={2}
                  pageCount={pageData.total_page}
                  previousLabel="< prev"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  activeClassName={'active'}
                  pageClassName = {'page-item'}
                  previousClassName = {'page-item'}
                  previousLinkClassName = {'page-link'}
                  pageLinkClassName = {'page-link'}
                  nextClassName = {'page-item'}
                  nextLinkClassName = { 'page-link'}
                  breakClassName = {'page-item'}
                  breakLinkClassName = {'page-link'}
                />
              )
            }
          </div>
        </section>
      }
      <Modal
        show={addRoleModal}
        size="md"
        centered
        backdrop="static"
        className='justify-content-between'
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
            Add Role
          </Modal.Title>
          <CloseButton onClick={() => empty()} />
        </Modal.Header>
        <Modal.Body>
          <>
            <div className='my-1'>
              <p className='mb-1'>
                Role Nmae
              </p>
              <input type="text" placeholder="Role Nmae"
                aria-label="Title" aria-describedby="basic-addon1"
                value={roleName}
                className={`form-control ${isExist ? 'reBorderClass' : ''}`}
                onChange={(e) => setRoleName(e.target.value.toUpperCase())}
              />
              {isExist && <span className="redStar m-0 p-0">Role title should be unique</span>}
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => addNewRole()} className='btn btn-primary'>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddRole
import axios from "axios";
import { useEffect, useState } from "react";

import "./pagiantion.css"
import ReactPaginate from "react-paginate";
import { useDispatch } from 'react-redux';
import { chunkUpload, apiCall } from '../action/loginAction';
function ImagePagination() {
const dispatch = useDispatch();
const [images, setImages] = useState([]);
const [isLoading, setIsLoading] = useState(false);
const [page, setPage] = useState(1);
const [currentImages, setCurrentImages] = useState(null);
const [pageCount, setPageCount] = useState(0);
const [imagesOffset, setImagesOffset] = useState(0);
const [imageData , setImageData] = useState(null)

  useEffect(() => {
    ImageData()
  }, [page]);

  const ImageData = async (pg) => {
    var data = {
      "get_images": true,
      "page_number": page,
      "per_page": 1
    };
    let res = await dispatch(apiCall(data, '/user/blur-images'));
    console.log("res", res.data.data) 
    setImageData(res.data.data)
}
const iSBlurAPI = async (media_id, post_id, value) => {
  var data = {
    "set_image_blur_status": true,
    "media_id": media_id,
    "post_id": post_id,
    "is_image_blur": value
  };
  let res = await dispatch(apiCall(data, '/user/blur-images'));
  console.log(res.data.src)
}

  const onRadioChange = (e,value) => {
    console.log("change")
    console.log(e)
    console.log(value)

    setPage((prevState) => prevState + 1)
    iSBlurAPI(e.media_id, e.post_id,value)
  }
  return (
  <div><h2>My Image Gallery</h2>
  <div className="pagiantion">
    {imageData?.map((image, i) => {
      return (
        <div className="img-wrapper" key={i}>
          <img src={image.url} alt={image.alt_description} style={{maxWidth : "600px"  }}/>
          <p> {image.url}</p>
          {/* <img src={imgUrl} alt={image.url} style={{maxWidth : "600px"}}/> */}
          {/* <p>{image.alt_description} </p> */}
          <div className="d-flex justify-content-around">
        

            <input type="radio" value="Blur" name="gender"  checked={false} onChange={() => onRadioChange(image,true)} /> Blur
            
          
           <input type="radio" value="Not_Blur" name="gender"  checked={false} onChange={() => onRadioChange(image,false)}/> Not_Blur
         
          </div>
         
        </div>
      );
    })}
    
  </div>
  <div className="pagination">
  {isLoading ? (
    <p>Loading...</p>
  ) : (
    <>
     <button
        disabled={page === 1}
        onClick={() => setPage((prevState) => prevState - 1)}
      >
        Prev
      </button>
      <p>{page}</p>
     <button onClick={() => setPage((prevState) => prevState + 1)}>
        Next
      </button>
    </>
  )}
</div></div>
  )
}
export default ImagePagination;
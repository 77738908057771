import React, { useState, useRef, useEffect } from 'react'


const PrivacyPolicy = () =>{
    return(
        <>
            <div className='mx-5 my-4' style={{textAlign : 'justify'}}>
                <h4 className='d-flex justify-content-center fs-1 mt-2'>
                    Privacy Policy
                </h4>
                <div className='mt-3 '>
                MEDISETTER VIETNAM JSC built the Medisetter app as a Free app. This SERVICE is
                provided by MEDISETTER VIETNAM JSC at no cost and is intended for use as is.
                This page is used to inform visitors regarding our policies regarding the collection, use, and
                disclosure of Personal Information if anyone decided to use our Service.
                </div>
                <div className='mt-2' >
                If you choose to use our Service, then you agree to the collection and use of information in
                relation to this policy. The Personal Information that we collect is used for providing and
                improving the Service. We will not use or share your information with anyone except as
                described in this Privacy Policy.
                </div>
                <div className='mt-2' >
                The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions,
                which are accessible at Medisetter unless otherwise defined in this Privacy Policy.
                </div>
                <h5 className='d-flex justify-content-start fs-5 mt-3'>
                Information Collection and Use
                </h5>
                <div className='mt-2' >
                For a better experience, while using our Service, we may require you to provide us with certain
                personally identifiable information, including but not limited to full name, job information, email
                address, telephone. The information that we request will be retained by us and used as described
                in this privacy policy.
                </div>
                <div className='mt-2' >
                The app does use third-party services that may collect information used to identify you.
                </div>
                <div className='mt-2' >
                Link to the privacy policy of third-party service providers used by the app
                </div>
                <div className='mt-2' >
                    <a className='text-decoration-none' href={"https://policies.google.com/privacy"} > Google Play Services</a>
                </div>
                <h5 className='d-flex justify-content-start fs-5 mt-3'>
                Log Data
                </h5>
                <div className='mt-2' >
                We want to inform you that whenever you use our Service, in a case of an error in the app we
                collect data and information (through third-party products) on your phone called Log Data. This
                Log Data may include information such as your device Internet Protocol (“IP”) address, device
                name, operating system version, the configuration of the app when utilizing our Service, the time
                and date of your use of the Service, and other statistics.
                </div>
                <h5 className='d-flex justify-content-start fs-5 mt-3'>
                Cookies
                </h5>
                <div className='mt-2' >
                Cookies are files with a small amount of data that are commonly used as anonymous unique
                identifiers. These are sent to your browser from the websites that you visit and are stored on your
                device's internal memory.
                </div>
                <div className='mt-2' >
                This Service does not use these “cookies” explicitly. However, the app may use third-party code
                and libraries that use “cookies” to collect information and improve their services. You have the
                option to either accept or refuse these cookies and know when a cookie is being sent to your
                device. If you choose to refuse our cookies, you may not be able to use some portions of this
                Service.
                </div>

                <h5 className='d-flex justify-content-start fs-5 mt-3'>
                Service Providers
                </h5>
                <div className='mt-2' >
                We may employ third-party companies and individuals due to the following reasons:
                </div>
                <ul >
                    <li style={{listStyle : 'initial'}}>
                        To facilitate our Service;
                    </li>
                    <li style={{listStyle : 'initial'}}>
                        To provide the Service on our behalf;
                    </li>
                    <li style={{listStyle : 'initial'}}>
                        To perform Service-related services; or
                    </li>
                    <li style={{listStyle : 'initial'}}>
                    To assist us in analyzing how our Service is used.
                    </li>
                </ul>
                <div className='mt-2' >
                We want to inform users of this Service that these third parties have access to their Personal
                Information. The reason is to perform the tasks assigned to them on our behalf. However, they
                are obligated not to disclose or use the information for any other purpose.
                </div>
                <h5 className='d-flex justify-content-start fs-5 mt-3'>
                Security
                </h5>
                <div className='mt-2' >
                We value your trust in providing us your Personal Information, thus we are striving to use
commercially acceptable means of protecting it. But remember that no method of transmission
over the internet, or method of electronic storage is 100% secure and reliable, and we cannot
guarantee its absolute security.
                </div>
                <h5 className='d-flex justify-content-start fs-5 mt-3'>
                Links to Other Sites
                </h5>
                <div className='mt-2' >
                This Service may contain links to other sites. If you click on a third-party link, you will be
directed to that site. Note that these external sites are not operated by us. Therefore, we strongly
advise you to review the Privacy Policy of these websites. We have no control over and assume
no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                </div>
                <h5 className='d-flex justify-content-start fs-5 mt-3'>
                Children’s Privacy
                </h5>
                <div className='mt-2' >
                These Services do not address anyone under the age of 13. We do not knowingly collect
personally identifiable information from children under 13 years of age. In the case we discover
that a child under 13 has provided us with personal information, we immediately delete this from
our servers. If you are a parent or guardian and you are aware that your child has provided us
with personal information, please contact us so that we will be able to do the necessary actions.
                </div>
                <h5 className='d-flex justify-content-start fs-5 mt-3'>
                Changes to This Privacy Policy
                </h5>
                <div className='mt-2' >
                We may update our Privacy Policy from time to time. Thus, you are advised to review this page
periodically for any changes. We will notify you of any changes by posting the new Privacy
Policy on this page.
                </div>
                <div className='mt-2' >
                This policy is effective as of 2022-12-29
                </div>
                <h5 className='d-flex justify-content-start fs-5 mt-3'>
                Contact Us
                </h5>
                <div className='mt-2' >
                If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us
at <a lassName='text-decoration-none ' href={"mailto: help@medisetter.com"}> help@medisetter.com.</a>
                </div>

            </div>
        </>
    )
}
export default PrivacyPolicy
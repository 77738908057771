import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "../../reducer/rootReducer";
import { loaderReducer } from "../../reducer/loaderReducer";
import { addRoleUpadate } from "../../reducer/addRoleUpdate";
import { searchFeedData } from "../../reducer/searchFeedData";
import { channelsFeedData } from "../../reducer/channelsFeedData";
import { getUpdatedProfile } from "../../reducer/getUpdatedProfile";
const store = configureStore({
   reducer : {
    root: rootReducer,
    loader:loaderReducer,
    role:addRoleUpadate,
    feedData:searchFeedData,
    channelData:channelsFeedData,
    profileUrlData:getUpdatedProfile
   }
});
export default store
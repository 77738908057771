import React, { useEffect, useState, useRef } from 'react'
import LeftSideBar from '../../SideBar/LeftSideBar'
import { Row, Col, Container } from 'react-bootstrap'
import Badge from 'react-bootstrap/Badge';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { apiCall } from '../../../../action/loginAction';
import Swal from 'sweetalert2';
import CloseButton from 'react-bootstrap/CloseButton';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { MultiSelect } from "react-multi-select-component";
import 'react-quill/dist/quill.snow.css';
import './Channels.css'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { actionpermission } from '../../../../constant/commonFunctions'
import ChannelsFeed from './ChannelsFeed';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import useDebounce from '../../../../constant/useDebouncing';
import { compressionImage } from '../../../../constant/compressImage';
import Unauthorized from './Unauthorized';
import { getShareLink } from '../../../../constant/getShareLink';

const Channels = (props) => {
    var navigate = useNavigate();
    const dispatch = useDispatch();
    //model hide or show
    const [modalShow, setModalShow] = React.useState(false);
    const [editModalShow, setEditModalShow] = React.useState(false);
    const [pendingUserModalShow, setPendingUserModalShow] = React.useState(false);
    const [profileDetails, setPorfileDetails] = useState({});
    //model details
    const [channelName, setChannelName] = useState('');
    const [channelType, setChannelType] = useState('')
    const [secondarySpeciality, setSecondarySpeciality] = useState([]);
    const [bannerImage, setBannerImage] = useState({ fileName: '', media_id: '' });
    const [thumbnailImage, seThumbnailImage] = useState({ fileName: '', media_id: '' });
    const [content, setContent] = useState('');
    const [sponsors, setSponsor] = useState([]);
    const [registrationPageData, setRegistrationPageData] = useState([]);
    const [tags, setTags] = useState([]);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState({});
    const [channelsData, setChannelsData] = useState([]);
    const [totalChannelsData, setTotalChannelsData] = useState(0)
    const [searchValue, setSearchValue] = useState('');
    const email = localStorage.getItem('email');
    const [specialitiesList, setSpecialitiesList] = useState([]);
    const [topicTagList, setTopicTagList] = useState([]);
    const [topicTag, setTopicTag] = useState([]);
    const [editTopicTag, setEditTopicTag] = useState([]);
    const [editSecondarySpeciality, setEditSecondarySpeciality] = useState([]);
    const [thumbnailImageUrl, setThumbnailImageUrl] = useState('');
    const [bannerImageUrl, seBannerImageUrl] = useState('');
    const [perPage, setPerPage] = useState(10)
    const [channelTypeFilter, setChannelTypeFilter] = useState('')
    const [permissions, setPermissions] = useState([])
    const [isEditChannel, setIsEditChannel] = useState({});
    const [isDeleteChannelId, setIsDeleteChannelId] = useState('');
    const [isEditBannerImage, setIsEditBannerImage] = useState(false);
    const [isEditThumbnailImage, setIsEditThumbnailImage] = useState(false);
    const debouceSearchTerm = useDebounce(searchValue, 600);
    const initialRender = useRef(true);
    const isBackButton = useRef(false);
    const [userType,setUserType] = useState("Admin");
    //previous page
    const [isBack, setIsBack] = useState(false);

    // get list of channel 
    // useEffect(()=>{
    //     return () => {
    //         console.log('Child unmounted');
    //       };
    // },[])
    useEffect(() => {
        window.onpopstate = () => {
            let currentPage = localStorage.getItem('currentChannelPage');
            handlePageClick(currentPage, true)
        }
        getChannelList(1, perPage, channelTypeFilter);
        getRegistrationPageData()
        getTopicTagData();
        let permissionsArray = actionpermission("Channels")
        setPermissions(permissionsArray)
    }, [])
    // useEffect(() => {
    //     getChannelList(1, perPage, channelTypeFilter);
    //     setPage(1)
    //     getRegistrationPageData()
    //     getTopicTagData();
    //     let permissionsArray = actionpermission("Channels")
    //     setPermissions(permissionsArray)
    // }, [perPage])
    useEffect(() => {
        if (isDeleteChannelId !== '') {
            deleteChannelApi(isDeleteChannelId)
        }
    }, [isDeleteChannelId])
    useEffect(() => {
        if (Object.keys(isEditChannel).length !== 0) {
            openEditModel(isEditChannel)
        }
    }, [isEditChannel])
    //debounce
    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        }else{
            if (debouceSearchTerm) {
                searchChannel(1,debouceSearchTerm)
            }else{
                getChannelList(1, perPage, channelTypeFilter);
                setPage(1)
            }
        }
    }, [debouceSearchTerm,channelTypeFilter,perPage])

    //get all regisetration page data 
    const getRegistrationPageData = async () => {
        var data = {
            preferred_language: 'EN',
        };
        let res = await dispatch(apiCall(data, '/user/get-registration-page-info'));
        if (res.data.message.MSG_CODE === 100281) {
            setRegistrationPageData(res.data.data)
            let newSpecArray = [];
            for (let i = 0; i < res.data.data.specialities.length; i++) {
                newSpecArray.push({ 'label': res.data.data.specialities[i].label_name, 'value': res.data.data.specialities[i].identifier_id })
            }
            setSpecialitiesList(newSpecArray)
        } else {
            Swal.fire({
                icon: 'error',
                text: 'Post Update Failure',
                timer: 3000
            })
        }
    }
    // model operation
    const openModel = () => {
        setModalShow(true);
    }
    const closeModel = () => {
        setModalShow(false);
        empty();
    }
    // update key
    const updateKeyList = (list1, list2) => {
        let newSpecArray = [];
        for (let i = 0; i < list1.length; i++) {
            newSpecArray.push({ 'label': list1[i].label_name, 'value': list1[i].identifier_id })
        }
        setEditSecondarySpeciality(newSpecArray);
        let newTopicArray = [];
        for (let i = 0; i < list2.length; i++) {
            newTopicArray.push({ 'label': list2[i].name, 'value': list2[i].topic_tag_id })
        }
        setEditTopicTag(newTopicArray)
    }
    const closeEditModel = () => {
        setEditModalShow(false);
        empty();
    }
    // push to sponspor array 
    const sponsporArrayPush = (e) => {
        if (e.key !== 'Enter') return
        const value = e.target.value
        if (!value.trim()) return
        if (profileDetails.sponsor === undefined) {
            setSponsor([...sponsors, value])
        } else {
            setPorfileDetails({ ...profileDetails, sponsor: [...profileDetails.sponsor, value] })
        }
        e.target.value = ''
    }
    // changeKeyName
    const getNewTopicTagList = (list) => {
        let newSpecArray = [];
        for (let i = 0; i < list.length; i++) {
            newSpecArray.push({ 'name': list[i].label, 'topic_tag_id': list[i].value })
        }
        return newSpecArray
    }
    const getSpecialityList = (list) => {
        let newSpecArray = [];
        for (let i = 0; i < list.length; i++) {
            newSpecArray.push({ 'label_name': list[i].label, 'identifier_id': list[i].value })
        }
        return newSpecArray
    }
    // create channel 
    const createChannel = async () => {
        if (channelName !== '' && channelType !== '' && bannerImage.media_id !== '' &&
            thumbnailImage.media_id !== '') {
            let specialityList = getSpecialityList(secondarySpeciality);
            let newTopicTagList = getNewTopicTagList(topicTag);
            var data = {
                create_channel: true,
                channel_name: channelName,
                channel_type: channelType,
                sponsor: sponsors,
                description: content,
                banner_image: bannerImage.media_id,
                thumbnail_image: thumbnailImage.media_id,
                topic_tags: newTopicTagList,
                specialities: specialityList
            }
            let res = await dispatch(apiCall(data, '/user/channel-admin'));
            if (res.data.message.MSG_CODE === 100361) {
                getChannelList(1, perPage, channelTypeFilter);
                closeModel();
                Swal.fire({
                    icon: 'success',
                    text: 'Channel has been created successfully',
                    timer: 3000
                })
            } else if (res.data.message.MSG_CODE === 100390) {
                Swal.fire({
                    icon: 'error',
                    text: 'Channel was not created, please try again later',
                    timer: 3000
                })
                getChannelList(1, perPage, channelTypeFilter);
                closeModel();
            } else if (res.data.message.MSG_CODE === 100380) {
                Swal.fire({
                    icon: 'warning',
                    text: 'Channel name already exist please change it',
                    timer: 3000
                })
            } else if (res.data.message.MSG_CODE === 100620) {
                Swal.fire({
                    icon: 'warning',
                    text: 'Channel name already exist please change it',
                    timer: 3000
                })
            } else if (res.data.message.MSG_CODE === 100010) {
                Swal.fire({
                    icon: 'warning',
                    text: 'Details not entered correctly',
                    timer: 3000
                })
            }
        } else {
            if (channelName === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please fill channel name',
                    timer: 4000
                })
            } else if (bannerImage.media_id === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please upload banner image',
                    timer: 4000
                })
            } else if (thumbnailImage.media_id === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please upload thumbnail image',
                    timer: 4000
                })
            } else if (channelType === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please select channel type ',
                    timer: 4000
                })
            }

        }

    }
    //delete channel 
    const deleteChannelApi = async (id) => {
        var data = {
            delete_channel: true,
            channel_id: id
        };
        let res = await dispatch(apiCall(data, '/user/channel-admin'));
        setIsDeleteChannelId('')
        getChannelList(1, perPage, channelTypeFilter);
        if (res.data.message.MSG_CODE === 100371) {
            Swal.fire({
                icon: 'success',
                text: 'Channel has been deleted successfully',
                timer: 3000
            })
        } else if (res.data.message.MSG_CODE === 100410) {
            Swal.fire({
                icon: 'error',
                text: 'Channel has been deleted unsuccessfully ',
                timer: 3000
            })
        } else if (res.data.message.MSG_CODE === 100400) {
            Swal.fire({
                icon: 'error',
                text: 'Channel does not exist',
                timer: 3000
            })
        }
    }
    //edit channel 
    const editChanneldetails = async (id) => {
        if (profileDetails.channel_name !== '' && profileDetails.channel_type !== '') {
            let specialityList = getSpecialityList(editSecondarySpeciality);
            let newTopicTagList = getNewTopicTagList(editTopicTag);
            var data = {
                edit_channel: true,
                channel_id: id,
                channel_name: profileDetails.channel_name,
                banner_image: bannerImage.media_id === "" ? null : bannerImage.media_id,
                thumbnail_image: thumbnailImage.media_id === "" ? null : thumbnailImage.media_id,
                channel_type: profileDetails.channel_type,
                description: profileDetails.description,
                sponsor: profileDetails.sponsor,
                topic_tags: newTopicTagList,
                specialities: specialityList
            };
            let res = await dispatch(apiCall(data, '/user/channel-admin'));
            setIsEditChannel({})
            closeEditModel();
            setPage(1);
            getChannelList(1, perPage, channelTypeFilter);
            if (res.data.message.MSG_CODE === 100371) {
                Swal.fire({
                    icon: 'success',
                    text: 'Changes has been done successfully',
                    timer: 3000
                })
            } else if (res.data.message.MSG_CODE === 100410) {
                Swal.fire({
                    icon: 'error',
                    text: 'There has benn some issue while updating data, please try again later',
                    timer: 3000
                })
            } else if (res.data.message.MSG_CODE === 100400) {
                Swal.fire({
                    icon: 'error',
                    text: 'Channel does not exist',
                    timer: 3000
                })
            }
        } else {
            if (profileDetails.channel_name === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please fill channel name',
                    timer: 4000
                })
            } else if (profileDetails.channel_type === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please select channel type ',
                    timer: 4000
                })
            } else if (profileDetails.description === '' && profileDetails.description !== '<p><br></p>') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please add description',
                    timer: 4000
                })
            } else if (editSecondarySpeciality.length === 0) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please select speciality ',
                    timer: 4000
                })
            } else if (editTopicTag.length === 0) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please select topic tag',
                    timer: 4000
                })
            }

        }

    }
    // add channel name
    const addChannelName = (name) => {
        setChannelName(name)
    }
    //empty all data
    const empty = () => {
        setChannelName('');
        setChannelType('');
        setBannerImage({ fileName: '', media_id: '' });
        seThumbnailImage({ fileName: '', media_id: '' });
        setContent('');
        setThumbnailImageUrl('')
        seBannerImageUrl('')
        setSponsor([]);
        setPage(1);
        setPorfileDetails({});
        setSearchValue('');
        setSecondarySpeciality([]);
        setTopicTag([]);
    }
    // get channel data and search 
    const getChannelList = async (pg, per_page, channel_type) => {
        dispatch({ type: "clearChannelFeedData" });
        var data = {
            get_channels_list: true,
            per_page: per_page,
            page_number: pg,
            preferred_language: 'EN',
            channel_type_filter: channel_type,
            search_channel: searchValue,
        };
        let res = await dispatch(apiCall(data, '/user/channel-admin'));
        if (res.data.message.MSG_CODE === 100351) {
            dispatch({ type: "getChannelFeedData",...res.data });
            setChannelsData(res.data.data);
            setTotalChannelsData(res.data.pagination.total_data)
            setPageData(res.data.pagination);
        }
        else if (res.data.message.MSG_CODE === 100680) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please try again later',
                timer: 3000
            })
        }else if (res.data.message.MSG_CODE === 100130){
            setUserType("Enterprise")
        }
    }
    const searchChannel = async (page,value) => {
        dispatch({ type: "clearChannelFeedData" });
        var data = {
            get_channels_list: true,
            search_channel: value,
            per_page: perPage,
            page_number: page,
            preferred_language: 'EN',
            channel_type_filter: channelTypeFilter,
        };
        let res = await dispatch(apiCall(data, '/user/channel-admin'));
        if (res.data.message.MSG_CODE === 100351) {
            dispatch({ type: "getChannelFeedData", ...res.data });
            setChannelsData(res.data.data);
            setPage(page)
            setTotalChannelsData(res.data.pagination.total_data)
            setPageData(res.data.pagination);
        }
        else if (res.data.message.MSG_CODE === 100680) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please try again later',
                timer: 3000
            })
        }
    }
    //remove tag
    const removeTag = (index) => {
        setSponsor(sponsors.filter((el, i) => i !== index))
    }
    //remove tag edit
    const removeEditTag = (index) => {
        setSponsor(sponsors.filter((el, i) => i !== index))
    }
    //get topic data 
    const getTopicTagData = async () => {
        var data = {
            get_topic_tags_list: true,
            paginated_result:false
        };
        let res = await dispatch(apiCall(data, '/user/topic-tags'));
        if (res.data.message.MSG_CODE === 100711) {
            let newTopicArray = [];
            for (let i = 0; i < res.data.data.length; i++) {
                newTopicArray.push({ 'label': res.data.data[i].name, 'value': res.data.data[i].topic_tag_id })
            }
            setTopicTagList(newTopicArray)
        }
        else if (res.data.message.MSG_CODE === 100130){
            console.log("Enterprise user")
        }
        else {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please try again later',
                timer: 3000
            })
        }
    }
    // upload api
    const uploadImage = async (files,type) => {
        dispatch({ type: "showLoader"});
        let arrayFile = []
        for (let i = 0; i < files.length; i++) {
            if ( files[i].type === 'image/heif' || files[i].type === 'image/HEIF') {
                arrayFile.push(files[i]);
            }else{
                const image = await compressionImage(files[i]);
                arrayFile.push(image);  
            }

        }
        dispatch({ type: "hideLoader"});
        uploadFile(arrayFile[0], type);
    };
    const uploadFile = async (e, fileName) => {
        if (fileName === 'Banner') {
            setBannerImage({ fileName: e.name })
        } else {
            seThumbnailImage({ fileName: e.name })
        }
        const convertString = await convertBase64(e);
        const base64Array = convertString.split(",");
        const base64 = base64Array[1];
        var data = {
            email: email,
            file_path: "channel",
            file_data: base64,
            file_name: e.name,
        };
        let res = await dispatch(apiCall(data, '/user/upload'));
        if (res.data.message.MSG_CODE === 100231) {
            if (fileName === 'Banner') {
                setBannerImage({ media_id: res.data.data.media_id })
                seBannerImageUrl(res.data.data.url)
            } else {
                seThumbnailImage({ media_id: res.data.data.media_id })
                setThumbnailImageUrl(res.data.data.url)
            }
        } else if (res.data.message.MSG_CODE === 100270) {
            Swal.fire({
                icon: 'error',
                text: 'Not valid extension please upload again (accept only JPEG, PNG, JPG)',
                timer: 3000
            })
        } else if (res.data.message.MSG_CODE === 100580 || res.data.message.MSG_CODE === 100010) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please upload again',
                timer: 3000
            })
        }
        else if (res.data.message.MSG_CODE === 100580 || res.data.message.MSG_CODE === 100010) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please upload again',
                timer: 3000
            })
        }
    }
    // base64 conversion
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = (() => {
                resolve(fileReader.result);
            });
            fileReader.onerror = ((error) => {
                reject(error)
            });
        });
    };
    // change height 
    const changeHeightTextArea = (id) => {
        setTimeout(() => {
            var textarea = document.querySelector(id);
            if (textarea !== null && textarea !== undefined) {

                textarea.addEventListener("keydown", e => {
                    let scroll_height = e.target.scrollHeight;
                    textarea.style.height = `${scroll_height}px`;
                });
            }
        }, 10);
    }
    //pagination
    const handlePageClick = (data,isBack) => {
        let currentPage 
        if (data.selected || data.selected === 0) {
            currentPage = data.selected + 1;
        }else{
            currentPage = Number(data)
        }
        localStorage.setItem('currentChannelPage', currentPage);
        setPage(currentPage)
        if (isBack) {
            setChannelsData([])
            setTimeout(()=>{
                 getChannelList(currentPage, perPage, channelTypeFilter);
            },200)
        }else{
            getChannelList(currentPage, perPage, channelTypeFilter);
        }

    }
    // edit model 
    const openEditModel = (profile) => {
        setPorfileDetails(profile)
        updateKeyList(profile.specialities, profile.topic_tags);
        setTimeout(() => {
            setEditModalShow(true);
        }, 500)
    }
    const createLink = async (data) => {
        console.log("data.category_id", data.channel_id)
        let resultLink = await getShareLink('Channel',data.channel_id)
        navigator.clipboard
        .writeText(resultLink)
        .then(() => {
            alert("successfully copied");
        })
        .catch(() => {
            alert("something went wrong");
        });
  }
    return (
        <>
            <section>
               
                        <Row style={{ height: '100vh' }}>
                        <Col className='' xxl={2} xl={2} lg={3} md={3} style={{ background: 'linear-gradient(90deg, #277AFC 0%, #27B3FC 100%)', height: '100%' }} >
                            <LeftSideBar />
                        </Col>
                        {
                    userType === "Admin" ? (
                        <Col className='bg-prime p-0 channelScreen' xxl={10} xl={10} lg={9} md={9} style={{ height: '100%' }}>
                            <Container className='log-wrap p-0' fluid style={{ height: '100vh' }}>
                                <>
                                    <section className='px-3 pt-3' style={{ height: '100%' }}>
                                        <div>
                                            <h5>Channels</h5>
                                            <hr></hr>
                                        </div>
                                        <Row className='w-100 px-2'>
                                            <Col xxl={4} xl={4} lg={4} md={12} className='p-0 pl-2'>
                                                <InputGroup className="mb-3 w-100">
                                                    <Form.Control
                                                        placeholder="Channel"
                                                        aria-label="Channel"
                                                        aria-describedby="basic-addon1"
                                                        value={searchValue}
                                                        type='text'
                                                        onChange={(e) => setSearchValue(e.target.value)}
                                                        style={{ cursor: 'auto !important',paddingRight:'1.6rem' }}
                                                    />
                                                    {
                                                        searchValue.trim().length === 0 ?
                                                        <span role='button' className='searchIcon' onClick={() => { }}><BsIcons.BsSearch /></span> :
                                                        <span role='button' className='searchIcon' onClick={() => {setSearchValue('')}}><AiIcons.AiOutlineClose /></span>
                                                    }
                                                </InputGroup>
                                            </Col>
    
                                            <Col xxl={4} xl={4} lg={4} md={12} className='p-0 pl-2' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p className='totalChannelHeading'>
                                                    Total Channels : {totalChannelsData}
                                                </p>
                                                <DropdownButton id="dropdown-basic-button" title={perPage} size="sm">
                                                    <Dropdown.Item onClick={() => setPerPage(10)} >10</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => setPerPage(25)} >25</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => setPerPage(50)} >50</Dropdown.Item>
                                                </DropdownButton>
                                                {channelType}
                                                <DropdownButton id="dropdown-basic-button" title={channelTypeFilter === "" ? 'All' : channelTypeFilter} size="sm">
                                                    <Dropdown.Item onClick={() => setChannelTypeFilter('')} >All</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => setChannelTypeFilter('Public')} >Public</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => setChannelTypeFilter('Private')} >Private</Dropdown.Item>
                                                </DropdownButton>
                                            </Col>
                                            {permissions.includes("Create channel") && <Col xxl={4} xl={4} lg={4} md={12} className=' p-0 buttonsPendingCreateChannel'>
                                                {/* <Button className='mx-2' variant="primary" style={{ height: '2.3rem' }} onClick={() => { openPendingUserLisModel() }}>Pending user</Button> */}
                                                <Button variant="primary" style={{ height: '2.3rem' }} onClick={() => { openModel() }}>Create channel</Button>
                                            </Col>
                                            }
                                        </Row>
                                        <div className='channelsListDiv'>
                                            {
                                                channelsData.length > 0 ?
                                                <>
                                                    <ChannelsFeed channelData={channelsData} perPage={perPage} sharePost={setIsEditChannel} deleteChannel={setIsDeleteChannelId} />
                                                    <ReactPaginate
                                                        className='pagination'
                                                        breakLabel={'...'}
                                                        nextLabel="next >"
                                                        onPageChange={handlePageClick}
                                                        pageRangeDisplayed={2}
                                                        marginPagesDisplayed={2}
                                                        pageCount={pageData.total_page}
                                                        previousLabel="< prev"
                                                        renderOnZeroPageCount={null}
                                                        containerClassName="pagination"
                                                        activeClassName={'active'}
                                                        pageClassName={'page-item'}
                                                        previousClassName={'page-item'}
                                                        previousLinkClassName={'page-link'}
                                                        pageLinkClassName={'page-link'}
                                                        nextClassName={'page-item'}
                                                        nextLinkClassName={'page-link'}
                                                        breakClassName={'page-item'}
                                                        forcePage={page-1}
                                                        breakLinkClassName={'page-link'}
                                                    />
                                                </>:
                                                <h5 style={{ textAlign: 'center', marginTop: '2rem', color: 'steelblue' }}>
                                                    No Data available
                                                </h5>
                                            }
                                        </div>
                                    </section>
                                </>
                            </Container>
                        </Col>
                         ) : (
                            <Col className='bg-prime p-0 channelScreen' xxl={10} xl={10} lg={9} md={9} style={{ height: '100%' }}>
                                <Unauthorized/>
                            </Col>
                         )
                    }
                    </Row>
            </section>
            <Modal
                show={modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='justify-content-between'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
                        Channel Detail
                    </Modal.Title>
                    <CloseButton onClick={closeModel} />
                </Modal.Header>
                <Modal.Body>
                    <section>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12}>
                                <div className='channelScreenBox'>
                                    <div className='my-1'>
                                        <p>
                                            Channel name <span className="redStar">&#42;</span>
                                        </p>
                                      
                                        <input type="text" className="form-control"
                                            placeholder="channel name" aria-label="Title"
                                            aria-describedby="basic-addon1"
                                            value={channelName}
                                            onChange={(channelName) => addChannelName(channelName.target.value)}
                                        />
                                    </div>
                                    <div className='my-2'>
                                        <p>Banner image <span>(jpeg,png)</span> <span className="redStar">&#42;</span>
                                            <span>{bannerImageUrl !== '' ? <a href={bannerImageUrl} target="_blank" rel="noreferrer">Preview</a> : null}</span></p>
                                        <input type="file" className="form-control"
                                            aria-describedby="basic-addon1"
                                            accept="image/*"
                                            onChange={(event) => uploadImage(event.target.files, 'Banner')}
                                        />
                                    </div>
                                    <div className='my-2'>
                                        <p>Thumbnail image <span>(jpeg,png)</span> <span className="redStar">&#42;</span>
                                            <span>{thumbnailImageUrl !== '' ? <a href={thumbnailImageUrl} target="_blank" rel="noreferrer">Preview</a> : null}</span></p>
                                        <input type="file" className="form-control"
                                            aria-describedby="basic-addon1"
                                            accept="image/*"
                                            onChange={(event) => uploadImage(event.target.files, 'Thumbnail')}
                                        />
                                    </div>
                                    <div className='my-2'>
                                        <p>Channel type <span className="redStar">&#42;</span></p>
                                        <div className='d-flex'>
                                            <input type="radio" className="" name='channelType' value='Private' checked={channelType === 'Private'} onChange={e => setChannelType(e.target.value)} />
                                            <label className="mx-2" >Private</label><br></br>
                                            <input className="mx-2" type="radio" name='channelType' value='Public' checked={channelType === 'Public'} onChange={e => setChannelType(e.target.value)} />
                                            <label className="mx-2" >Public</label><br></br>
                                        </div>
                                    </div>
                                    <div className='my-2'>
                                        <p>
                                            Description
                                        </p>
                                        <textarea
                                            name="searchValue" className='channelDiscInputBox'
                                            id='channelDescriptionInputBox1'
                                            rows="2" cols="200"
                                            placeholder="What's happening"
                                            value={content}
                                            onChange={(e) => setContent(e.target.value)}
                                            onKeyUp={changeHeightTextArea('#channelDescriptionInputBox1')}
                                        >
                                        </textarea>
                                    </div>
                                    <div className='my-2'>
                                        <h6> Specialties </h6>
                                        {
                                            registrationPageData.specialities !== undefined ?
                                                <>
                                                    <MultiSelect
                                                        options={specialitiesList}
                                                        value={secondarySpeciality}
                                                        onChange={setSecondarySpeciality}
                                                        labelledBy="Select"
                                                    />
                                                </> : null

                                        }
                                    </div>
                                    <div className='my-2'>
                                        <h6> Topic tags </h6>
                                        <MultiSelect
                                            options={topicTagList}
                                            value={topicTag}
                                            onChange={setTopicTag}
                                            labelledBy="Select tags"
                                            placeholder="Select tags"
                                        />
                                    </div>
                                    <div className='my-2'>
                                        <p className='m-0'>Sponsor:- (please press enter to add sponspor in sponsor list) </p>
                                        {sponsors.length > 0 ?
                                            <span>[{
                                                sponsors.map((sponsor, index) => {
                                                    return (
                                                        <div className='tagsItem' key={index}>
                                                            <span>{sponsor}</span>
                                                            <span className='close' onClick={() => { removeTag(index) }}> &#x2717;</span>
                                                        </div>
                                                    )
                                                })
                                            }]</span> : null
                                        }
                                        <input type="text" className="form-control"
                                            placeholder=" Channel sponsor" aria-label="Title"
                                            aria-describedby="basic-addon1"
                                            onKeyDown={sponsporArrayPush}
                                        />
                                    </div>
                                    <div className='my-3' style={{ float: 'right' }}>
                                        <Button className='mx-2' onClick={closeModel} style={{ background: 'white', color: 'blue' }}>Close</Button>
                                        <Button onClick={createChannel}>Create</Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </Modal.Body>
            </Modal>
            <Modal
                {...profileDetails}
                show={editModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='justify-content-between'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
                        Channel Detail
                    </Modal.Title>
                    <CloseButton onClick={closeEditModel} />
                </Modal.Header>
                <Modal.Body>
                    <section>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12}>
                                <div className='channelScreenBox'>
                                    <div className='my-1'>
                                        <div className='d-flex justify-content-between mb-2'>
                                        <p>
                                            Channel name <span className="redStar">&#42;</span>
                                        </p>
                                        <Button style={{backgroundColor : "#277AFC"}} onClick={() => createLink(profileDetails)}>Share link</Button>
                                        </div>

                                        <input type="text" className="form-control"
                                            placeholder="channel name" aria-label="Title"
                                            aria-describedby="basic-addon1"
                                            value={profileDetails.channel_name}
                                            onChange={(name) => setPorfileDetails({ ...profileDetails, channel_name: name.target.value })}
                                        />
                                    </div>
                                    <div className='my-2'>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <p>Banner image <span>(jpeg,png)</span> <span className="redStar">&#42;</span>
                                                    <span className='mx-3'>{bannerImageUrl !== '' ? <a href={bannerImageUrl} target="_blank" rel="noreferrer">Preview</a> : <a href={profileDetails.banner_image} target="_blank" rel="noreferrer">Preview</a>}</span>
                                                </p>
                                            </div>
                                            <div onClick={() => { setIsEditBannerImage(!isEditBannerImage) }} style={{ color: 'green', cursor: 'pointer' }} className='px-4'>
                                                <AiIcons.AiFillEdit />
                                            </div>
                                        </div>
                                        {
                                            isEditBannerImage === true ?
                                                <input type="file" className="form-control"
                                                    aria-describedby="basic-addon1"
                                                    accept="image/png, image/jpeg"
                                                    onChange={(event) => uploadImage(event.target.files, 'Banner')}
                                                /> : null
                                        }

                                    </div>
                                    <div className='my-2'>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <p>Thumbnail image <span>(jpeg,png)</span> <span className="redStar">&#42;</span>
                                                    <span className='mx-3'>{thumbnailImageUrl !== '' ? <a href={thumbnailImageUrl} target="_blank" rel="noreferrer">Preview</a> : <a href={profileDetails.thumbnail_image} target="_blank" rel="noreferrer">Preview</a>}</span>
                                                </p>
                                            </div>
                                            <div onClick={() => { setIsEditThumbnailImage(!isEditThumbnailImage) }} style={{ color: 'green', cursor: 'pointer' }} className='px-4'>
                                                <AiIcons.AiFillEdit />
                                            </div>
                                        </div>
                                        {
                                            isEditThumbnailImage === true ?
                                                <input type="file" className="form-control"
                                                    aria-describedby="basic-addon1"
                                                    accept="image/png, image/jpeg"
                                                    onChange={(event) => uploadImage(event.target.files, 'Thumbnail')}
                                                /> : null
                                        }
                                    </div>
                                    <div className='my-2'>
                                        <p>
                                            Channel type <span className="redStar">&#42;</span>
                                        </p>
                                        <div className='d-flex'>
                                            <input type="radio" className="" name='channelType' value='Private' checked={profileDetails.channel_type === 'Private'}
                                                onChange={e => setPorfileDetails({ ...profileDetails, channel_type: e.target.value })} />
                                            <label className="mx-2" >Private</label><br></br>
                                            <input className="mx-2" type="radio" name='channelType' value='Public' checked={profileDetails.channel_type === 'Public'}
                                                onChange={e => setPorfileDetails({ ...profileDetails, channel_type: e.target.value })} />
                                            <label className="mx-2" >Public</label><br></br>
                                        </div>
                                    </div>
                                    <div className='my-2'>
                                        <p>
                                            Description
                                        </p>
                                        <textarea
                                            name="searchValue" className='channelDiscInputBox'
                                            id='channelDescriptionInputBox2'
                                            rows="2" cols="200"
                                            placeholder="What's happening"
                                            value={profileDetails.description}
                                            onChange={(e) => setPorfileDetails({ ...profileDetails, description: e.target.value })}
                                            onKeyDown={changeHeightTextArea('#channelDescriptionInputBox2')}
                                        >
                                        </textarea>
                                    </div >
                                    <div className='my-2'>
                                        <h6>
                                            Specialities
                                        </h6>
                                        {
                                            profileDetails.specialities !== undefined ?
                                                <>
                                                    <MultiSelect
                                                        options={specialitiesList}
                                                        value={editSecondarySpeciality}
                                                        onChange={setEditSecondarySpeciality}
                                                        labelledBy="Select"
                                                    />
                                                </> : null

                                        }
                                    </div>
                                    {
                                        profileDetails.topic_tags !== undefined ?
                                            <div className='my-2'>
                                                <span className='my-2'>Tags:- </span>
                                                <MultiSelect
                                                    options={topicTagList}
                                                    value={editTopicTag}
                                                    onChange={setEditTopicTag}
                                                    labelledBy="Select"
                                                />
                                            </div> : null
                                    }
                                    {
                                        profileDetails.sponsor !== undefined ?
                                            <div className='my-2'>
                                                <p className='my-2'>Sponsor:- (Enter) </p>
                                                {profileDetails.sponsor.length > 0 ?
                                                    <span>[{
                                                        profileDetails.sponsor.map((sponsor, index) => {
                                                            return (
                                                                <div className='tagsItem' key={index}>
                                                                    <span>{sponsor}</span>
                                                                    <span className='close' onClick={() => { removeEditTag(index) }}> &#x2717;</span>
                                                                </div>
                                                            )
                                                        })
                                                    }]</span> : null
                                                }
                                                <input type="text" className="form-control"
                                                    placeholder=" Channel sponsor" aria-label="Title"
                                                    aria-describedby="basic-addon1"
                                                    onKeyDown={sponsporArrayPush}
                                                />
                                            </div> : null
                                    }
                                    
                                </div>
                            </Col>
                        </Row>
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={closeEditModel} style={{ background: 'white', color: 'blue' }}>Close</Button>
                    <Button onClick={() => { editChanneldetails(profileDetails.channel_id) }}>Update</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Channels
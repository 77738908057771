import React from 'react'
import AdminPanelScreen from '../AdminPanel/AdminPanelScreen';
import {Route,Routes} from "react-router-dom";
import Request from '../AdminPanel/RightSide/Pages/Request';
// import Welcome from '../../features/auth/Welcome';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import Events from '../AdminPanel/RightSide/Pages/Events';
import CreateEvents from '../AdminPanel/RightSide/Pages/CreateEvents';
import EditEvents from '../AdminPanel/RightSide/Pages/EditEvent';
import Channels from '../AdminPanel/RightSide/Pages/Channels';
import IndivisualChannel from '../AdminPanel/RightSide/Pages/IndivisualChannel';
import { useSelector } from 'react-redux';
import AdminPost from '../AdminPanel/RightSide/Pages/AdminPost';
import PostAttributes from '../AdminPanel/RightSide/Pages/PostAttributes/PostAttributes';
import CreateAttributes from '../AdminPanel/RightSide/Pages/PostAttributes/CreateAttributes';
import Analytics from '../AdminPanel/RightSide/Pages/Analytics';
import UserInfoEdit from '../AdminPanel/RightSide/Pages/UserInfoEdit';
import PostStatistics from '../AdminPanel/RightSide/Pages/PostStatistics';
import UserManagement from '../AdminPanel/RightSide/Pages/UserManagement';
import EditUserManagementUser from '../AdminPanel/RightSide/Pages/EditUserManagementUser';
import TopicTags from '../AdminPanel/RightSide/Pages/TopicTags';
const Navigation = () => {
  const { isAuthenticated } = useSelector(state => state.root);
  return (
    <div>
          <Routes>
            <Route element = {<PrivateRoute isAuthenticated = {isAuthenticated}/>}>
              <Route path="pannel" element={<AdminPanelScreen/>} />
              <Route path= "pendingrequest" element = {<Request/>}/>
              <Route path= "events" element = {<Events/>}/>
              <Route path= "events/addevent" element = {<CreateEvents/>}/>
              <Route path= "attributes/addeattribute" element = {<CreateAttributes/>}/>
              <Route path= "events/editevent" element = {<EditEvents/>}/>
              <Route path= "channels" element = {<Channels/>}/>
              <Route path= "channels/indivisualchannel" element = {<IndivisualChannel/>}/>
              <Route path= "home" element = {<AdminPost/>}/>
              <Route path= "attributes" element = {<PostAttributes/>}/>
              <Route path= "analytics" element = {<Analytics/>}/>
              <Route path= "pendingrequest/edituserdata" element = {<UserInfoEdit/>}/>
              <Route path= "user-management/edit" element = {<EditUserManagementUser/>}/>
              <Route path= "analytics/post-statistics" element = {<PostStatistics/>}/>
              <Route path= "user-management" element = {<UserManagement/>}/>
              <Route path= "topic-tags" element = {<TopicTags/>}/>
            </Route>
          </Routes>
    </div>
  )
}

export default Navigation
import React, { useState, useEffect } from 'react';
import './AllUserFeedData.css'
import InfiniteScroll from "react-infinite-scroll-component";
import image from '../../../../../constant/image';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import './AllUserFeedData.css'
import Swal from 'sweetalert2';
import { apiCall } from '../../../../../action/loginAction';
import { Button } from 'react-bootstrap';
import selectedTab from  "../../../../../GlobalFunctions/global"
import ReactPaginate from 'react-paginate';
const SearchUsers = (props) => {
  const dispatch = useDispatch();
  const { searchFeedData, feedPermissions } = useSelector(state => state.feedData);
  const [peoplsData, setPeoplsData] = useState([]);
  const { isCallData } = useSelector(state => state.channelData);
  const [isDeletePost, setIsDeletePost] = useState({ postID: '', indexOfPost: '' });
  const [searchedValue, setSearchedValue] = useState(props.searchedValue)
  const [feedData, setFeedData] = useState();
  const [mideaList, setMideaList] = useState([]);
  const [page, setPage] = useState(1);
  const [isSharePost, setIsSharePost] = useState(false);
  const [isShowAdminPost, setIsShowAdminPost] = useState('');
  const [permissions, setPermissions] = useState(props.permissions)
  const email = localStorage.getItem('email');
  const role = localStorage.getItem('role');
  const [searchPost, setSearchPost] = useState('');
  const [searchPostData, setSearchPostData] = useState([]);
  const [isMoreData, setIsMoreData] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [newFeedData , setNewFeedData] = useState();
  const [isDataLoading , setIsDataLoading] = useState(false);
  const [activeTab , setActiveTab] = useState(props.activeTab)
  useEffect(() => {
    setPeoplsData(searchFeedData.people)
  }, [searchFeedData])

  useEffect(() => {
    console.log("SearchUsers 24")
    getPeopleData(1)
  }, [])

  useEffect(() => {
    console.log("useEffect called in search user")
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );

    const windowBottom = windowHeight + window.pageYOffset;
      console.log("windowBottom" , windowBottom)
    if (windowBottom >= documentHeight - 100) {
      // You can adjust the value (100) based on your preference for when to trigger the function
      onEndOfPage();
    }
  };
  const getPeopleData = async (pg) => {
    var data = {
        get_people: true,
        search_data: searchedValue,
        per_page: 10,
        preferred_language: 'EN',
        page_number: pg
    }
    let res = await dispatch(apiCall(data, '/user/search-all', true));
    if (res.data.message.MSG_CODE === 100441) {
      console.log("51")
      console.log("searchedValue : " ,searchedValue )
        if (searchedValue.trim().length === 0) {
          setPeoplsData([])
        } else {
          let total_number_of_pages = res.data.pagination.total_page
          
          setTotalPage(total_number_of_pages);
          setPeoplsData(res.data.data)
          setIsDataLoading(false);
        }
    }
    else {
        Swal.fire({
            icon: 'error',
            text: 'Something went, please try again later',
            timer: 3000
        })
    }
}
const getNewPeopleData = async (pg) => {
  var data = {
      get_people: true,
      search_data: searchedValue,
      per_page: 20,
      preferred_language: 'EN',
      page_number: pg
  }
  let res = await dispatch(apiCall(data, '/user/search-all', true));
  if (res.data.message.MSG_CODE === 100441) {
    console.log("79")
      if (searchedValue.trim().length === 0) {
        setPeoplsData([])
      } else {
        let total_number_of_pages = res.data.pagination.total_page
        setTotalPage(total_number_of_pages);
        setPeoplsData([...peoplsData , ...res.data.data])
        setIsDataLoading(false);
      }
  }
  else {
      Swal.fire({
          icon: 'error',
          text: 'Something went, please try again later',
          timer: 3000
      })
  }
}
const onEndOfPage = () => {
  if (totalPage >= page && selectedTab.selectedTab === "user"){
    setIsDataLoading(true)
    setPage(page + 1)
    getNewPeopleData(page + 1)
  }


} 
const handlePageClick = (data) =>{
  setIsDataLoading(true)
  setPeoplsData([])
  let currentPage = data.selected + 1 ;
  console.log("data.selected",data.selected)
  getPeopleData(currentPage)
  setPage(currentPage)
}
  return (
    <>
      <div className='mt-3'>
      {/* <InfiniteScroll
              dataLength={peoplsData.length}
              style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
              hasMore={true}
              next={() => onEndOfPage()}
              scrollableTarget="scrollableDiv"
            > */}
        {
          peoplsData && peoplsData.length > 0 ?

            <ul className='userFeedChannelList p-0 '>
              {
                peoplsData && peoplsData.map((channel, index) => {
                  return (
                    <li key={index} className='p-1 d-flex justify-content-between my-2' >
                      <div className='d-flex userFeedChannelsInfo'>
                        {
                          channel.profile_picture !== null ?
                            <div className=''>
                              <img role='button' src={channel.profile_picture} alt='logo' className='userFeedChannelsListLogo' />
                            </div> :
                            <div className=''>
                              <img role='button' src={image.porfilePicture} alt='logo' className='userFeedChannelsListLogo' />
                            </div>
                        }

                        <div className='mx-3 py-2'>
                          <div>
                            <strong>
                              {channel.full_name}
                            </strong>
                            <span style={channel.channel_type === 'Private' ? { color: 'red' } : { color: 'green' }}>({channel.role})</span>
                          </div>
                          <span>
                            {channel.primary_speciality}
                          </span>
                        </div>
                      </div>
                      <div className='d-flex'>

                      </div>
                    </li>
                  )
                })
              }
             
            </ul> 
            :
            isDataLoading ? null :
            <h5 style={{ textAlign: 'center', marginTop: '2rem', color: 'steelblue' }}>
              No Data available
            </h5>

        }
        {/* </InfiniteScroll> */}
        <div className='mt-3 mb-3'>
        {
                          totalPage > 1 && (
                            <ReactPaginate
                              className='pagination m-0'
                              breakLabel= {'...'}
                              nextLabel="next >"
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={2}
                              marginPagesDisplayed={2}
                              pageCount={totalPage}
                              previousLabel="< prev"
                              renderOnZeroPageCount={null}
                              containerClassName="pagination"
                              activeClassName={'active'}
                              pageClassName = {'page-item'}
                              previousClassName = {'page-item'}
                              previousLinkClassName = {'page-link'}
                              pageLinkClassName = {'page-link'}
                              nextClassName = {'page-item'}
                              nextLinkClassName = { 'page-link'}
                              breakClassName = {'page-item'}
                              breakLinkClassName = {'page-link'}
                             />
                          )
                        }
                         </div>
      </div>
    </>
  )
}

export default SearchUsers
import React,{useState} from 'react';
import './login.css';
import {useDispatch} from 'react-redux';
import {useLocation,useNavigate } from "react-router-dom";
import image from '../../constant/image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { apiCall } from '../../action/loginAction';
import Swal from 'sweetalert2'
const ResetPassword = (props) => {
    const {state} = useLocation();
    const dispatch = useDispatch();
    var navigate = useNavigate();
    
    const [password, setPassword] = useState('');
    const [psdError, setPsdError ] = useState('null');
    const [showPsdString, setShowPsdString] = useState(false);

    const [confirmpassword, setconfirmPassword] = useState('');
    const [confirmPsdError, setConfirmPsdError ] = useState('null');
    const [showConfirmPsdString, setConfirmShowPsdString] = useState(false);


    const [email]=useState(state.userEmailId);
    const [verificationCodeValue]=useState(state.verificationCode);
    const [referenceCode] = useState(state.referenceCodeValue);
    
    // password set to use state
    const addPassword = (password) => {
        let reg = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^!&*+=]).*$/gm
        if(reg.test(password) === false){
            setPsdError('Password should be minimum 8 characters and include atleast 1 letter 1 number 1 uppercase and 1 special character!');
            setShowPsdString(true);
            setPassword(password);
        }else{
            setPsdError(() => (null));
            setShowPsdString(false);
            setPassword(password);           
        }
    }
    const confirmAddPassword = (confmpassword) => {
        setconfirmPassword(confmpassword);
    }
    //on reset password
    const onResetPassword =  async(e) =>{
        e.preventDefault();
        if ( password === '' && confirmpassword === '' ){
            setPsdError('Please enter a password');
            setShowPsdString(true);
            setConfirmPsdError('Please enter a confirmpassword');
            setConfirmShowPsdString(true);
        }else if(password !== confirmpassword ){
            setConfirmPsdError('Confirm password should be same as password');
            setConfirmShowPsdString(true);
            return
        }
        else{
            if( password === confirmpassword ){
                let data = {  
                    email:email,
                    is_verify_and_set_password: true,
                    new_password:confirmpassword,
                    code : Number(verificationCodeValue),
                    reference_id : referenceCode
                };
                let res = await dispatch(apiCall(data,'/user/forgot-password'));
                if (res.data.message.MSG_CODE === 100061){
                    Swal.fire({
                        icon: 'success',
                        text: 'Password changed successfully',
                    })
                    empty();
                    navigate("/");
                }else if (res.data.message.MSG_CODE === 100110){
                    Swal.fire({
                        icon: 'success',
                        text: 'Password changed unsuccessful',
                    })
                }
            }
        }

    }
    // empty state
    const empty = ()=>{
        setShowPsdString(false);
        setConfirmShowPsdString(false);
        setPassword('');
        setconfirmPassword('');
    }

  return (
    <div className='Login'> 
        <div className='loginImage d-flex align-items-center' > 
            <Container className='log-wrap 'style={{ height: '57vh' }}>
                <div className='loginCard sm-12 w-100'>
                    <Row className= 'd-flex align-items-center justify-content-center' sm={12}>
                        <Col sm={4} className = 'loginBox d-flex align-items-center justify-content-around'>
                            <form className="Auth-form w-75">
                                <div className="Auth-form-content">
                                    <h3 style ={{color:'#1d60de'}}>Reset Password</h3>
                                    <div className="form-group mt-3">
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        className="form-control mt-1 lh-lg"
                                        placeholder="Enter password"
                                        value={password}
                                        onChange={(password) => addPassword(password.target.value)}
                                    />
                                    {
                                        showPsdString === true?
                                        <label className='mt-2' style={{ color: "red" }}>{psdError}</label>:
                                        null
                                    }
                                    </div>
                                    <div className="form-group mt-1">
                                    <label>Confirm password</label>
                                    <input
                                        type="password"
                                        name="confirmpassword"
                                        id="confirmpassword"
                                        className="form-control mt-1 lh-lg"
                                        placeholder="Enter confirmpassword"
                                        value={confirmpassword}
                                        onChange={(confirmpassword) => confirmAddPassword(confirmpassword.target.value)}
                                    />
                                    {
                                        showConfirmPsdString === true?
                                        <label className='mt-2' style={{ color: "red" }}>{confirmPsdError}</label>:null
                                    }
                                    </div>
                                    
                                    <div className="d-flex align-items-center justify-content-between my-3">
                                        <button type="submit" className="btn btn-primary w-100 p-2" onClick={onResetPassword}>
                                            Confirm
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row sm={12} style ={{textAlign:'center'}}> 
                        <Col>
                        <img src={image.loginPgLeftImg} alt= 'logo' style={{ height:'28vh',width:'17vw'}}/>
                        </Col>
                        <Col>
                        <img src={image.loginPgRightImg} alt= 'logo' style={{height:'28vh',width:'17vw'}}/>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    </div>
  )
}

export default ResetPassword
import React from 'react'
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import UserActivityTable from './UserActivityTable';
import Container from 'react-bootstrap/Container';
import './UserActivity.css'
const UserActivity = () => {
  return (
    <>
        <div className='p-0 userActivity'>
          <Container className='log-wrap p-4 pb-0' fluid style={{height:'100%'}}>
            <React.Fragment>
                <div className='p-1' style={{height:'100%'}}>
                    <section style={{marginBottom:'2em'}} >
                        <div className='d-flex align-items-center justify-content-between'>
                           <h4>Your post</h4>
                           <span>Filter</span> 
                        </div>
                        <div className='my-2'>
                            <input type="search" className='form-control'  style={{background:'#EFF5FF'}}
                                placeholder="Search here" aria-label="Search" />
                        </div>
                    </section>
                    <section style={{height:'73%'}}>
                      <UserActivityTable/>
                    </section>
                </div>
            </React.Fragment>
          </Container>
        </div>
    </>
  )
}

export default UserActivity
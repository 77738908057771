import React, { useState, useEffect } from 'react';
import './AllUserFeedData.css'
import InfiniteScroll from "react-infinite-scroll-component";
import MediaGrid from '../MediaGrid';
import * as AiIcons from 'react-icons/ai';
import image from '../../../../../constant/image';
import Badge from 'react-bootstrap/Badge';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { apiCall } from '../../../../../action/loginAction';
import useDebounce from '../../../../../constant/useDebouncing';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import selectedTab from  "../../../../../GlobalFunctions/global"
const AllUserFeedData = (props) => {
    const dispatch = useDispatch();
    const { isCallData } = useSelector(state => state.channelData);
    const { searchFeedData, feedPermissions } = useSelector(state => state.feedData);
    const [feedData, setFeedData] = useState(searchFeedData.feed);
    const [searchedValue, setSearchedValue] = useState(props.searchedValue)
    const [mideaList, setMideaList] = useState([]);
    const [channelsData, setChannelsData] = useState(searchFeedData.groups);
    const [isDeletePost, setIsDeletePost] = useState({ postID: '', indexOfPost: '' });
    const [peoplsData, setPeoplsData] = useState(searchFeedData.people);
    const [isSharePost, setIsSharePost] = useState(false);
    const [page, setPage] = useState(1);
    const [isShowAdminPost, setIsShowAdminPost] = useState('');
    const [permissions, setPermissions] = useState(props.permissions)
    let permissionsLocal = JSON.parse(localStorage.getItem('role_permission')).menu_permission
    const [toShow,setToShow] = useState(false)
    useEffect(()=>{
      let checkPermission = permissionsLocal.includes("Users")
      setToShow(checkPermission)
    },[])
          useEffect(() => {
        if (isDeletePost.postID !== '') {
            deletePostinAdminPost(isDeletePost)
        }
    }, [isDeletePost])
    useEffect(()=>{
        setFeedData(searchFeedData.feed)
        setChannelsData(searchFeedData.groups)
        setPeoplsData(searchFeedData.people)
    },[searchFeedData])
    useEffect(()=>{
        setFeedData(feedData)

    },[feedData])
    const getSearchAdminData = async (pg, searchValue) => {
        var data = {
            get_all: true,
            search_data: searchedValue,
            per_page: 7,
            preferred_language: 'EN',
            page_number: pg
        }
        let res = await dispatch(apiCall(data, '/user/search-all', true));
        if (isCallData === true) {
            dispatch({ type: "clearCallChannelData" });
        }
        if (res.data.message.MSG_CODE === 100891) {
            if (searchedValue.trim().length === 0) {
                dispatch({ type: "clearFeedData" });
            } else {
                dispatch({ type: "getFeedData", ...res.data });
                dispatch({ type: "getChannelFeedData", ...res.data });
            }
        }
        else {
            Swal.fire({
                icon: 'error',
                text: 'Something went, please try again later',
                timer: 3000
            })
        }
    }
          // delete post 
          const deletePostinAdminPost = async (deletePost) => {
            var data = {
                post_id: deletePost.postID,
                is_delete: true,
            };
            let res = await dispatch(apiCall(data, '/user/post'));
            if (res.data.message.MSG_CODE === 100321) {
                setPage(1);
                setMideaList([])
                setIsDeletePost({ postID: '', indexOfPost: '' })
                getSearchAdminData(1)
            } else if (res.data.message.MSG_CODE === 100390 || res.data.message.MSG_CODE === 100170) {
                Swal.fire({
                    icon: 'error',
                    text: 'Post Update Failure',
                    timer: 3000
                })
            }
        }
        const onClickViewMore = (key) => {
            dispatch({ type: "selectedTabkey",key});
        }
    return (
        <>
            {
                toShow && peoplsData && <div className='mt-3'>
                    <h4 className='mt-1 feedDataHeading'>
                        Peoples
                    </h4>
                    {
                        peoplsData.length > 0 ?
                        <div>
                            <ul className='userFeedChannelList p-0'>
                                {
                                    peoplsData && peoplsData.map((channel, index) => {
                                        return (
                                            <li key={index} className='p-1 d-flex justify-content-between my-2'>
                                                <div className='d-flex userFeedChannelsInfo'>
                                                    {
                                                        channel.profile_picture !== null ?
                                                            <div className=''>
                                                                <img role='button' src={channel.profile_picture} alt='logo' className='userFeedChannelsListLogo' />
                                                            </div> :
                                                            <div className=''>
                                                                <img role='button' src={image.porfilePicture} alt='logo' className='userFeedChannelsListLogo' />
                                                            </div>
                                                    }

                                                    <div className='mx-3 py-2'>
                                                        <div>
                                                            <strong>
                                                                {channel.full_name}
                                                            </strong>
                                                            <span style={channel.channel_type === 'Private' ? { color: 'red' } : { color: 'green' }}>({channel.role})</span>
                                                        </div>
                                                        <span>
                                                            {channel.primary_speciality}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='d-flex'>

                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul> 
                            <div className='d-flex justify-content-center mt-3 mb-3'>
                            <Button variant="primary" style={{ height: '2.3rem' }} onClick={() => onClickViewMore('user')}>View More</Button>
                            </div>
                            </div>
                            :
                            <h5 style={{ textAlign: 'center', marginTop: '2rem', color: 'steelblue' }}>
                                No Data available
                            </h5>
                    }
                </div>
            }

            <hr className='mt-1 feedDataHeading'></hr>
            <div className='mt-3'>
                <h4 className='mt-1 feedDataHeading'>
                    Channels
                </h4>
                {
                    channelsData && channelsData.length > 0 ?
                    <div>
                        <ul className='userFeedChannelList p-0'>
                            {
                                channelsData && channelsData.map((channel, index) => {
                                    return (
                                        <li key={index} className='p-1 d-flex justify-content-between my-2'>
                                            <div className='d-flex userFeedChannelsInfo'>
                                                {
                                                    channel.thumbnail_image !== null ?
                                                        <div className=''>
                                                            <img role='button' src={channel.thumbnail_image} alt='logo' className='userFeedChannelsListLogo' />
                                                        </div> :
                                                        <div className=''>
                                                            <img role='button' src={image.porfilePicture} alt='logo' className='userFeedChannelsListLogo' />
                                                        </div>
                                                }

                                                <div className='mx-3 py-2'>
                                                    <div>
                                                        <strong>
                                                            {channel.channel_name}
                                                        </strong>
                                                        <span style={channel.channel_type === 'Private' ? { color: 'red' } : { color: 'green' }}>({channel.channel_type})</span>
                                                    </div>
                                                    <span>
                                                        {channel.users_count}  subscribers
                                                    </span>
                                                    {
                                                        channel.sponsor.length > 0 ?
                                                            <span className='mx-3'>
                                                                sponsor :{
                                                                    channel.sponsor.map((spon, index) => {
                                                                        return (
                                                                            <Badge pill bg="primary" className='m-2' key={index}>
                                                                                {spon}
                                                                            </Badge>                                                                                            // <strong key={index}>{spon}</strong>
                                                                        )
                                                                    })
                                                                }
                                                            </span> : null
                                                    }
                                                </div>
                                            </div>
                                            <div className='d-flex'>
                                                {/* <div style={{ color: 'green', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='px-4'>
                                                    <AiIcons.AiFillEdit />
                                                </div>
                                                <div style={{ color: 'red', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='px-4'>
                                                    <AiIcons.AiFillDelete />
                                                </div> */}
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul> 
                                                <div className='d-flex justify-content-center mt-3 mb-3'>
                                                <Button variant="primary" style={{ height: '2.3rem' }} onClick={() => onClickViewMore('channels')}>View More</Button>
                                                </div>
                                                </div>
                        :
                        <h5 style={{ textAlign: 'center', marginTop: '2rem', color: 'steelblue' }}>
                            No Data available
                        </h5>
                }
            </div>
            <hr className='mt-1 feedDataHeading'></hr>
            <div>
                <h4 className='mt-3 feedDataHeading'>
                    Post 
                </h4>
               
                {
                    feedData && feedData.length > 0 ?
                    <div>
                        <InfiniteScroll
                            dataLength={feedData.length}
                            style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                            // hasMore={isMoreData}
                            scrollableTarget="scrollableDiv"
                        >
                            <div className='adminFeedData'>
                                {
                                    feedData.map((feed, index3) => {
                                        return (
                                            <div key={index3}>
                                                <MediaGrid feedData={feed} indexOfFeed={index3} deletePost={setIsDeletePost} sharePost={setIsSharePost} showAdminPost={setIsShowAdminPost} 
                                                    permissions={permissions} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </InfiniteScroll> 
                                                            <div className='d-flex justify-content-center mt-3 mb-3'>
                                                            <Button variant="primary" style={{ height: '2.3rem' }} onClick={() => onClickViewMore('post')}>View More</Button>
                                                            </div>
                                                            </div>:
                        <h5 style={{ textAlign: 'center', marginTop: '2rem', color: 'steelblue' }}>
                            No Data available
                        </h5>
                }
            </div>
        </>
    )
}

export default AllUserFeedData

import Api from '../utils/Api';
import Swal from 'sweetalert2';
const CallRefreshToken = async() => {
  const refreshToken = localStorage.getItem('refreshToken');
  const res = await Api.POST('/user/refresh-token','',{Authorization: `Bearer ${refreshToken}`});
  if (res.data.message.MSG_CODE === 100710){
    setTimeout(() => {
      localStorage.clear();
      window.location.reload();
    },3000)
    Swal.fire({
      icon: 'error',
      text: 'Please login again',
      timer: 3000
    })
  }else{
    localStorage.setItem('accessToken', res.data.data);
    return true
  }

}
export function chunkUpload(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: "showLoader"});
      const accessToken = localStorage.getItem('accessToken');
      const res = await Api.POSTFORMDATA('/user/chunk-upload', data,{Authorization: `Bearer ${accessToken}`});
      dispatch({ type: "hideLoader"});
      if (res.success === 0) {
        const status = res.data.Status_msg
        if(status === "Error")
        {
          Swal.fire({
            icon: 'error',
            text: res.data.Status_msg,
          })
        }else if (res.data.message.MSG_CODE === 100710){
          dispatch({ type: "showLoader"});
          const newAcceessToken = await CallRefreshToken();
          if (newAcceessToken === true){
            const accessToken = localStorage.getItem('accessToken');
            const res = await Api.POSTFORMDATA('/user/chunk-upload', data,{Authorization: `Bearer ${accessToken}`});
             dispatch({ type: "hideLoader"});
            return res
          }
        }
        else
        {
          return res;
        }

      }else if (res.success === 1){
        Swal.fire({
          icon: 'error',
          text: res.message,
        })
      }
    } catch (error) {
      dispatch({ type: "hideLoader"});
      console.log('error: ', error);
    }
  };
}
export function approveUserBlobData(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: "showLoader"});
      const accessToken = localStorage.getItem('accessToken');
      const res = await Api.POST('/user/approve', data,{Authorization: `Bearer ${accessToken}`});
      dispatch({ type: "hideLoader"});
      if (res.success === 0) {
        return res;
      }else if (res.success === 1){
        Swal.fire({
          icon: 'error',
          text: res.message,
        })
      }
    } catch (error) {
      dispatch({ type: "hideLoader"});
      console.log('error: ', error);
    }
  };
}
export function apiCall(data , endpoint,hideLoader) {
  return async (dispatch, getState) => {
    try {
      if (hideLoader === undefined) {
      }
      dispatch({ type: "showLoader"});
      const accessToken = localStorage.getItem('accessToken');
      const res = await Api.POST(endpoint, data,{Authorization: `Bearer ${accessToken}`});
      dispatch({ type: "hideLoader"});
      if (res.success === 0) {
        const status = res.data.Status_msg
        if(status === "Error")
        {
          Swal.fire({
            icon: 'error',
            text: res.data.Status_msg,
          })
        }else if (res.data.message.MSG_CODE === 100710){
          dispatch({ type: "showLoader"});
          const newAcceessToken = await CallRefreshToken();
          if (newAcceessToken === true){
            const accessToken = localStorage.getItem('accessToken');
            const res = await Api.POST(endpoint, data,{Authorization: `Bearer ${accessToken}`});
             dispatch({ type: "hideLoader"});
            return res
          }
        }
        else
        {
          return res;
        }

      }else if (res.success === 1){
        Swal.fire({
          icon: 'error',
          text: res.message,
        })
      }
    } catch (error) {
      dispatch({ type: "hideLoader"});
      console.log('error: ', error);
    }
  };
}

export function apiCall1(data , endpoint,hideLoader) {
  return async (dispatch, getState) => {
   
      dispatch({ type: "showLoader"});
      const accessToken = localStorage.getItem('accessToken');
      const res = await Api.POST(endpoint, data,{Authorization: `Bearer ${accessToken}`});
      dispatch({ type: "hideLoader"});

          return res;
        
  }
     
}
import { createReducer } from "@reduxjs/toolkit";
export const channelsFeedData = createReducer({isCallData:false, channelFeedData:[]},{
    getChannelFeedData: (state,action) => {
        state.channelFeedData = action;
    },
    clearChannelFeedData: (state,action) => {
        state.channelFeedData = [];
    },
    callChannelData: (state,action) => {
        state.isCallData = true;
    },
    clearCallChannelData:(state,action) => {
        state.isCallData = false;
    },
})
import React from 'react'
import Container from 'react-bootstrap/Container';
import './MediaInteraction.css'
import image from '../../../constant/image';
const MediaLikes = () => {
  return (
    <>
      <div className='p-0' style ={{height:'100%'}}>
        <Container className='log-wrap mediaBox d-flex align-items-center' fluid style ={{height:'100%'}}>
          <div className='mediaBoxContent'>
            <div className='d-flex align-items-center justify-content-around '>
              <img role = 'button' src={image.adminPannelLikes} alt= 'logo' className='mediaIconImg'/>
              <span className='my-1'>Likes</span>
            </div>
            <div className='d-flex' style={{marginLeft:'1em'}}>
              <strong>8,000</strong>
            </div>
          </div>
        </Container>
      </div>
  </>
  )
}

export default MediaLikes
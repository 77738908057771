import React, { useEffect, useState, useRef } from 'react';
import LeftSideBar from '../../../SideBar/LeftSideBar';
import { Row, Col, Container, ProgressBar, Form } from 'react-bootstrap'
import './PostAttributes.css';
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import CloseButton from 'react-bootstrap/CloseButton';
import { useDispatch } from 'react-redux';
import { apiCall } from '../../../../../action/loginAction';
import Button from 'react-bootstrap/Button';
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import { FaLink } from "react-icons/fa";
import ReactPaginate from 'react-paginate';
import Switch from "react-switch";
import Swal from 'sweetalert2';
import Parser from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';
import { actionpermission } from '../../../../../constant/commonFunctions'
import Unauthorized from '../Unauthorized';
import { getShareLink } from '../../../../../constant/getShareLink';
const PostAttributes = (props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [isDeleteEvent, setIsDeleteEvent] = useState(false);
  const [toggleLink, setToggleLink] = useState(false);
  const [postAttribute, setPostAttribute] = useState([]);
  const [pageData, setPageData] = useState({});
  const [attributeDetails, setAttributeDetails] = useState({});
  const [editModalShow, setEditModalShow] = React.useState(false);
  const [bannerImage, setBannerImage] = useState({ fileName: null, media_id: null });
  const email = localStorage.getItem('email');
  const [permissions, setPermissions] = useState([])
  const initialRender = useRef(true);
  const [userType,setUserType] = useState("Admin");
  const [toggleAttribute, setToggleAttribute] = useState(false);
  const [toggleAttributeButton , setToggleAttributeButton] = useState(false);
  const [toggleAttributeData , setToggleAttributeData] = useState([]);

  const itemsPerPage = 8;
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = postAttribute.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(postAttribute.length / itemsPerPage)

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % postAttribute.length;
    setItemOffset(newOffset);
  };
  // get data
  useEffect(() => {
    getAttributesData(1);
    let permissionsArray = actionpermission("Post Attributes")
    setPermissions(permissionsArray)
    // getToggle()
  }, [])
  useEffect(() => {
    console.log(initialRender.current , "curr")
    if (initialRender.current) {
      initialRender.current = false;
  } else {
    console.log("ran")
    updateToggle()
  }

  }, [toggleLink])
  // get data
  const getAttributesData = async (pg) => {
    var data = {
      is_get: true,
      content_language: 'EN',
      per_page: 100,
      page_number: pg
    };
    let res = await dispatch(apiCall(data, '/user/admin-category'));
    if (res.data.message.MSG_CODE === 100661) {
      setPostAttribute(res.data.data);
    }else if (res.data.message.MSG_CODE === 100130){
      setUserType("Enterprise")
    }   
    else {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again later',
        timer: 3000
      })
    }
  }
  const deleteEventSwal = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this Event",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed === true) {
        // deleteEvent(id);
        setIsDeleteEvent(false);
      }
    })
  }
  // add line brk 
  const NewlineText = (props) => {
    const text = props.text;
    const newText = text.split('\n').map(str => <p className='my-1'>{str}</p>);
    return newText;
  }
  //pagination
  // const handlePageClick = (data) =>{
  //   let currentPage = data.selected + 1 ;
  //   setPage(currentPage)
  // }
  // edit model 
  const openEditModel = (profile) => {
    setAttributeDetails(profile)
    setTimeout(() => {
      setEditModalShow(true);
    }, 500)
  }
  const closeEditModel = () => {
    setEditModalShow(false);
  }
  // change height 
  const changeHeightTextArea = (id) => {
    setTimeout(() => {
      var textarea = document.querySelector(id);
      textarea.addEventListener("keydown", e => {
        let scroll_height = e.target.scrollHeight;
        textarea.style.height = `${scroll_height}px`;
      });
    }, 10);
  }
  // upload api
  const uploadFile = async (e) => {
    setBannerImage({ fileName: e.name })
    const convertString = await convertBase64(e);
    const base64Array = convertString.split(",");
    const base64 = base64Array[1];
    var data = {
      email: email,
      file_path: "category",
      file_data: base64,
      file_name: e.name,
    };
    let res = await dispatch(apiCall(data, '/user/upload'));
    if (res.data.message.MSG_CODE === 100231) {
      setBannerImage({ media_id: res.data.data.media_id });
    } else if (res.data.message.MSG_CODE === 100580) {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please upload again',
        timer: 3000
      })
    } else if (res.data.message.MSG_CODE === 100270) {
      Swal.fire({
        icon: 'error',
        text: 'Not valid extension please upload again (accept only JPEG, PNG, JPG)',
        timer: 3000
      })
    } else if (res.data.message.MSG_CODE === 100010) {
      Swal.fire({
        icon: 'warning',
        text: 'image uploading failure',
        timer: 5000
      })
    }
  }
  // base64 conversion
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = (() => {
        resolve(fileReader.result);
      });
      fileReader.onerror = ((error) => {
        reject(error)
      });
    });
  };
  const toggleLinkFunc = async()=>{
    setToggleLink(!toggleLink)
    // console.log(toggleLink , "toggleLink")
    var data = {
      change_link_status: true,
    };
    let res = await dispatch(apiCall(data, '/user/admin-category'));
    // console.log(res.data.message.MSG_CODE)
    if (res.data.message.MSG_CODE === 100971) {
      Swal.fire({
        icon: 'success',
        text: 'Link status changed successfully',
        timer: 3000
      })    
    }
    else {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again later',
        timer: 3000
      })
    }
  }
  const updateToggle = async () =>{

  }
  const getToggle = async (category_id) =>{
    console.log(toggleLink , "toggleLink")
    var data = {
      change_link_status: true,
      category_id : category_id
    };
    let res = await dispatch(apiCall(data, '/user/admin-category'));
    if (res.data.message.MSG_CODE === 100971) {
      setToggleLink(res.data.data.link_status)
    }else if (res.data.message.MSG_CODE === 100130){
      setUserType("Enterprise")
    }
    else {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again later',
        timer: 3000
      })
    }
  }
  const empty = () => {
    setBannerImage({ fileName: null, media_id: null });
    closeEditModel();
    setAttributeDetails({})
  }
  const updateAttribute = async (id) => {
    if (attributeDetails.category_description !== '' && attributeDetails.category_title !== '') {
      var data = {
        is_edit: true,
        category_description: attributeDetails.category_description,
        category_title: attributeDetails.category_title,
        category_image: bannerImage.media_id === null ? attributeDetails.media_data._id : bannerImage.media_id,
        content_language: 'EN',
        category_id: id,
        category_type : attributeDetails.type,
        url :  attributeDetails.url 
      };
      let res = await dispatch(apiCall(data, '/user/admin-category'));
      if (res.data.message.MSG_CODE === 100651) {
        empty();
        getAttributesData(1);
      } else if (res.data.message.MSG_CODE === 100850) {
        Swal.fire({
          icon: 'error',
          text: 'Attribute title should be unique',
          timer: 3000
        })
      }
      else {
        Swal.fire({
          icon: 'error',
          text: 'Something went wrong please try again later',
          timer: 3000
        })
      }
    } else {
      if (attributeDetails.category_title === null || attributeDetails.category_title === '') {
        Swal.fire({
          icon: 'error',
          text: 'Please fill attributes title',
          timer: 4000
        })
      } else if (attributeDetails.category_description === null || attributeDetails.category_description === '') {
        Swal.fire({
          icon: 'error',
          text: 'Please fill description',
          timer: 4000
        })
      }
    }
  }
  const createToggleAttributeData = async () => {
    postAttribute.map((item, index) => 
        {if(item.type  == "LINK" ) {
          toggleAttributeData.push(item);
        }}
    )
  }
  const onPressToggleAttributes = async () => {
    createToggleAttributeData()

    setToggleAttribute(prev => !prev)
    console.log("postAttribute ",postAttribute)
  }
  const closeToggleModel = () => {
    setToggleAttributeData([])
    setToggleAttribute(false)
  }
  const onToggleAttribute = (index) => {
    console.log("toggleAttribute" ,toggleAttributeButton , index)

    toggleAttributeData.map((item, i) => {
      if(i == index){
        item["is_enabled"] = !item["is_enabled"]
        getToggle(item.category_id)
      }
    } )
    setToggleAttributeButton(prev => !prev)
  }

  const createLink = async (data) => {
      if(data.type == "LINK"){
        let dynamicUrl = data.url
        dynamicUrl = dynamicUrl.replace('https://','')
        let resultLink = await getShareLink('webView',dynamicUrl + "$" + data.category_title)
        navigator.clipboard
        .writeText(resultLink)
        .then(() => {
            alert("successfully copied");
        })
        .catch(() => {
            alert("something went wrong");
        });
      }else{
        console.log("data.category_id", data.category_id)
        let resultLink = await getShareLink('Attributes',data.category_id+ "$" + data.category_title + "$"+ data.category_description)
        navigator.clipboard
        .writeText(resultLink)
        .then(() => {
            alert("successfully copied");
        })
        .catch(() => {
            alert("something went wrong");
        });
      }
  }

  return (
    <>
      <section>
        <Row style={{ height: '100vh' }}>
          <Col className='' xxl={2} xl={2} lg={3} md={3} style={{ background: 'linear-gradient(90deg, #277AFC 0%, #27B3FC 100%)', height: '100%' }} >
            <LeftSideBar />
          </Col>
          {
            userType === "Admin" ? (<Col className='bg-prime p-0 adminPannel' xxl={10} xl={10} lg={9} md={9}>
            <Container className='log-wrap p-0' fluid style={{ height: '100vh' }}>
              <React.Fragment>
                <>
                  <section className='px-3 pt-3'>
                    <div>
                      <h5>Attributes</h5>
                      <hr></hr>
                    </div>
                    <div>
                      <Row className="mx-2">
                        <div class="switch-btn-parent"  style={{ display:'flex',justifyContent:'flex-end',alignItems:'center' }}>
                          
                      {/* <div class="switch-btn" style={{ display:'flex'}}>
                      <h6 style={{ marginRight: '1rem' }}>App Drawer Links</h6>
                                                                <Switch onChange={toggleLinkFunc} checked={toggleLink} onColor="#86d3ff"
                                                                    onHandleColor="#2693e6"
                                                                    handleDiameter={20}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                    activeBoxShadow="0px 0px 1px 4px rgba(0, 0, 0, 0.2)"
                                                                    height={15}
                                                                    width={36}
                                                                    className="react-switch"
                                                                    id="material-switch" />                        
                        </div> */}
                        {permissions.includes("Create Attributes") &&
                          <div className='text-end'>
                            <Button variant="primary" className='mx-3' 
                            style={{ height: '2.3rem' }} 
                            onClick={() => onPressToggleAttributes()}
                            >Toggle Links</Button>
                          </div>}
                        {permissions.includes("Create Attributes") &&
                          <div className='text-end'>
                            <NavLink to='addeattribute'><Button variant="primary" className='mx-3' style={{ height: '2.3rem' }}>Create Attributes / Links</Button></NavLink>
                          </div>}
                          </div>
                        <Row className='attributesDataDiv my-3'>
                          {
                            currentItems.map((post, index) => {
                              return (
                                
                                <Col xxl={3} xl={3} lg={4} md={4} className='my-2' key={index}>
                                  <div className='indivisualAttrbute'>
                                    {
                                      isDeleteEvent === true ?
                                        <span className='mx-2' style={{ color: '#b8b7b7', float: 'right' }} onClick={() => { deleteEventSwal(post.event_id) }}><BsIcons.BsXCircleFill /></span> : null
                                    }
                                    <div>
                                    <div className='d-flex justify-content-end'>
                                    {
                                      post.type == "LINK" ? 
                                      <div onClick={() => { createLink(post) }} style={{ color: '#277AFC', cursor: 'pointer' }} className='px-2 py-1'>
                                      <FaLink />
                                        </div> : null
                                    }
                                    
                                  
                                      <div onClick={() => { openEditModel(post) }} style={{ color: 'green', cursor: 'pointer' }} className='px-2 py-1'>
                                        <AiIcons.AiFillEdit />
                                      </div>
                                      </div>
                                      <div className='indivisualAttributeImage'>
                                        <img
                                          style={{ height: 100, width: 100 }}
                                          src={post.category_image} alt='banner image'
                                        />
                                      </div>
                                      <div className='indivisualAttributesPageinfo'>
                                        <div>
                                          <strong className='m-0'>{post.title}</strong>
                                          <div className='indivisualAttributeTitle'>
                                            <h6> {post.category_title}</h6>
                                          </div>
                                          <div className='indivisualEventDescr'>
                                            {Parser(post.category_description)}
                                            {/* <NewlineText text={post.category_description} /> */}
                                            <p className='mx-1'> </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Col> 
                              )
                            })
                          }
                          {
                            pageCount > 1 && (
                              <div>
                                <ReactPaginate
                                  className='pagination'
                                  breakLabel={'...'}
                                  nextLabel="next >"
                                  onPageChange={handlePageClick}
                                  pageRangeDisplayed={2}
                                  marginPagesDisplayed={2}
                                  pageCount={pageCount}
                                  previousLabel="< prev"
                                  renderOnZeroPageCount={null}
                                  containerClassName="pagination"
                                  activeClassName={'active'}
                                  pageClassName={'page-item'}
                                  previousClassName={'page-item'}
                                  previousLinkClassName={'page-link'}
                                  pageLinkClassName={'page-link'}
                                  nextClassName={'page-item'}
                                  nextLinkClassName={'page-link'}
                                  breakClassName={'page-item'}
                                  breakLinkClassName={'page-link'}
                                />
                              </div>
                            )
                          }
                        </Row>
                      </Row>
                    </div>
                  </section>
                </>
              </React.Fragment>
            </Container>
          </Col>) : 
          (
              <Col className='bg-prime p-0 channelScreen' xxl={10} xl={10} lg={9} md={9} style={{ height: '100%' }}>
                  <Unauthorized/>
              </Col>
          )
          }
          
        </Row>
      </section>
      <Modal
        {...attributeDetails}
        show={editModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='justify-content-between'
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
            Attribute / Link Detail
          </Modal.Title>
          <CloseButton onClick={closeEditModel} />
        </Modal.Header>
        <Modal.Body>
          <section>
            <Row>
              <Col xxl={12} xl={12} lg={12} md={12}>
                <div className=''>
                  <div className='my-1'>
                  <div className='d-flex justify-content-between mb-2'>
                    <p>
                      Title
                    </p>
                      <Button style = {{backgroundColor : '#277AFC'}}onClick={() => { createLink(attributeDetails) }}>Share link</Button>
                    </div>
                    <input type="text" className="form-control"
                      placeholder="channel name" aria-label="Title"
                      aria-describedby="basic-addon1"
                      value={attributeDetails.category_title}
                      onChange={(name) => setAttributeDetails({ ...attributeDetails, category_title: name.target.value })}
                    />
                  </div>
                  <div className='my-2'>
                    <p>
                      Description
                    </p>
                    <textarea
                      name="searchValue" className='postInputBox'
                      id='postTextInputBox'
                      rows="2" cols="200"
                      placeholder="What's happening"
                      value={attributeDetails.category_description}
                      onChange={(e) => setAttributeDetails({ ...attributeDetails, category_description: e.target.value })}
                      onKeyDown={() => { changeHeightTextArea('#postTextInputBox') }}
                    >
                    </textarea>
                    {
                      attributeDetails.type === "LINK" ? 
                      <div>
                      <p>
                      Link
                      </p>
                      <input type="text" className="form-control mt-0"
                      placeholder="link" aria-label="link"
                      aria-describedby="basic-addon1"
                      value={attributeDetails.url}
                      onChange={(url) => setAttributeDetails({ ...attributeDetails, url: url.target.value })}
                    />
                    </div>
                    : 
                    null
                    }
                    
                  </div >
                  <div className='my-2'>
                    <p>Banner image (jpeg and png )</p>
                    <input type="file" accept="image/png, image/jpeg" onChange={(event) => uploadFile(event.target.files[0])} />
                  </div>
                </div>
              </Col>
            </Row>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeEditModel} style={{ background: 'white', color: 'blue' }}>Close</Button>
          <Button onClick={() => { updateAttribute(attributeDetails.category_id) }}>Update</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={toggleAttribute}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='justify-content-between'
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
            Toggle Links
          </Modal.Title>
          <CloseButton onClick={() => closeToggleModel()} />
        </Modal.Header>
        <Modal.Body>
          <section>
            <Row>
              <Col xxl={12} xl={12} lg={12} md={12}>
                <div className='postAttirbuteScreenBox'>
                  
                    <ul className='d-grid gap-3 '>
                    {
                        toggleAttributeData.map((item, index) => 
                         
                          <li className='d-flex justify-content-between p-2 bg-light border rounded-2'>
                          {item.category_title}
                          <Switch onChange={() => onToggleAttribute(index)} checked={item["is_enabled"]} onColor="#86d3ff"
                                                                    onHandleColor="#2693e6"
                                                                    handleDiameter={18}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                    activeBoxShadow="0px 0px 1px 4px rgba(0, 0, 0, 0.2)"
                                                                    height={12}
                                                                    width={30}
                                                                    className="react-switch"
                                                                    id="material-switch" />   
                        </li>
                        
                        
                      )
                    }
                  </ul>
                  
                </div>
              </Col>
            </Row>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => closeToggleModel()}>OK</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PostAttributes
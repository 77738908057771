import image from '../../../constant/image'
export const LeftSideBarData = [
   
   {
      title :'Home',
      path : '/admin/home',
      icon : image.homeImage
   },
   {
      title :'Detailed Analytics',
      path : '/admin/home',
      icon : image.homeImage
   },
   {
      title :'Channels',
      path : '/admin/channels',
      icon : image.channelImage
   },
   {
      title :'Events',
      path : '/admin/events',
      icon : image.eventImage
   },
   {
      title :'Users',
      path : '/admin/pendingrequest',
      icon : image.requestImage
   },
   {
       title :'Post Attributes',
       path : '/admin/attributes',
       icon : image.attributesImage
   },
   {
      title :'Analytics',
      path : '/admin/analytics',
      icon : image.requestImage
  },
  {
   title :'Summary Statistics',
   path : '/admin/analytics',
   icon : image.requestImage
},
  {
   title :'User Management',
   path : '/admin/user-management',
   icon : image.analyticsImage
},
{
   title :'Topic Tags',
   path : '/admin/topic-tags',
   icon : image.analyticsImage
},
]
import React from 'react'
import { Navigate,Outlet,useLocation } from 'react-router-dom';

const PrivateRoute = ({isAuthenticated}) => {
    const location = useLocation()
    if (isAuthenticated === false){
       isAuthenticated = localStorage.getItem('isAuthenticated')
    }
  return isAuthenticated ? <Outlet/> : <Navigate to ='/' state = {{ from: location}} replace/>
}

export default PrivateRoute
import React, { useState } from 'react';
import './login.css';
import { useDispatch } from 'react-redux';
import { apiCall } from '../../action/loginAction';
import { useNavigate } from "react-router-dom";
import image from '../../constant/image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Swal from 'sweetalert2'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
// import './Login'
const Login = () => {
  var navigate = useNavigate();
  const dispatch = useDispatch();
  // localStorage.clear();
  //email state and key
  const [email, setEmail] = useState("");
  const [phoneEmailError, setPhoneEmailError] = useState('null');
  const [showPhoneEmailString, setShowPhoneEmailString] = useState(false);

  //password state and key
  const [password, setPassword] = useState("");
  const [psdError, setPsdError] = useState('null');
  const [showPsdString, setShowPsdString] = useState(false);
  //login with otp
  const [isLoginWithOtp, setIsLoginWithOtp] = useState(false);
  const [isOtpRecieved, setisOtpRecieved] = useState(false);
  const [verificationCodeValue, setVerificationCodeValue] = useState('');
  const [referenceCode, setReferenceCode] = useState('');
  const [showNewPassInput, setShowNewPassInput] = useState(false);

  //add email value to email state
  const addEmail = (email) => {
    setPhoneEmailError(() => (null));
    setShowPhoneEmailString(false)
    setEmail(email)
  }
  //add password value to password state
  const addPassword = (password) => {
    setPsdError(() => (null));
    setShowPsdString(false)
    setPassword(password)
  }
  //show and hide password field
  const loginWithotp = () => {
    setIsLoginWithOtp(true);
    setEmail('');
    setPassword("");
    setShowPhoneEmailString(false);
    setShowPsdString(false);
  }
  const loginWithPass = () => {
    setIsLoginWithOtp(false);
    setEmail("");
    setPassword("");
    setisOtpRecieved(false);
    setShowPhoneEmailString(false);
    setShowPsdString(false);
    setVerificationCodeValue("")
  }
  //Login btn press
  const onLogin = async (e) => {
    e.preventDefault();
    if (email === '' && password === '') {
      setPhoneEmailError('Please enter email address');
      setShowPhoneEmailString(true);
      setPsdError('please Enter password');
      setShowPsdString(true);
    }
    else if (password === '' && isLoginWithOtp === false) {
      setPsdError('please pnter password');
      setShowPsdString(true);
    }
    else if (email !== '') {
      let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (reg.test(email) === false) {
        Swal.fire({
          icon: 'error',
          text: 'enter proper email!',
        })
        return
      }
      if (isLoginWithOtp !== true) {
        var data = {
          email: email,
          password: password,
        };
        let res = await dispatch(apiCall(data,'/user/web-login'));
        if (res.data.message.MSG_CODE === 100071 && res.data.data.admin_panel_access.includes(res.data.data.role)) {
          emptyState();
          dispatch({ type: "logout" });
          localStorage.clear();
          dispatch({ type: "login", ...res.data.data });
          localStorage.setItem('accessToken', res.data.data.access_token);
          localStorage.setItem('refreshToken', res.data.data.refresh_token)
          localStorage.setItem('isAuthenticated', true);
          localStorage.setItem('email', email);
          localStorage.setItem('fullName', res.data.data.full_name);
          localStorage.setItem('profile_picture', res.data.data.profile_picture);
          localStorage.setItem('role', res.data.data.role);
          localStorage.setItem('company_name', res.data.data.company_name);
          localStorage.setItem('company_logo', res.data.data.company_logo);
          localStorage.setItem('role_permission' , JSON.stringify(res.data.data.role_permission));
          localStorage.setItem('profile_picture', res.data.data.profile_picture);
          localStorage.setItem('user_id', res.data.data.user_id);
          navigate("/admin/home")
        } else if (res.data.message.MSG_CODE === 100071) {
          dispatch({ type: "logout" })
          Swal.fire({
            icon: 'error',
            text: 'Your are not authorize to access this data',
            timer: 4000
          })
          emptyState();
        }else if (res.data.message.MSG_CODE === 101050) {
          Swal.fire({
            icon: 'error',
            text: 'Invalid Credentials',
          })
          dispatch({ type: "logout" })
        } else if (res.data.message.MSG_CODE === 100020) {
          dispatch({ type: "logout" })
          Swal.fire({
            icon: 'error',
            text: 'User does not exist',
          })
          emptyState();
        } else if (res.data.message.MSG_CODE === 100120) {
          dispatch({ type: "logout" })
          Swal.fire({
            icon: 'error',
            text: 'Password is incorrect',
          })
          setPassword('')
        }
      } else {
        if (isOtpRecieved === false) {
          data = {
            email: email,
            is_login_otp: true,
            preferred_language: 'EN',
          }
        } else {
          if (verificationCodeValue.length < 6) {
            Swal.fire({
              icon: 'error',
              text: 'Please enter your 6 digit code',
            })
            return
          }
          data = {
            email: email,
            otp: Number(verificationCodeValue),
            reference_id: referenceCode,
            is_login_otp: true,
          }
        }
        let res = await dispatch(apiCall(data,'/user/web-login'));
        if (res.data.message.MSG_CODE === 100071 && res.data.data.admin_panel_access.includes(res.data.data.role)) {
            emptyState();
            dispatch({ type: "logout" });
            localStorage.clear();
            dispatch({ type: "login", ...res.data.data });
            localStorage.setItem('accessToken', res.data.data.access_token);
            localStorage.setItem('refreshToken', res.data.data.refresh_token)
            localStorage.setItem('isAuthenticated', true);
            localStorage.setItem('email', email);
            localStorage.setItem('fullName', res.data.data.full_name);
            localStorage.setItem('profile_picture', res.data.data.profile_picture);
            localStorage.setItem('company_name', res.data.data.company_name);
            localStorage.setItem('role', res.data.data.role);
            localStorage.setItem('role_permission' , JSON.stringify(res.data.data.role_permission));
            localStorage.setItem('user_id', res.data.data.user_id);
            navigate("/admin/home")
        } else if (res.data.message.MSG_CODE === 100071) {
          dispatch({ type: "logout" })
          Swal.fire({
            icon: 'error',
            text: 'Your are not authorize to access this data',
            timer: 3000
          })
          emptyState();
        }
        else if (res.data.message.MSG_CODE === 100020) {
          dispatch({ type: "logout" })
          Swal.fire({
            icon: 'error',
            text: 'User does not exist',
          })
          emptyState();
        } else if (res.data.message.MSG_CODE === 100251) {
          codeIsVerified(res.data.data.reference_id);
          Swal.fire({
            icon: 'success',
            text: 'OTP send successfully',
          })

        } else if (res.data.message.MSG_CODE === 100340) {
          Swal.fire({
            icon: 'warning',
            text: 'OTP is already validated',
          })
        } else if (res.data.message.MSG_CODE === 100320) {
          Swal.fire({
            icon: 'error',
            text: 'OTP is incorrect',
          })
        } else if (res.data.message.MSG_CODE === 101050) {
          Swal.fire({
            icon: 'error',
            text: 'Invalid Credentials',
          })
        }
      }
    }
  }
  const codeIsVerified = (refId) => {
    setReferenceCode(refId);
    setisOtpRecieved(true);
  }
  //empty state 
  const emptyState = () => {
    setEmail("")
    setPassword("")
  }
  // update value 
  const showHidePass =() => {
    setShowNewPassInput(!showNewPassInput)
  }
  //redirect forgot password 
  const redirectForgotPass = () => {
    navigate("/forgotpassword ", { replace: true });
  }

  return (
    <div className='Login'>
      <div className='loginImage d-flex align-items-center' >
        <Container className='log-wrap' style={{ marginTop: 'auto' }}>
          <div className='loginCard lg-12 w-100' >
            <Row className='d-flex align-items-center justify-content-center' md={12} lg={12}>
              <Col xxl={4} md={6} lg={6} xl={4} className='loginBox d-flex align-items-center justify-content-around'>
                <form className="Auth-form w-75">
                  <div className="Auth-form-content">
                    <h2 className='text-center' style={{ color: '#1d60de' }} >Login</h2>
                    <div className="form-group mt-3">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control mt-1 lh-lg"
                        placeholder="Enter your email address *"
                        disabled={isOtpRecieved === true}
                        value={email}
                        onChange={(email) => addEmail(email.target.value)}
                      />
                      {
                        showPhoneEmailString === true ?
                          <label className='mt-2' style={{ color: "red" }}>{phoneEmailError}</label> :
                          null
                      }
                    </div>
                    {isLoginWithOtp === false ?
                      <div className="form-group mt-3">
                        <div className='d-flex'>
                          <input type={showNewPassInput ? 'text' : 'password'}
                            name="password"
                            id="password"
                            className="form-control mt-1  lh-lg"
                            placeholder="Enter your password *"
                            value={password}
                            onChange={(password) => addPassword(password.target.value)}
                          />
                          <span className='btn showHideBtnLog d-flex mt-1' onClick={()=>showHidePass()}>{ showNewPassInput !== false?<AiOutlineEye />:<AiOutlineEyeInvisible/>}</span>
                        </div>
                        {
                          showPsdString === true && isOtpRecieved === false ?
                            <label className='mt-1' style={{ color: "red" }}>{psdError}</label> : null
                        }
                      </div> : null
                    }
                    {
                      isOtpRecieved === true ?
                        <div className="form-group mt-3">
                          <label>Verifcation code</label>
                          <input
                            maxLength={4}
                            type="number"
                            name="number"
                            id="number"
                            className="form-control mt-1 lh-lg"
                            placeholder="Enter code"
                            value={verificationCodeValue}
                            onChange={(verificationCodeValue) => {
                              if (verificationCodeValue.target.value.length === 7) return false;
                              setVerificationCodeValue(verificationCodeValue.target.value)
                            }}
                          />
                        </div> : null
                    }
                    {/* 
                        otp recieved issue 
                        <p className=" form-group text-end mb-1 mt-2" role='button' onClick={()=>redirectForgotPass()}>Forgot password?</p> 
                      */}

                    <Row className="d-flex align-items-center justify-content-between my-3">
                      <button type="submit" className="btn btn-primary p-2" style={{ width: '48%' }} onClick={onLogin}>
                        Login
                      </button>
                      {isLoginWithOtp === false ?
                        <p className="btn secndBtn p-2 m-0" style={{ width: '48%' }} onClick={()=>loginWithotp()}>
                          Login with otp
                        </p> :
                        <p className="btn secndBtn p-2 m-0" style={{ width: '48%' }} onClick={() =>loginWithPass()}>
                          Login password
                        </p>
                      }
                    </Row>
                  </div>
                </form>
              </Col>
            </Row>
          </div>
          <div className='d-flex my-3' style={{ textAlign: 'center' }}>
            <Col>
              <img src={image.loginPgLeftImg} alt='logo' style={{ width: '20em' }} />
            </Col>
            <Col>
              <img src={image.loginPgRightImg} alt='logo' style={{ width: '20em' }} />
            </Col>
          </div>
        </Container>
      </div>
    </div>

  )
}

export default Login 

import React, { useState, useEffect } from 'react'
import { LeftSideBarData } from './LeftSideBarData';
import { NavLink } from "react-router-dom";
import { useDispatch } from 'react-redux';
import './SideBar.css'
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import { apiCall } from '../../../action/loginAction';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import CloseButton from 'react-bootstrap/CloseButton';
import Button from 'react-bootstrap/Button';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import image  from '../../../constant/image'

const LeftSideBar = (props) => {
  const dispatch = useDispatch();
  const [isChangePasswordModal, setIsChangePasswordModal] = useState(false);
  const [isProfileModal, setIsProfileModal] = useState(false);
  const [adminName, setAdminName] = useState(localStorage.getItem('fullName'));
  const [adminRole, setAdminRole] = useState(localStorage.getItem('role'));
  const [companyName, setCompanyName] = useState(localStorage.getItem('company_name'));
  const [passwordData, setPassWordData] = useState({ oldPassword: '', newPassword: '', confirmPassword: '' })
  const [showOldPassInput, setShowOldPassInput] = useState(false);
  const [showNewPassInput, setShowNewPassInput] = useState(false);
  const [showConfirmPassInput, setShowConfirmPassInput] = useState(false);
  const [filteredSideBar, setFilteredSideBar] = useState([])
  const userId = localStorage.getItem('user_id');
  const { profileUrl } = useSelector(state => state.profileUrlData);
  const [profilePicture, setProfilePicture] = useState('')
  const { isLoadImage } = useSelector(state => state.profileUrlData);
  const [defaultProfilePicture, setDefaultProfilePicture] = useState(image['porfilePicture'])
  const [defaultProfilePicture1, setDefaultProfilePicture1] = useState(image)



  useEffect(() => {
    let profilepic= ''
    if(adminRole ==='ENTERPRISE'){
      profilepic = JSON.parse(JSON.stringify(localStorage.getItem("company_logo")))
      if(profilepic.length > 4){
        setProfilePicture(profilepic)
      }else{
        setProfilePicture(defaultProfilePicture)

      }
    }else{
      profilepic = JSON.parse(JSON.stringify(localStorage.getItem("profile_picture")))
      setProfilePicture(profilepic)

    }
    console.log(profilePicture , "here")

    let menu = JSON.parse(localStorage.getItem('role_permission')).menu_permission
    let newMenu = LeftSideBarData.filter(singleMenu => {
      return menu.includes(singleMenu.title)
    })
    setFilteredSideBar(newMenu)
    console.log(profilePicture)
  }, [])
  useEffect(() => {
    if (isLoadImage) {
      getUserProfile()
    } else {
      dispatch({ type: "notGetImage" });
    }
  }, [isLoadImage])
  //get userProfile
  const getUserProfile = async () => {
    var data = {
      get_profile: true,
      preferred_language: 'EN',
      user_id: userId
    };
    let res = await dispatch(apiCall(data, '/user/profile'));
    localStorage.setItem('profile_picture', res.data.data.profile_picture);
    dispatch({ type: "profileImage", ...res.data.data });
  }

  //logout
  const logoutAdminPannel = () => {
    dispatch({ type: "logout" });
    localStorage.clear();
  }
  //update passowrd
  const updatePassword = async () => {
    if (passwordData.newPassword === '' || passwordData.confirmPassword === '' || passwordData.oldPassword === '') {
      Swal.fire({
        icon: 'error',
        text: 'Please fill all given field',
        timer: 3000
      })
      return
    }
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      Swal.fire({
        icon: 'error',
        text: 'New password and confirm password must be same!',
        timer: 3000
      })
      return
    }
    var data = {
      current_password: passwordData.oldPassword,
      new_password: passwordData.confirmPassword
    };
    let res = await dispatch(apiCall(data, '/user/change-password'));
    if (res.data.message.MSG_CODE === 100061) {
      Swal.fire({
        icon: 'success',
        text: 'Password change successfully!',
        timer: 3000
      })
      empty();
    }
    else if (res.data.message.MSG_CODE === 100110) {
      empty();
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again!',
        timer: 4000
      })
    }
  }
  const empty = () => {
    setShowOldPassInput(false);
    setShowNewPassInput(false);
    setShowConfirmPassInput(false);
    setIsChangePasswordModal(false);
    setIsProfileModal(false)
    setPassWordData({ oldPassword: '', newPassword: '', confirmPassword: '' })
  }

  return (
    <>
      <div className='leftSidebar' style={{ height: '100%', overflow: 'auto' }}>
        <React.Fragment>
          <section className='adminPannelNameLogo py-2 mt-2'>
            <div className='py-2 d-flex  justify-content-start align-items-center'>
              <span className='px-2'><img src={image.adminPageLogo} className='adminPannelMedisetterLogo' alt='logo' /></span>
              <span className='adminPannelMedisetterHeading'>Medisetter</span>
            </div>
          </section>
          <section className='submenutLogoutOption d-flex flex-column justify-content-between '>
            <section style={{ height: 'auto' }}>
              <div className='text-white'>
                {
                  filteredSideBar.map((item, index) => {
                    return (
                      <div key={index} className='mx-2 adminPannelOption ' role="button" >
                        <NavLink to={item.path} className='px-3 py-1 text-light text-decoration-none'>
                          <div className='d-flex justify-content-start align-items-center adminPannelMedisetterOptionDiv'>
                            {/* <span>{item.icon}</span> */}
                            <img src={item.icon} className='adminPannelMedisetterOptionLogo' alt='logo' />
                            <span className='adminPanelOptionTitle'>{item.title}</span>
                          </div>
                        </NavLink>
                      </div>
                    )
                  })
                }
              </div>
            </section>
            <section className='profileInfo mt-2' >
              <div className='text-white'>
                <div className='profileNameLogo d-flex justify-content-start align-items-center  ' onClick={() => setIsProfileModal(true)}>
                  {
                     profileUrl.role !=='ENTERPRISE' && profileUrl.profile_picture ?
                      <>
                        {
                          profileUrl.profile_picture !== null ?
                            <img role='' src={profileUrl.profile_picture} alt='logo' className='leftProfileImagePic 1' /> :
                            <img className='leftProfileImagePic 2' src={defaultProfilePicture} alt='postProfileImagePic' />
                        }
                      </> :
                       profileUrl.role =='ENTERPRISE' && profilePicture && profilePicture!==null && profilePicture!== '' ?
                      <img className='leftProfileImagePic 3' src={profilePicture} alt='postProfileImagePic' />:<img className='leftProfileImagePic 4' src={defaultProfilePicture} alt='postProfileImagePic' />
                  }
                  <div className='d-grid profileName'>
                    <span>Welcome back</span>
                    {
                      adminName !== 'null' ?
                        <span>{adminName}</span> :
                        <span>{companyName}</span>
                    }
                  </div>
                </div>
                <div className='adminPannelLogout my-3' role="button">
                  <NavLink to='/' className='text-light text-decoration-none' role='button'>
                    <div className='d-flex justify-content-start align-items-center adminPannelLogoutDiv' onClick={() => logoutAdminPannel()}>
                      <img src={image.logoutBtn} alt='logo' className='adminPannelLogoutBtn' />
                      <span className='mx-4'>Logout</span>
                    </div>
                  </NavLink>
                </div>
              </div>
            </section>
          </section>
        </React.Fragment>
      </div>
      <Modal
        show={isProfileModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='justify-content-between'
      >
        {
          isChangePasswordModal === true ?
            <>
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1em' }}>
                  Change Password
                </Modal.Title>
                <CloseButton onClick={empty} />
              </Modal.Header>
              <Modal.Body>
                <section>
                  <Row>
                    <div className='my-1'>
                      <div className='my-1'>
                        Old Password
                      </div>
                      <div className='d-flex'>
                        <input type={showOldPassInput ? 'text' : 'password'} className="form-control"
                          placeholder="Old Password" aria-label="Title"
                          aria-describedby="basic-addon1"
                          value={passwordData.oldPassword}
                          onChange={(password) => setPassWordData({ ...passwordData, oldPassword: password.target.value })}
                        />
                        <button className='btn showHideBtn' onClick={() => setShowOldPassInput(!showOldPassInput)}>{showOldPassInput !== false ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}</button>
                      </div>
                    </div>
                    <div className='my-1'>
                      <div className='my-1'>
                        New Password
                      </div>
                      <div className='d-flex'>
                        <input type={showNewPassInput ? 'text' : 'password'} className="form-control"
                          placeholder="New Password" aria-label="Title"
                          aria-describedby="basic-addon1"
                          value={passwordData.newPassword}
                          onChange={(e) => setPassWordData({ ...passwordData, newPassword: e.target.value })}
                        />
                        <button className='btn showHideBtn' onClick={() => setShowNewPassInput(!showNewPassInput)}>{showNewPassInput !== false ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}</button>
                      </div>
                    </div>
                    <div className='my-1'>
                      <div className='my-1'>
                        Confirm Password
                      </div>
                      <div className='d-flex'>
                        <input type={showConfirmPassInput ? 'text' : 'password'} className="form-control"
                          placeholder="Confirm Password" aria-label="Title"
                          aria-describedby="basic-addon1"
                          value={passwordData.confirmPassword}
                          onChange={(e) => setPassWordData({ ...passwordData, confirmPassword: e.target.value })}
                        />
                        <button className='btn showHideBtn' onClick={() => setShowConfirmPassInput(!showConfirmPassInput)}>{showConfirmPassInput !== false ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}</button>
                      </div>
                    </div>
                  </Row>
                </section>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => setIsChangePasswordModal(false)} style={{ background: 'white', color: 'blue' }}>Cancel</Button>
                <Button onClick={() => updatePassword()}>Save</Button>
              </Modal.Footer>
            </> :
            <>
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1em' }}>
                  Profile Details
                </Modal.Title>
                <CloseButton onClick={empty} />
              </Modal.Header>
              <Modal.Body>
                <section>
                  <div className='d-flex justify-content-centre align-items-center  px-2 '>
                    {
                      profileUrl.profile_picture ?
                        <>
                          {
                            profileUrl.profile_picture !== null ?
                              <img role='' src={profileUrl.profile_picture} alt='logo' className='leftProfileImagePic' /> :
                              <img className='leftProfileImagePic' src={image.porfilePicture} alt='postProfileImagePic' />
                          }
                        </> :
                        <img className='leftProfileImagePic' src={profilePicture} alt='postProfileImagePic' />
                    }
                    <div className='d-grid profileNameDetails'>
                      <p className='m-0 my-1'> {
                        adminName !== 'null' ? adminName : companyName
                      }</p>
                      <p className='m-0'>{adminRole}</p>
                    </div>
                  </div>
                  <div className='my-3'>
                    <ul>
                      <li className='listOfIndData' onClick={() => setIsChangePasswordModal(true)}>
                        Change Password
                      </li>
                    </ul>
                  </div>
                </section>
              </Modal.Body>
              <Modal.Footer>
              </Modal.Footer>
            </>
        }
      </Modal>
    </>
  )
}

export default LeftSideBar
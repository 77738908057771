import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { apiCall } from '../../../../action/loginAction';
import Swal from 'sweetalert2';
import CloseButton from 'react-bootstrap/CloseButton';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'react-quill/dist/quill.snow.css';
import './Channels.css'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const AddUserManagementModal = ({ closeModal, getUsersData }) => {
    const dispatch = useDispatch();
    //model details
    const [logoImage, setLogoImage] = useState({ fileName: '', media_id: '' });
    const email = localStorage.getItem('email');
    const role = localStorage.getItem('role');

    const [logoImageUrl, setLogoImageUrl] = useState('');
    // enterprise user
    const [fullCompanyName, setFullCompanyName] = useState('')
    const [emailAddress, setEmailAddress] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [officeAddress, setOfficeAddress] = useState('')
    const [fullName, setFullName] = useState('')
    const [emailAddressPersonal, setEmailAddressPersonal] = useState('')
    const [designation, setDesignation] = useState('')
    const [roles, setRoles] = useState([])
    const [selectedRole, setSelectedRole] = useState('GUEST')
    const [emailCoAdmin, setEmailCoAdmin] = useState("");
    const [phoneNumberCoAdmin, setPhoneNumberCoAdmin] = useState('');
    const [firstNameCoAdmin, setFirstNameCoAdmin] = useState('')
    const [lastNameCoAdmin, setLastNameCoAdmin] = useState('')
    const [companyList, setCompanyList] = useState([])
    const [uniqueCompanyName, setUniqueCompanyName] = useState(false)
    // get list of channel 
    useEffect(() => {
        let rolesLocal = JSON.parse(localStorage.getItem('role_permission')).internal_permission.ADD_USER
        setRoles(rolesLocal);
        companyName();
    }, [])
    useEffect(() => {
        if (fullCompanyName !== ''){
            checkValuePresent(fullCompanyName);
        }else{
            setUniqueCompanyName(false)
        }
    }, [fullCompanyName])
    // create channel 
    const addUserModal = async () => {
        const regex = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/
        if (selectedRole === 'ENTERPRISE') {
            if (fullCompanyName !== '' && !uniqueCompanyName && selectedRole !== "" && emailAddress !== "" && regex.test(emailAddress)) {
                let data = {
                    is_add_user: true,
                    email: emailAddress,
                    company_name: fullCompanyName,
                    phone: phoneNumber,
                    office_address: officeAddress,
                    contact_person_name: fullName,
                    contact_person_email: emailAddressPersonal,
                    contact_person_designation: designation,
                    role_name: selectedRole,
                    preferred_language: 'EN',
                    requested_by: role,
                    logo_image: logoImage.media_id !== '' ? logoImage.media_id : null,
                }
                let res = await dispatch(apiCall(data, '/user/add-user'));
                if (res.data.message.MSG_CODE === 100011) {
                    closeModal();
                    getUsersData(1, 10);
                    Swal.fire({
                        icon: 'success',
                        text: 'User has been created successfully',
                        timer: 3000
                    })
                } else if (res.data.message.MSG_CODE === 100390) {
                    Swal.fire({
                        icon: 'error',
                        text: 'Channel was not created, please try again later',
                        timer: 3000
                    })
                    closeModal();
                    getUsersData(1, 10);
                } else if (res.data.message.MSG_CODE === 100380) {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Channel name already exist please change it',
                        timer: 3000
                    })
                } else if (res.data.message.MSG_CODE === 100620) {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Channel name already exist please change it',
                        timer: 3000
                    })
                } else if (res.data.message.MSG_CODE === 100010) {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Details not entered correctly',
                        timer: 3000
                    })
                }else if (res.data.message.MSG_CODE === 100030) {
                    clearEmailAddress()
                    Swal.fire({
                        icon: 'warning',
                        text: 'User already exist',
                        timer: 3000
                    })
                }
            } else if (fullCompanyName === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Company name not entered ',
                    timer: 3000
                })
            } else if (selectedRole === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Role  not selected ',
                    timer: 3000
                })
            } else if (emailAddress === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Email address not entered ',
                    timer: 3000
                })
            } else if (!regex.test(emailAddress)) {
                Swal.fire({
                    icon: 'warning',
                    text: 'Email not entered correctly',
                    timer: 3000
                })
            } else if (!uniqueCompanyName) {
                Swal.fire({
                    icon: 'warning',
                    text: 'Company already exists',
                    timer: 3000
                })
            }

        } else if (selectedRole === 'CO-ADMIN' || selectedRole === 'GUEST') {
            if (emailCoAdmin !== '' && selectedRole !== "" && firstNameCoAdmin !== "" && lastNameCoAdmin !== "" && regex.test(emailCoAdmin)) {
                let data = {
                    is_add_user: true,
                    email: emailCoAdmin,
                    first_name: firstNameCoAdmin,
                    last_name: lastNameCoAdmin,
                    phone: phoneNumberCoAdmin,
                    role_name: selectedRole,
                    preferred_language: 'EN',
                    requested_by: role

                }
                let res = await dispatch(apiCall(data, '/user/add-user'));
                if (res.data.message.MSG_CODE === 100011) {
                    closeModal();
                    getUsersData(1, 10);
                    Swal.fire({
                        icon: 'success',
                        text: 'User has been created successfully',
                        timer: 3000
                    })
                } else if (res.data.message.MSG_CODE === 100390) {
                    Swal.fire({
                        icon: 'error',
                        text: 'Channel was not created, please try again later',
                        timer: 3000
                    })
                    closeModal();
                    getUsersData(1, 10);
                } else if (res.data.message.MSG_CODE === 100380) {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Channel name already exist please change it',
                        timer: 3000
                    })
                } else if (res.data.message.MSG_CODE === 100620) {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Channel name already exist please change it',
                        timer: 3000
                    })
                } else if (res.data.message.MSG_CODE === 100010) {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Details not entered correctly',
                        timer: 3000
                    })
                }else if (res.data.message.MSG_CODE === 100030) {
                    clearEmailAddress() 
                    Swal.fire({
                        icon: 'warning',
                        text: 'User already exist',
                        timer: 3000
                    })
                }
            } else if (selectedRole === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Role  not selected ',
                    timer: 3000
                })
            } else if (emailCoAdmin === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Email address not entered ',
                    timer: 3000
                })
            } else if (!regex.test(emailCoAdmin)) {
                Swal.fire({
                    icon: 'warning',
                    text: 'Email not entered correctly',
                    timer: 3000
                })
            } else if (firstNameCoAdmin === '') {
                Swal.fire({
                    icon: 'warning',
                    text: 'First name not entered correctly',
                    timer: 3000
                })
            } else if (lastNameCoAdmin === '') {
                Swal.fire({
                    icon: 'warning',
                    text: 'Last name not entered correctly',
                    timer: 3000
                })
            }

        }
    }
    // upload api
    const uploadFile = async (e) => {
        setLogoImage({ fileName: e.name })
        const convertString = await convertBase64(e);
        const base64Array = convertString.split(",");
        const base64 = base64Array[1];
        var data = {
            email: email,
            file_path: "channel",
            file_data: base64,
            file_name: e.name,
        };
        let res = await dispatch(apiCall(data, '/user/upload'));
        if (res.data.message.MSG_CODE === 100231) {
            setLogoImage({ media_id: res.data.data.media_id })
            setLogoImageUrl(res.data.data.url)

        } else if (res.data.message.MSG_CODE === 100270) {
            Swal.fire({
                icon: 'error',
                text: 'Not valid extension please upload again (accept only JPEG, PNG, JPG)',
                timer: 3000
            })
        } else if (res.data.message.MSG_CODE === 100580 || res.data.message.MSG_CODE === 100010) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please upload again',
                timer: 3000
            })
        }
        else if (res.data.message.MSG_CODE === 100580 || res.data.message.MSG_CODE === 100010) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please upload again',
                timer: 3000
            })
        }
    }
    // base64 conversion
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = (() => {
                resolve(fileReader.result);
            });
            fileReader.onerror = ((error) => {
                reject(error)
            });
        });
    };
    const userRoleSelected = (item) => {
        if (selectedRole === item) {
            setSelectedRole('GUEST')
        } else {
            setSelectedRole(item)
        }
    }
    const companyName = async () => {
        var data = {
            get_unique_company: true,
        };
        let res = await dispatch(apiCall(data, '/user/add-user'));
        if (res.data.message.MSG_CODE === 100871) {
            setCompanyList(res.data.data)
        }
    }
    const checkValuePresent = (val) => {
        let isInclude = companyList.includes(val)
        if (isInclude === true) {
            setUniqueCompanyName(true)
        } else {
            setUniqueCompanyName(false)
        }
    }

    const clearEmailAddress = () => {
        setEmailCoAdmin("")
        setEmailAddress("")
    }
    return (
        <>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '0.3em' }}>
                    Enterprise User Details
                </Modal.Title>
                <CloseButton onClick={closeModal} />
            </Modal.Header>
            <Modal.Body>
                <section>
                    <Row>
                        <div className='d-flex align-items-center'>

                            <span style={{ marginRight: '1rem', fontWeight: 'bold' }}>Select role type<span className="redStar m-1" >&#42;</span></span>
                            <DropdownButton id="dropdown-basic-button px-1" title={selectedRole.length > 10 ? selectedRole.slice(0, 10) + '...' : selectedRole} className='mb-2'>
                                {
                                    roles && roles.length > 0 && roles.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <Dropdown.Item className={selectedRole === item ? 'active' : ''} onClick={() => userRoleSelected(item)} >{item}</Dropdown.Item>
                                            </div>
                                        )
                                    })
                                }
                            </DropdownButton>
                        </div>
                        <Col xxl={12} xl={12} lg={12} md={12}>
                            {selectedRole === 'ENTERPRISE' &&
                                <div className='userManagmentScreenBox'>
                                    <div className='my-1'>
                                        <p>
                                            Full name of company <span className="redStar">&#42;</span>
                                        </p>
                                        <input type="text"
                                            placeholder="Company name" aria-label="Title"
                                            aria-describedby="basic-addon1"
                                            value={fullCompanyName}
                                            id="companyNameInput"
                                            className={`form-control ${uniqueCompanyName ? 'reBorderClass' : ''}`}
                                            onChange={(e) => setFullCompanyName(e.target.value)}
                                        />
                                         <div className=''>{uniqueCompanyName && <span className="redStar m-0 p-0">Company already exists</span>}</div>
                                    </div>
                                    <div className='my-2'>
                                        <p>Upload logo image <span>(jpeg,png)</span>
                                            <span>{logoImageUrl !== '' ? <a href={logoImageUrl} target="_blank" rel="noreferrer">Preview</a> : null}</span></p>
                                        <input type="file" className="form-control"
                                            aria-describedby="basic-addon1"
                                            accept="image/png, image/jpeg"
                                            onChange={(event) => uploadFile(event.target.files[0])}
                                        />
                                    </div>
                                    <div className='my-1'>
                                        <p>
                                            Email Address of Company <span className="redStar">&#42;</span>
                                        </p>
                                        <input type="text" className="form-control"
                                            placeholder="Email address " aria-label="Title"
                                            aria-describedby="basic-addon1"
                                            value={emailAddress}
                                            onChange={(e) => setEmailAddress(JSON.parse(JSON.stringify(e.target.value)))}
                                        />
                                    </div>

                                    <div className='my-1'>
                                        <p>
                                            Phone Number of Company
                                        </p>

                                        <PhoneInput

                                            country={'vn'}
                                            placeholder="Enter phone number"
                                            value={phoneNumber}
                                            onChange={setPhoneNumber} />
                                    </div>
                                    <div className='my-1'>
                                        <p>
                                            Office Address
                                        </p>
                                        <input type="text" className="form-control"
                                            placeholder="Office address " aria-label="Title"
                                            aria-describedby="basic-addon1"
                                            value={officeAddress}
                                            onChange={(e) => setOfficeAddress(JSON.parse(JSON.stringify(e.target.value)))}
                                        />
                                    </div>
                                    <hr />
                                    <div className='my-1'>
                                        <p>
                                            Full name of contact person
                                        </p>
                                        <input type="text" className="form-control"
                                            placeholder="Full name" aria-label="Title"
                                            aria-describedby="basic-addon1"
                                            value={fullName}
                                            onChange={(e) => setFullName(JSON.parse(JSON.stringify(e.target.value)))}
                                        />
                                    </div>
                                    <div className='my-1'>
                                        <p>
                                            Email Address of contact person
                                        </p>
                                        <input type="text" className="form-control"
                                            placeholder="Email address" aria-label="Title"
                                            aria-describedby="basic-addon1"
                                            value={emailAddressPersonal}
                                            onChange={(e) => setEmailAddressPersonal(JSON.parse(JSON.stringify(e.target.value)))}
                                        />
                                    </div>
                                    <div className='my-1'>
                                        <p>
                                            Designation of contact person
                                        </p>
                                        <input type="text" className="form-control"
                                            placeholder="Designation" aria-label="Title"
                                            aria-describedby="basic-addon1"
                                            value={designation}
                                            onChange={(e) => setDesignation(JSON.parse(JSON.stringify(e.target.value)))}
                                        />
                                    </div>
                                    <div className='my-3' style={{ float: 'right' }}>
                                        <Button className='mx-2' onClick={closeModal} style={{ background: 'white', color: 'blue' }}>Close</Button>
                                        <Button onClick={addUserModal}>Add</Button>
                                    </div>
                                </div>
                            }
                            {(selectedRole === 'CO-ADMIN' || selectedRole === 'GUEST') &&
                                <div className='userManagmentScreenBox'>
                                    <div className='my-1'>
                                        <p>
                                            Email of {selectedRole.toLocaleLowerCase()} <span className="redStar">&#42;</span>
                                        </p>
                                        <input type="text" className="form-control"
                                            placeholder="Email address" aria-label="Title"
                                            aria-describedby="basic-addon1"
                                            value={emailCoAdmin}
                                            onChange={(e) => setEmailCoAdmin(JSON.parse(JSON.stringify(e.target.value)))}
                                        />
                                    </div>
                                    <div className='my-1'>
                                        <p>
                                            Phone Number of {selectedRole.toLocaleLowerCase()}
                                        </p>

                                        <PhoneInput

                                            country={'vn'}
                                            placeholder="Enter phone number"
                                            value={phoneNumberCoAdmin}
                                            onChange={setPhoneNumberCoAdmin} />
                                    </div>
                                    <div className='my-1'>
                                        <p>
                                            First Name of {selectedRole.toLocaleLowerCase()} <span className="redStar">&#42;</span>
                                        </p>
                                        <input type="text" className="form-control"
                                            placeholder="First name " aria-label="Title"
                                            aria-describedby="basic-addon1"
                                            value={firstNameCoAdmin}
                                            onChange={(e) => setFirstNameCoAdmin(JSON.parse(JSON.stringify(e.target.value)))}
                                        />
                                    </div>


                                    <div className='my-1'>
                                        <p>
                                            Last Name of {selectedRole.toLocaleLowerCase()}<span className="redStar">&#42;</span>
                                        </p>
                                        <input type="text" className="form-control"
                                            placeholder="Last name " aria-label="Title"
                                            aria-describedby="basic-addon1"
                                            value={lastNameCoAdmin}
                                            onChange={(e) => setLastNameCoAdmin(JSON.parse(JSON.stringify(e.target.value)))}
                                        />
                                    </div>


                                    <div className='my-3' style={{ float: 'right' }}>
                                        <Button className='mx-2' onClick={closeModal} style={{ background: 'white', color: 'blue' }}>Close</Button>
                                        <Button onClick={addUserModal}>Add</Button>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                </section>
            </Modal.Body>
        </>
    )
}

export default AddUserManagementModal
import { createReducer } from "@reduxjs/toolkit";

export const addRoleUpadate = createReducer({isUpdateRole:false},{
    getNewRoleList: (state,action) => {
        state.isUpdateRole = true;
    },
    newRoleList: (state,) => {
        state.isUpdateRole = false;
    },
    deleteRoleList: (state,action) => {
        state.isDelete = true;
    },
    newPermissionList: (state,) => {
        state.isDelete = false;
    }
})
import React, { useEffect, useState, useRef } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import * as BsIcons from 'react-icons/bs'
import './Request.css'
import image from '../../../../constant/image';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import LeftSideBar from '../../SideBar/LeftSideBar';
import { useDispatch } from 'react-redux';
import { apiCall, approveUserBlobData } from '../../../../action/loginAction';
import Swal from 'sweetalert2';
import CloseButton from 'react-bootstrap/CloseButton';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import InputGroup from 'react-bootstrap/InputGroup';
import * as AiIcons from 'react-icons/ai';
import Form from 'react-bootstrap/Form';
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useNavigate } from "react-router-dom";
import { actionpermission } from '../../../../constant/commonFunctions'
import ReactPaginate from 'react-paginate';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import useDebounce from '../../../../constant/useDebouncing';
import Unauthorized from './Unauthorized';
const TopicTags = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [topicTagList, setTopicTagList] = useState([]);
  //model hide or show
  const [modalShow, setModalShow] = React.useState(false);
  const [profileDetails, setPorfileDetails] = useState({});
  const [content, setContent] = useState('');
  const editor = useRef(null);
  const [email, setemail] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState({});
  const [isFirstTimeHit, setIsFirstTimeHit] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState("All users");
  const [perPage, setPerPage] = useState(10)
  const [permissions, setPermissions] = useState([])
  const [internalPermissions, setInternalPermissions] = useState([]);
  const debouceSearchTerm = useDebounce(searchValue, 300);
  const initialRender = useRef(true);
  const [isExist, setIsExist] = useState(false);
  const [tagName, setTagName] = useState('')
  const [editModal, setEditModal] = React.useState(false);
  const [topicData, setTopicData] = useState({})
  const [userType,setUserType] = useState("Admin");
  //get list when page is loaded
  useEffect(() => {
    getTopicTagData(page, perPage);
    let permissionsArray = actionpermission("Users")
    let internalPermissionsLocal = JSON.parse(localStorage.getItem("role_permission")).internal_permission.ID_View
    setInternalPermissions(internalPermissionsLocal)
    setPermissions(permissionsArray)
  }, [])
  //debounce
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (debouceSearchTerm) {
        searchTopicTag(debouceSearchTerm, page, perPage)
      } else {
        getTopicTagData(page, perPage);
      }
    }
  }, [debouceSearchTerm, perPage, selectedUserType])
  //get topic data 
  const getTopicTagData = async (pg, perPage) => {
    var data = {
      get_topic_tags_list: true,
      preferred_language: 'EN',
      per_page: perPage,
      page_number: pg,
      paginated_result: true
    };
    let res = await dispatch(apiCall(data, '/user/topic-tags'));
    if (res.data.message.MSG_CODE === 100711) {
      setTopicTagList(res.data.data)
      setPageData(res.data.pagination)
    }
    else  if (res.data.message.MSG_CODE === 100130){
      setUserType("Enterprise")
    }
    else {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again later',
        timer: 3000
      })
    }
  }
  //empty data 
  const empty = () => {
    setContent('');
    setemail('');
    setPorfileDetails({});
    setTagName('')
    setSearchValue('');
    setPage(1)
    setStartDate(null)
    setModalShow(false);
    setTopicData({})
    setEditModal(false);
  }
  // search user 
  const searchTopicTag = async (value, pg, perpg) => {
    var data = {
      get_topic_tags_list: true,
      preferred_language: 'EN',
      per_page: perpg,
      page_number: pg,
      paginated_result: true,
      keyword: value
    };
    let res = await dispatch(apiCall(data, '/user/topic-tags'));
    if (res.data.message.MSG_CODE === 100711) {
      setTopicTagList(res.data.data)
      setPageData(res.data.pagination)
    }
    else {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again later',
        timer: 3000
      })
    }
  }
  // donwload csv list for users
  const downloadCsvList = async () => {

  }
  // pagination
  const handlePageClick = (data) => {
    let currentPage
    if (data.selected || data.selected === 0) {
      currentPage = data.selected + 1;
    } else {
      currentPage = Number(data)
    }
    setPage(currentPage)
    getTopicTagData(currentPage, perPage);
  }
  //delete
  const deleteUserSwal = (topicID) => {
    Swal.fire({
      text: "You want to delete this Tag",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed === true) {
        deleteTopicTag(topicID);
      }
    })
  }
  const deleteTopicTag = async (id) => {
    var data = {
      is_delete: true,
      preferred_language: 'EN',
      topic_tag_id: id
    };
    let res = await dispatch(apiCall(data, '/user/topic-tags'));
    empty()
    if (res.data.message.MSG_CODE === 100941) {
      handlePageClick(1)
      Swal.fire({
        icon: 'success',
        text: 'Topic Tag deleted successfully',
        timer: 3000
      })
    }
    else if (res.data.message.MSG_CODE === 101040) {
      Swal.fire({
        icon: 'error',
        text: 'Invalid topic tag id',
        timer: 3000
      })
    }
    else {
      Swal.fire({
        icon: 'error',
        text: 'Topic tag  delete failure',
        timer: 3000
      })
    }
  }
  const addNewTag = async () => {
    if (tagName.trim().length === 0) {
      Swal.fire({
        icon: 'error',
        text: 'Please enter a tag name',
        timer: 3000
      })
      return
    }
    var data = {
      is_add: true,
      preferred_language: 'EN',
      topic_tags: tagName
    };
    let res = await dispatch(apiCall(data, '/user/topic-tags'));
    empty()
    if (res.data.message.MSG_CODE === 100921) {
      handlePageClick(1)
      Swal.fire({
        icon: 'success',
        text: 'Topic Tag added successfully',
        timer: 3000
      })
    } else if (res.data.message.MSG_CODE === 101000) {
      Swal.fire({
        icon: 'error',
        text: 'Topic tag is duplicate',
        timer: 3000
      })
    }
    else {
      Swal.fire({
        icon: 'error',
        text: 'Invalid topic tag name',
        timer: 3000
      })
    }
  }
  const editTopicTag = (data) => {
    setEditModal(true)
    setTopicData(data)
  }
  const editTopicTagData = async (topicData) => {
    if (topicData.name.trim().length === 0) {
      Swal.fire({
        icon: 'error',
        text: 'Please enter a tag name',
        timer: 3000
      })
      return
    }
    var data = {
      is_edit: true,
      topic_tags: topicData.name,
      topic_tag_id:topicData.topic_tag_id
    };
    let res = await dispatch(apiCall(data, '/user/topic-tags'));
    empty()
    if (res.data.message.MSG_CODE === 100931) {
      handlePageClick(1)
      Swal.fire({
        icon: 'success',
        text: 'Topic Tag editted successfully',
        timer: 3000
      })
    } else if (res.data.message.MSG_CODE === 101000) {
      Swal.fire({
        icon: 'error',
        text: 'Topic tag is duplicate',
        timer: 3000
      })
    }
    else {
      Swal.fire({
        icon: 'error',
        text: 'Invalid topic tag name',
        timer: 3000
      })
    }
  }
  return (
    <>
      <section>
        <Row style={{ height: '100vh' }} xxl={12} xl={12} lg={12} md={12}>
          <Col className='' xxl={2} xl={2} lg={3} md={3} style={{ background: 'linear-gradient(90deg, #277AFC 0%, #27B3FC 100%)', height: '100%' }} >
            <LeftSideBar />
          </Col>
          {
            userType === "Admin" ? (    
            <Col className='bg-prime p-0 adminPannel' xxl={10} xl={10} lg={9} md={9}>
            <Container className='log-wrap p-0' fluid style={{ height: '100vh' }}>
              <React.Fragment>
                <section className='px-3 pt-3' style={{ height: '100%' }} >
                  <div>
                    <h5>Topic Tags</h5>
                    <hr></hr>
                  </div>
                  <>
                    <Row style={{ alignItems: 'center' }} xxl={12} xl={12} lg={12} md={12}>
                      <Col className='' xxl={4} xl={4} lg={5} md={4}>
                        <InputGroup className="">
                          <Form.Control
                            placeholder='Topic Tag'
                            aria-label="Events"
                            aria-describedby="basic-addon1"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyDown={(e) => setSearchValue(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                      <Col xxl={1} xl={1} lg={2} md={1} className='text-center'>
                        <DropdownButton id="dropdown-basic-button" title={perPage} size="sm">
                          <Dropdown.Item onClick={() => setPerPage(10)} >10</Dropdown.Item>
                          <Dropdown.Item onClick={() => setPerPage(25)} >25</Dropdown.Item>
                          <Dropdown.Item onClick={() => setPerPage(50)} >50</Dropdown.Item>
                        </DropdownButton>
                      </Col>
                      {
                        permissions.includes("Download list") &&
                        <Col className='text-end px-4 flex-grow-1' xxl={3} xl={3} lg={5} md={4}>
                          <Button variant="primary" style={{ height: '2.3rem' }} onClick={() => { setModalShow(true) }}>Add Tag</Button>
                        </Col>
                      }
                    </Row>
                    <div className='tableClass'>
                      <Table hover striped size="sm" className='table-responsive'>
                        <thead>
                          <tr>
                            <th className='w-25' >Sr No</th>
                            <th className='' >Name</th>
                            <th className=' text-center'> Edit</th>
                            <th className='w-25 text-center'> Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            topicTagList.map((item, index) => {
                              return (
                                <tr key={index} className='' role='button'>
                                  <td className='mx-2'>{index + 1}</td>
                                  <td className='' >
                                    <div className='d-flex align-items-center'>
                                      <span className='viewsOnPost'>{item.name}</span>
                                    </div>
                                  </td>
                                  <td className='editIcon text-center' onClick={() => editTopicTag(item)}>
                                    <AiIcons.AiFillEdit />
                                  </td>
                                  <td className='text-center' style={{ color: 'red' }} onClick={() => deleteUserSwal(item.topic_tag_id)}>
                                    <AiIcons.AiFillDelete />
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </Table>
                      {
                        pageData.total_page > 1 && (
                          <ReactPaginate
                            className='pagination m-0'
                            breakLabel={'...'}
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            pageCount={pageData.total_page}
                            previousLabel="< prev"
                            renderOnZeroPageCount={null}
                            containerClassName="pagination"
                            activeClassName={'active'}
                            pageClassName={'page-item'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            pageLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                          />
                        )
                      }
                    </div>
                  </>
                </section>
              </React.Fragment>
            </Container>
          </Col>) : (
            <Col className='bg-prime p-0 adminPannel' xxl={10} xl={10} lg={9} md={9}>
              <Unauthorized/>
            </Col>
          )
          }
      
        </Row>
      </section>
      <Modal
        show={modalShow}
        size="md"
        centered
        backdrop="static"
        className='justify-content-between'
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '0.3em' }}>
            Tag Role
          </Modal.Title>
          <CloseButton onClick={() => empty()} />
        </Modal.Header>
        <Modal.Body>
          <>
            <div className='my-1'>
              <p className='mb-1'>
                Tag Name
              </p>
              <input type="text" placeholder="Tag Name"
                aria-label="Title" aria-describedby="basic-addon1"
                value={tagName}
                className={`form-control ${isExist ? 'reBorderClass' : ''}`}
                onChange={(e) => setTagName(e.target.value)}
              />
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => addNewTag()} className='btn btn-primary'>Save</Button>
        </Modal.Footer>
      </Modal>
      <Modal
       {...topicData}
        show={editModal}
        size="md"
        centered
        backdrop="static"
        className='justify-content-between'
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '0.3em' }}>
            Edit tag Role
          </Modal.Title>
          <CloseButton onClick={() => empty()} />
        </Modal.Header>
        <Modal.Body>
          <>
            <div className='my-1'>
              <p className='mb-1'>
                Tag Name
              </p>
              <input type="text" placeholder="Tag Name"
                aria-label="Title" aria-describedby="basic-addon1"
                value={topicData.name}
                className={`form-control ${isExist ? 'reBorderClass' : ''}`}
                onChange={(e) => setTopicData({...topicData,name:e.target.value})}
              />
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => editTopicTagData(topicData)} className='btn btn-primary'>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default TopicTags
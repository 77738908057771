export default {
    whiteBg:require('../assets/image/whitebg.jpg'),
    loginImage:require('../assets/image/loginImage.png'),
    appIconLogin:require('../assets/image/appIconLogin.png'),
    psdFieldIcon:require('../assets/image/psdFieldIcon.png'),
    emailFieldIcon:require('../assets/image/emailFieldIcon.png'),
    btnArrewo:require('../assets/image/btnArrewo.png'),
    hidePsdIcon:require('../assets/image/hidePsdIcon.png'),
    bgLoginPg:require('../assets/image/loginPgbgImage.png'),
    loginPgLeftImg:require('../assets/image/loginImageLeftSide.png'),
    loginPgRightImg:require('../assets/image/loginPagerightsideImage.png'),
    adminPageLogo:require('../assets/image/MedisetterLogo.png'),
    logoutBtn:require('../assets/image/logoutBtn.png'),
    porfilePicture:require('../assets/image/porfilePicture.png'),
    adminPannelClick:require('../assets/image/AdminPannelClick.png'),
    adminPannelComments:require('../assets/image/AdminPannelComments.png'),
    adminPannelLikes:require('../assets/image/AdminPannelLikes.png'),
    adminPannelViews:require('../assets/image/AdminPannelViews.png'),
    loaderView:require('../assets/image/loadingspinner.gif'),
    galleryImage:require('../assets/image/gallery.png'),
    videoImage:require('../assets/image/VideosIcon.png'),
    audioImage:require('../assets/image/Audio.png'),
    calenderImage:require('../assets/image/calendar.png'),
    locationImage:require('../assets/image/location.png'),
    modeImage:require('../assets/image/mode.png'),
    peopleImage:require('../assets/image/people.png'),
    topicsImage:require('../assets/image/topics.png'),
    specialityImage:require('../assets/image/speciality.png'),
    ticketImage:require('../assets/image/ticket.png'),
    likeImage:require('../assets/image/like.png'),
    commentImage:require('../assets/image/comment.png'),
    shareImage:require('../assets/image/share.png'),
    like2Image:require('../assets/image/like2.png'),
    channelImage:require('../assets/image/channel.png'),
    eventImage:require('../assets/image/event.png'),
    newsImage:require('../assets/image/news.png'),
    requestImage:require('../assets/image/request.png'),
    researchImage:require('../assets/image/research.png'),
    quizImage:require('../assets/image/quiz.png'),
    caseImage:require('../assets/image/casediscussion.png'),
    druginfoImage:require('../assets/image/druginfo.png'),
    guideImage:require('../assets/image/guide.png'),
    homeImage:require('../assets/image/home.png'),
    attributesImage:require('../assets/image/attributes.png'),
    downloadImage:require('../assets/image/downloadOpt.png'),
    sendMessageImage:require('../assets/image/sendMessage.png'),
    analyticsImage:require('../assets/image/analytics.png'),
    checkListImage:require('../assets/image/checklist.png'),
    educationDegreeImage:require('../assets/image/educationalDegree.png'),
    educationInstituteImage:require('../assets/image/educationalInstitute.png'),
    specailityImage:require('../assets/image/specailityFieldIcon.png'),
    workPlaceImage:require('../assets/image/workPlaceFieldIcon.png'),
    locationFieldIconImage:require('../assets/image/locationFieldIcon.png'),
    attachmentIconImage:require('../assets/image/attachment.png'),
    target:require('../assets/image/bullseye.png'),
    views:require('../assets/image/view.png')
}
import { createReducer } from "@reduxjs/toolkit";

export const rootReducer = createReducer({isAuthenticated:false,isLocalData:''},{
    login: (state,action) => {
        state.isAuthenticated = true;
        state.isLocalData = action;
    },
    logout: (state,) => {
        state.isAuthenticated = false;
        state.isLocalData = [];
    }
})
import React from 'react'
import LeftSideBar from './SideBar/LeftSideBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RightAdminPannel from './RightSide/RightAdminPannel';
const AdminPanelScreen = () => {
  return (
    <>
      <section>
          <Row style ={{height:'100vh'}}>
            <Col xxl={3} xl={3} lg={4} md ={4} style = {{background:'linear-gradient(90deg, #277AFC 0%, #27B3FC 100%)',height:'100%'}} >
                <LeftSideBar/>
            </Col>
            <Col className='bg-prime p-0 adminPannel' xxl={9} xl={9} lg={8} md ={8}>
                <RightAdminPannel/>
            </Col>
          </Row>
          </section>
    </>
  )
}

export default AdminPanelScreen
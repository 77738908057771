import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import SSRProvider from 'react-bootstrap/SSRProvider';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route,Routes } from "react-router-dom";
import store from './features/store/store';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <SSRProvider>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path = "/*" element = {<App/>}/>
          </Routes>
        </Router>
      </Provider>
    </SSRProvider>
);
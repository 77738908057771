import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import CloseButton from 'react-bootstrap/CloseButton';
import InputGroup from 'react-bootstrap/InputGroup';
import * as AiIcons from 'react-icons/ai';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import { apiCall } from '../../../../../action/loginAction';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import image from '../../../../../constant/image';
import AddUserManagementModal from '../AddUserManagementModal';
import { actionpermission } from '../../../../../constant/commonFunctions';
import ReactPaginate from 'react-paginate';
import './AllUsers.css'

const AllUsers = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10)
  const [pageData, setPageData] = useState({});
  const [addedUsersList, setAddedUsersList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [userModal, setUserModal] = useState(false)
  const [infoModal, setInfoModal] = useState(false)
  const [profileDetails, setPorfileDetails] = useState({});
  const [permissions, setPermissions] = useState([])


  useEffect(() => {
    let permissionsArray = actionpermission("User Management")
    setPermissions(permissionsArray)
  }, [])
  useEffect(() => {
    if (searchValue === '') {
      getUsersData(page, perPage);
    }
    if (searchValue.trim() !== "") {
      searchUser()
    }
  }, [searchValue, perPage, page])
  const addUser = () => {
    setUserModal(true)
  }
  const closeModal = () => {
    setUserModal(false)
  }
  const openModelUserInfo = async (user) => {
    let data = {
      email: user,
      get_added_users: true,
    };
    let res = await dispatch(apiCall(data, '/user/add-user'));
    if (res.data.message.MSG_CODE === 100841) {
      setPorfileDetails(res.data.data[0]);
      setTimeout(() => {
        setInfoModal(true);
      }, 500)
    } else if (res.data.message.MSG_CODE === 100020) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        text: "User does not exist",
        timer: 3000,
        showConfirmButton: false,
      })
    }
  }

  const navigateToEditUser = (item) => {
    navigate('/admin/user-management/edit', {
      state: {
        data: item
      }
    })
  }

  //get pending approval user list 
  const getUsersData = async (pg, perpg) => {
    let getRoles = JSON.parse(localStorage.getItem('role_permission')).internal_permission.ADD_USER
    let data = {
      get_added_users: true,
      per_page: perpg,
      page_number: pg,
      preferred_language: 'EN',
      role_name: getRoles
    };
    dispatch({ type: "showLoader" });
    let res = await dispatch(apiCall(data, '/user/add-user'));
    if (res.data.message.MSG_CODE === 100841) {
      setAddedUsersList(res.data.data);
      setPageData(res.data.pagination);
    }
    else if (res.data.message.MSG_CODE === 100500) {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again!',
        timer: 4000
      })
    }
    dispatch({ type: "hideLoader" });
  }
  // search user 
  const searchUser = async () => {
    let data = {
      get_added_users: true,
      preferred_language: 'EN',
      search_user: searchValue,
      per_page: perPage,
      page_number: page,
    }
    let res = await dispatch(apiCall(data, '/user/add-user'));
    if (res.data.message.MSG_CODE === 100841) {
      setAddedUsersList(res.data.data);
      setPageData(res.data.pagination);
    } else if (res.data.message.MSG_CODE === 100020) {
      setAddedUsersList([]);
      setPageData({});
      setPage(1);
    } else if (res.data.message.MSG_CODE === 100500) {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again!',
        timer: 4000
      })
    }
  }
  const handlePageClick = (data) =>{
    let currentPage = data.selected + 1 ;
    setPage(currentPage)
  }
  const closeModel = () => {
    setInfoModal(false);
  }
  const deleteUser = async (dataemail) => {
    let data = {
      email: dataemail,
      is_delete_user: true
    }
    let res = await dispatch(apiCall(data, '/user/add-user'));
    if (res.data.message.MSG_CODE === 100851) {
      Swal.fire({
        icon: 'success',
        text: 'user deleted successfully',
        timer: 2000
      })
      getUsersData(page, perPage)
    } else if (res.data.message.MSG_CODE === 100900) {
      Swal.fire({
        icon: 'error',
        text: 'Role not deleted',
        timer: 4000
      })
    }
  }
  const deleteUserSwal = (dataemail) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this user",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed === true) {
        deleteUser(dataemail);
      }
    })
  }

  return (
    <>
      {
        addedUsersList &&
        <section className='px-3 pt-3 p-4' style={{ height: '100%' }} >
          <Row style={{ alignItems: 'center', justifyContent: 'space-between' }} xxl={12} xl={12} lg={12} md={12}>
            {
              addedUsersList &&
              <>
                <Col className='' xxl={4} xl={4} lg={5} md={4}>
                  <InputGroup className="">
                    <Form.Control
                      placeholder='All users'
                      aria-label="Events"
                      aria-describedby="basic-addon1"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      onKeyDown={(e) => setSearchValue(e.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col xxl={1} xl={1} lg={2} md={1} className='text-center'>
                  <DropdownButton id="dropdown-basic-button" title={perPage} size="sm">
                    <Dropdown.Item onClick={() => setPerPage(10)} >10</Dropdown.Item>
                    <Dropdown.Item onClick={() => setPerPage(25)} >25</Dropdown.Item>
                    <Dropdown.Item onClick={() => setPerPage(50)} >50</Dropdown.Item>
                  </DropdownButton>
                </Col>
                <Col className='text-end px-4' xxl={3} xl={3} lg={3} md={4}>
                  <Button variant="primary" style={{ height: '2.3rem' }} onClick={() => addUser()}>Add User</Button>
                </Col>
              </>
            }
          </Row>
          {
            addedUsersList &&
            <div className='tableAllUserClass'>
              {
                addedUsersList.length > 0 ?
                  <Table hover striped size="sm" className='table-responsive'>
                    <thead>
                      <tr>
                        <th className='' >Sr No</th>
                        <th className='' >Name</th>
                        <th className='' >Email</th>
                        <th className=''>Role</th>
                        <th className=' text-center'> Edit</th>
                        {permissions.includes('Delete User') &&
                          <th className='text-center'> Delete</th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        addedUsersList.map((item, index) => {
                          return (
                            <tr key={index} className='' role='button' >
                              <td>{index + 1}</td>
                              <td className='' onClick={() => openModelUserInfo(item.email)}>
                                <div className='d-flex align-items-center'>
                                  <img role='button' src={image.porfilePicture} alt='logo' style={{ height: '2rem', width: '2rem', borderRadius: '50%', marginRight: '1em' }} />
                                  <span className=''>{item.role === 'ENTERPRISE' ? item.company_name : ((item.role === 'CO-ADMIN' || item.role === 'GUEST') ? item.full_name : '')}</span>
                                </div>
                              </td>
                              <td className='' onClick={() => openModelUserInfo(item.email)}>{item.email}</td>
                              <td className='' onClick={() => openModelUserInfo(item.email)}>{item.role}</td>
                              <td className='editIcon text-center' onClick={() => navigateToEditUser(item)}>
                                <AiIcons.AiFillEdit />
                              </td>
                              {permissions.includes('Delete User') &&
                                <td className='text-center' style={{ color: 'red' }} onClick={() => deleteUserSwal(item.email)}>
                                  <AiIcons.AiFillDelete />
                                </td>
                              }
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table> :
                  <h3 style={{ textAlign: 'center', marginTop: '2rem', color: 'steelblue' }}>
                    No Data available
                  </h3>
              }
              {
                pageData.total_page > 1 && (
                  <ReactPaginate
                    className='pagination'
                    breakLabel= {'...'}
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    pageCount={pageData.total_page}
                    previousLabel="< prev"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    activeClassName={'active'}
                    pageClassName = {'page-item'}
                    previousClassName = {'page-item'}
                    previousLinkClassName = {'page-link'}
                    pageLinkClassName = {'page-link'}
                    nextClassName = {'page-item'}
                    nextLinkClassName = { 'page-link'}
                    breakClassName = {'page-item'}
                    breakLinkClassName = {'page-link'}
                  />
                )
              }
            </div>

          }
        </section>

      }
      <Modal
        show={userModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='justify-content-between'
      >
        <AddUserManagementModal closeModal={closeModal} getUsersData={getUsersData} />
      </Modal>

      <Modal
        {...profileDetails}
        show={infoModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
            Account Summary
          </Modal.Title>
          <CloseButton onClick={closeModel} />
        </Modal.Header>
        <Modal.Body>
          <section>
            <Row>
              <Col xxl={4} xl={4} lg={5} md={5} style={{ borderRight: '1px solid #b2cedb' }}>
                <div style={{ textAlign: 'center' }}>
                  {
                    profileDetails?.profile_picture === null ?
                      <img src={image.porfilePicture} alt='logo' style={{ height: '8rem', width: '8rem', borderRadius: '50%', marginRight: '1em' }} />
                      : <img src={profileDetails.company_logo} alt='logo' style={{ height: '8rem', width: '8rem', borderRadius: '50%', marginRight: '1em' }} />
                  }
                </div>
                {profileDetails.company_name && <div className='profileDetails' style={{ marginTop: '1.5em', textAlign: 'center' }}>
                  <span>Company name</span>
                  {
                    profileDetails.company_name !== null && profileDetails.company_name !== ''?
                    <h6 className='m-0'>{profileDetails.company_name}</h6>
                    : <h6 className='m-0'>Not available</h6>
                  }
                </div>}
              </Col>
              <Col xxl={7} xl={7} lg={7} md={7}>
                <div className='d-flex my-2'>
                    <Col className='profileDetails'>
                    <span>Phone number</span>
                    {
                      profileDetails.phone !== null && profileDetails.phone !== '' ?
                      <h6 className='m-0'>{profileDetails.phone}</h6>
                      :<h6 className='m-0'>Not available</h6>
                    }
                    </Col>
                  <Col className='profileDetails'>
                    <span>Role</span>
                    {
                      profileDetails.role !== null && profileDetails.role !== '' ?
                      <h6 className='m-0'>{profileDetails.role}</h6>
                      :<h6 className='m-0'>Not available</h6>
                    }
                  </Col>
                </div>
                <div className='d-flex'>
                  {profileDetails.full_name && <Col className='profileDetails'>
                    <div className='profileDetails'>
                      <span>Full Name</span>
                      {
                        profileDetails.full_name !== null && profileDetails.full_name !== '' ?
                        <h6 className='m-0'>{profileDetails.full_name}</h6>
                        :<h6 className='m-0'>Not available</h6>
                       }
                    </div>
                  </Col>}
                  <Col className='profileDetails'>
                    <div className='profileDetails'>
                      <span>Email Address</span>
                      {
                        profileDetails.email !== null && profileDetails.email !== '' ?
                        <h6 className='m-0'>{profileDetails.email}</h6>
                        :<h6 className='m-0'>Not available</h6>
                      }
                    </div>
                  </Col>
                </div>
                {profileDetails.role === 'ENTERPRISE' && <div>
                  <hr />
                  <h5>Contact person details</h5>
                  <div className='d-flex'>
                    <Col className='profileDetails'>
                      <div className='profileDetails'>
                        <span>Contact person name</span>
                        {
                          profileDetails.contact_person_name !== null && profileDetails.contact_person_name !== '' ?
                          <h6 className='m-0'>{profileDetails.contact_person_name}</h6>
                          :<h6 className='m-0'>Not available</h6>
                        }
                      </div>
                    </Col>
                    <Col className='profileDetails'>
                      <div className='profileDetails'>
                        <span>Email Address</span>
                        {
                          profileDetails.contact_person_email !== null && profileDetails.contact_person_email !== '' ?
                          <h6 className='m-0'>{profileDetails.contact_person_email}</h6>
                          :<h6 className='m-0'>Not available</h6>
                        }
                      </div>
                    </Col>

                  </div>
                  <div className='d-flex'>
                    <Col className='profileDetails'>
                      <div className='profileDetails'>
                        <span>Full Name</span>
                        {
                          profileDetails.contact_person_designation !== null && profileDetails.contact_person_designation !== '' ?
                          <h6 className='m-0'>{profileDetails.contact_person_designation}</h6>
                          :<h6 className='m-0'>Not available</h6>
                        }
                      </div>
                    </Col>
                  </div>
                </div>}
              </Col>
            </Row>
          </section>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    </>

  )
}

export default AllUsers
import { createReducer } from "@reduxjs/toolkit";

export const getUpdatedProfile = createReducer({isLoadImage:false,profileUrl:{}},{
    getImage: (state,action) => {
        state.isLoadImage = true;
    },
    notGetImage: (state,) => {
        state.isLoadImage = false;
    },
    profileImage : (state,action) => {
        state.profileUrl = action;
    },
    clearProfileImage : (state,action) => {
        state.profileUrl = {};
    },
})
import React, { useState, useRef, useEffect } from 'react'
import { Row, Col, CloseButton } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import LeftSideBar from '../../SideBar/LeftSideBar'
import Button from 'react-bootstrap/Button';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import './Events.css';
import { MultiSelect } from "react-multi-select-component";
import { apiCall } from '../../../../action/loginAction';
import { compressionImage } from '../../../../constant/compressImage';
const CreateEvents = () => {
    var navigate = useNavigate();
    const dispatch = useDispatch();
    const [speakerlist, setSpeakerList] = useState([])
    const email = localStorage.getItem('email');
    const [value, onChange] = useState([new Date(), new Date()]);
    const [secondarySpeciality, setSecondarySpeciality] = useState([]);
    const [registrationPageData, setRegistrationPageData] = useState([]);
    //event data
    const [startDateTime, setStartDateTime] = useState('');
    const [endDateTime, setEndDateTime] = useState('')
    const [eventMode, setEventMode] = useState(null)
    const [bannerImage, setBannerImage] = useState({ fileName: '', media_id: '' });
    const [specialitiesList, setSpecialitiesList] = useState([]);
    const [topicTagList, setTopicTagList] = useState([]);
    const [topicTag, setTopicTag] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [uploadImgUrl, setUploadImgUrl] = useState('')
    const [eventData, setEventData] = useState({
        title: null,
        banner_image_id: null,
        speaker_names: [],
        location: null,
        description: null,
        ticket_required: 'free',
        fees: 0,
        language: null,
        registration_link: null,
        joining_link: null,
        organizer: null
    })
    const [isUploadText, setIsUploadText] = useState('');
    // on date value change
    useEffect(() => {
        if (value !== null) {
            let startDateTime = new Date(value[0]);
            let startDate = formatDateTime(startDateTime);
            setStartDateTime(startDate)
            let endDateTime = new Date(value[1]);
            let endDate = formatDateTime(endDateTime);
            setEndDateTime(endDate)
        }
    }, [value]);
    // get data
    useEffect(() => {
        getRegistrationPageData();
        getTopicTagData();
    }, [])
    //formate data
    const formatDateTime = (date) => {
        let  newDate = date.toISOString();
        return newDate;
    }
    const addTagsToSpeakerList = () => {
        setSpeakerList([...speakerlist, isUploadText])
        setIsUploadText('')
    }
    const removeSpeaker = (index) => {
        setSpeakerList(speakerlist.filter((el, i) => i !== index))
    }
    // changeKeyName
    const getNewTopicTagList = (list) => {
        let newSpecArray = [];
        for (let i = 0; i < list.length; i++) {
            newSpecArray.push({ 'name': list[i].label, 'topic_tag_id': list[i].value })
        }
        return newSpecArray
    }
    const getSpecialityList = (list) => {
        let newSpecArray = [];
        for (let i = 0; i < list.length; i++) {
            newSpecArray.push({ 'label_name': list[i].label, 'identifier_id': list[i].value })
        }
        return newSpecArray
    }
    //createEvent 
    const createEvent = async () => {
        if (eventData.title !== '' && eventData.title !== null && eventData.language !== null && bannerImage.media_id !== null && value !== null && eventData.description !== null &&
            speakerlist.length !== 0 && eventData.location !== '' && eventData.location !== null && eventMode !== null && eventData.language !== null &&
            eventData.language !== '' && eventData.joining_link !== '' && eventData.joining_link !== null && startDateTime !== null && startDateTime !=='' && endDateTime !==null && endDateTime !== '') {
            let specialityList = getSpecialityList(secondarySpeciality);
            let newTopicTagList = getNewTopicTagList(topicTag);
            var data = {
                setup_event: true,
                title: eventData.title,
                banner_image_id: bannerImage.media_id,
                description: eventData.description,
                speaker_names: speakerlist,
                location: eventData.location,
                mode: eventMode,
                language: eventData.language,
                speciality: specialityList,
                topic_tags: newTopicTagList,
                ticket_required: eventData.ticket_required === 'free' ? false : true,
                fees: eventData.fees,
                registration_link: eventData.registration_link,
                joining_link: eventData.joining_link,
                organizer: eventData.organizer,
                start_event_datetime:startDateTime,
                end_event_datetime:endDateTime
            };
            let res = await dispatch(apiCall(data,'/user/organize-event'));
            if (res.data.message.MSG_CODE === 100401) {
                Swal.fire({
                    icon: 'success',
                    text: 'Event has been created successfully',
                    timer: 3000
                })
                navigate("/admin/events");
                empty();
            }
            else if (res.data.message.MSG_CODE === 100500) {
                Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong please try again!',
                    timer: 4000
                })
            }
            else if (res.data.message.MSG_CODE === 100460) {
                Swal.fire({
                    icon: 'error',
                    text: 'Details entred in invalid formate!',
                    timer: 4000
                })
            } else if (res.data.message.MSG_CODE === 100010) {
                Swal.fire({
                    icon: 'warning',
                    text: 'Details not entered correctly please fill all given field',
                    timer: 5000
                })
            }
        } else {
            if (eventData.title === null || eventData.title === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please fill event name',
                    timer: 4000
                })
            } else if (eventData.description === null || eventData.description === '' || eventData.description === '<p><br></p>') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please fill description',
                    timer: 4000
                })
            } else if (eventData.location === null || eventData.location === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please fill location',
                    timer: 4000
                })
            } else if (eventData.joining_link === null || eventData.joining_link === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please fill joining link',
                    timer: 4000
                })
            }  
            else if (bannerImage.media_id === null || bannerImage.media_id === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please upload event image',
                    timer: 4000
                })
            } else if (eventMode === null || eventMode === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please select mode event',
                    timer: 4000
                })
            } else if (eventData.language === null || eventData.language === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please fill mode of communication',
                    timer: 4000
                })
            } else if (speakerlist.length === 0) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please add speaker name',
                    timer: 4000
                })
            } else if (value === null || value === '') {
                Swal.fire({
                    icon: 'error',
                    text: 'Please select respected date',
                    timer: 4000
                })
            }else if ( startDateTime === null && startDateTime ==='' && endDateTime === null && endDateTime === '' ) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please select speciality',
                    timer: 4000
                })
            } 
        }

    }
    //empty all data
    const empty = () => {
        setSecondarySpeciality([]);
        setSpeakerList([]);
        setEventMode(null)
        setBannerImage({ fileName: '', media_id: '' });
        setEventData({
            title: null,
            banner_image_id: null,
            speaker_names: [],
            location: null,
            description: null,
            ticket_required: 'free',
            fees: null,
            language: null,
            registration_link: null,
            joining_link: null,
            organizer: null,
        })
        setTopicTag([])
    }
    //get all data 
    const getRegistrationPageData = async () => {
        var data = {
            preferred_language: 'EN',
        };
        let res = await dispatch(apiCall(data,'/user/get-registration-page-info'));
        if (res.data.message.MSG_CODE === 100281) {
            setRegistrationPageData(res.data.data)
            let newSpecArray = [];
            for (let i = 0; i < res.data.data.specialities.length; i++) {
                newSpecArray.push({ 'label': res.data.data.specialities[i].label_name, 'value': res.data.data.specialities[i].identifier_id })
            }
            setSpecialitiesList(newSpecArray)
        } else {
            Swal.fire({
                icon: 'error',
                text: 'Post Update Failure',
                timer: 3000
            })
        }
    }
    //get topic data 
    const getTopicTagData = async () => {
        var data = {
            get_topic_tags_list: true,
            paginated_result:false
        };
        let res = await dispatch(apiCall(data,'/user/topic-tags'));
        if (res.data.message.MSG_CODE === 100711) {
            let newTopicArray = [];
            for (let i = 0; i < res.data.data.length; i++) {
                newTopicArray.push({ 'label': res.data.data[i].name, 'value': res.data.data[i].topic_tag_id })
            }
            setTopicTagList(newTopicArray)
        }
        else {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please try again later',
                timer: 3000
            })
        }
    }
    // upload api
    const uploadImage = async (files,type) => {
        dispatch({ type: "showLoader"});
        let arrayFile = []
        for (let i = 0; i < files.length; i++) {
            if ( files[i].type === 'image/heif' || files[i].type === 'image/HEIF') {
                arrayFile.push(files[i]);
            }else{
                const image = await compressionImage(files[i]);
                arrayFile.push(image);  
            }
        }
        dispatch({ type: "hideLoader"});
        uploadFile(arrayFile[0]);
    };
    const uploadFile = async (e) => {
        setBannerImage({ fileName: e.name })
        const convertString = await convertBase64(e);
        const base64Array = convertString.split(",");
        const base64 = base64Array[1];
        var data = {
            email: email,
            file_path: "event",
            file_data: base64,
            file_name: e.name,
        };
        let res = await dispatch(apiCall(data,'/user/upload'));
        if (res.data.message.MSG_CODE === 100231) {
            setBannerImage({ media_id: res.data.data.media_id });
            setUploadImgUrl(res.data.data.url)
        } else if (res.data.message.MSG_CODE === 100270) {
            Swal.fire({
                icon: 'error',
                text: 'Not valid extension please upload again (accept only JPEG, PNG, JPG)',
                timer: 3000
            })
        } else if (res.data.message.MSG_CODE === 100580) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please upload again',
                timer: 3000
            })
        } else if (res.data.message.MSG_CODE === 100010) {
            Swal.fire({
                icon: 'warning',
                text: 'image uploading failure',
                timer: 5000
            })
        }
    }
    // base64 conversion
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = (() => {
                resolve(fileReader.result);
            });
            fileReader.onerror = ((error) => {
                reject(error)
            });
        });
    };
    // preview image
    const closeFilterModel = () => {
        navigate("/admin/events");
        empty();
    }
    // change height 
    const changeHeightTextArea = (id) => {
        setTimeout(() => {
            var textarea = document.querySelector(id);
            textarea.addEventListener("keydown", e => {
                let scroll_height = e.target.scrollHeight;
                textarea.style.height = `${scroll_height}px`;
            });
        }, 10);
    }
    return (
        <>
            <section>
                <Row style={{ height: '100vh' }}>
                    <Col className='' xxl={2} xl={2} lg={3} md={3} style={{ background: 'linear-gradient(90deg, #277AFC 0%, #27B3FC 100%)', height: '100%' }} >
                        <LeftSideBar />
                    </Col>
                    <Col className='bg-prime p-0 eventScreen' xxl={10} xl={10} lg={9} md={9}>
                        <Container className='log-wrap p-0 d-flex justify-content-center eventScreenPage' fluid style={{ height: '100vh' }}>
                            <>
                                <section className='w-75'>
                                    <div className='eventScreenHeading'>
                                        <h5>Create Event</h5>
                                    </div>
                                    <div className='eventScreenBox'>
                                        <div style={{ marginLeft: "95%" }}>


                                            <CloseButton onClick={() => { closeFilterModel() }} />
                                        </div>
                                        <div className='my-1'>

                                            <p>
                                                Title <span className="redStar">&#42;</span>
                                            </p>
                                            <input type="text" className="form-control" placeholder="Title"
                                                aria-label="Title" aria-describedby="basic-addon1"
                                                value={eventData.title}
                                                onChange={(e) => setEventData({ ...eventData, title: e.target.value })}
                                            />
                                        </div>
                                        <div className='my-2'>
                                            <p>
                                                Description <span className="redStar">&#42;</span>
                                            </p>
                                            <textarea
                                                name="searchValue" className='channelDiscInputBox'
                                                id='channelDescriptionInputBox'
                                                rows="2" cols="200"
                                                placeholder="What's happening"
                                                value={eventData.description}
                                                onChange={(e) => setEventData({ ...eventData, description: e.target.value })}
                                                onKeyDown={changeHeightTextArea('#channelDescriptionInputBox')}
                                            >
                                            </textarea>
                                            {/* <ReactQuill theme="snow" 
                                            ref={editor}
                                            value={eventData.description}
                                            tabIndex={1}
                                            onChange={newContent => setEventData({...eventData,description:newContent})}
                                        /> */}
                                        </div >
                                        <div className='my-2'>
                                            <p>Location <span className="redStar">&#42;</span></p>
                                            <input type="text" className="form-control"
                                                placeholder="Location" aria-label="Title" aria-describedby="basic-addon1"
                                                onChange={(e) => setEventData({ ...eventData, location: e.target.value })}
                                            />
                                        </div>
                                        <div className='my-2'>
                                            <p>Registration link </p>
                                            <input type="url" className="form-control" placeholder="Registration link"
                                                aria-label="Registration link" aria-describedby="basic-addon1"
                                                onChange={(e) => setEventData({ ...eventData, registration_link: e.target.value })}
                                            />
                                        </div>
                                        <div className='my-2'>
                                            <p>Joining link <span className="redStar">&#42;</span></p>
                                            <input type="url" className="form-control" placeholder="Joining link"
                                                aria-label="Registration link" aria-describedby="basic-addon1"
                                                onChange={(e) => setEventData({ ...eventData, joining_link: e.target.value })}
                                            />
                                        </div>
                                        <div className='my-2'>
                                            <p>Add image (accept only jpeg and png ) <span className="redStar">&#42;</span> {uploadImgUrl !== '' ? <button onClick={() => { setModalShow(true) }} className="previewBtn"> Preview </button> : null}</p>
                                            <input type="file" className="form-control" aria-describedby="basic-addon1" accept="image/*" onChange={(event) => uploadImage(event.target.files)} />
                                        </div>

                                        <div className='my-2'>
                                            <p>Event mode <span className="redStar">&#42;</span></p>
                                            <div className='d-flex'>
                                                <input type="radio" className="" checked={eventMode === 'Online'} name='eventMode' value={'Online'} style={{ cursor: 'pointer' }} onChange={e => setEventMode(e.target.value)} />
                                                <label className="mx-2" >Online</label><br></br>
                                                <input className="mx-2" type="radio" checked={eventMode === 'Offline'} name='eventMode' value={'Offline'} style={{ cursor: 'pointer' }} onChange={e => setEventMode(e.target.value)} />
                                                <label className="mx-2" >Offline</label><br></br>
                                                <input className="mx-2" type="radio" checked={eventMode === 'Hybrid'} name='eventMode' value={'Hybrid'} style={{ cursor: 'pointer' }} onChange={e => setEventMode(e.target.value)} />
                                                <label className="mx-2" >Hybrid</label><br></br>
                                            </div>
                                        </div>
                                        <div className='my-2'>
                                            <p>Date and time <span className="redStar">&#42;</span></p>
                                            <DateTimeRangePicker onChange={onChange} value={value} />
                                        </div>
                                        <div className='my-2'>
                                            <p>Mode of communication <span className="redStar">&#42;</span></p>
                                            <input type="text" className="form-control" aria-describedby="basic-addon1" placeholder="Language"
                                                onChange={e => setEventData({ ...eventData, language: e.target.value })}
                                            />
                                        </div>
                                        <div className='my-2'>
                                            <p>Organizer  </p>
                                            <input type="text" className="form-control" aria-describedby="basic-addon1" placeholder="Organizer"
                                                onChange={e => setEventData({ ...eventData, organizer: e.target.value })}
                                            />
                                        </div>
                                        <div className='my-2'>
                                            <h6> Specialties </h6>
                                            {
                                                registrationPageData.specialities !== undefined ?
                                                    <>
                                                        <MultiSelect
                                                            options={specialitiesList}
                                                            value={secondarySpeciality}
                                                            onChange={setSecondarySpeciality}
                                                            labelledBy="Select"
                                                        />
                                                    </> : null
                                            }
                                        </div>
                                        <div className='my-2'>
                                            <h6> Topic tags </h6>
                                            <MultiSelect
                                                options={topicTagList}
                                                value={topicTag}
                                                onChange={setTopicTag}
                                                labelledBy="Select"
                                            />
                                        </div>
                                        <div className='my-1'>
                                            <p className='m-0'>Speaker name <span className="redStar">&#42;</span> (please press enter to add speaker name in list) </p>
                                            {
                                                speakerlist.map((tag, index) => (
                                                    <div className='tagsItem' key={index}>
                                                        <span className='text'>{tag}</span>
                                                        <span className='close' onClick={() => { removeSpeaker(index) }}> &#x2717;</span>
                                                    </div>
                                                ))
                                            }
                                                <textarea
                                                    name="searchValue" className='postInputBox'
                                                    id='speakerInputBox'
                                                    rows="2" cols="200"
                                                    placeholder="Speaker name"
                                                    value={isUploadText}
                                                    onChange={(e) => setIsUploadText(e.target.value)}
                                                    onKeyDown={changeHeightTextArea('#speakerInputBox')}
                                                >
                                                </textarea>
                                                <Button onClick={() => {addTagsToSpeakerList()}}>Add</Button>
                                                
                                            {/* <input type="text" onKeyDown={addTagsToSpeakerList} className="form-control my-2" aria-describedby="basic-addon1" placeholder="speaker name" /> */}
                                        </div>
                                        <div className='my-2'>
                                            <p>Type of event <span className="redStar">&#42;</span> </p>
                                            <div className='d-flex'>
                                                <input type="radio" checked={eventData.ticket_required === 'free'} className="" name='typeEvent' value={'free'} style={{ cursor: 'pointer' }} onChange={e => setEventData({ ...eventData, ticket_required: e.target.value })} />
                                                <label className="mx-2" >free</label><br></br>
                                                <input className="mx-2" checked={eventData.ticket_required === 'paid'} type="radio" name='typeEvent' value={'paid'} style={{ cursor: 'pointer' }} onChange={e => setEventData({ ...eventData, ticket_required: e.target.value })} />
                                                <label className="mx-2" >paid</label><br></br>
                                            </div>
                                        </div>
                                        {
                                            eventData.ticket_required === 'paid' ?
                                                <div className='my-2'>
                                                    <p>Ticket price <span className="redStar">&#42;</span> </p>
                                                    <input type="number" className="form-control"
                                                        aria-describedby="basic-addon1"
                                                        placeholder="Price"
                                                        onChange={(e) => setEventData({ ...eventData, fees: e.target.value })}
                                                    />
                                                </div> : null
                                        }
                                        <div className='my-3' style={{ float: 'right' }}>
                                            <Button className='mx-2' onClick={closeFilterModel} style={{ background: 'white', color: 'blue' }}>Cancel</Button>
                                            <Button onClick={() => createEvent()}>Submit</Button>
                                        </div>
                                    </div>
                                </section>
                            </>
                        </Container>
                    </Col>
                </Row>
            </section>
            <Modal
                show={modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='justify-content-between p-0'
            >
                <div className='p-0 text-end'>
                    <CloseButton onClick={() => setModalShow(false)} />
                </div>
                <Modal.Body>
                    <img className='previewImageModal' src={uploadImgUrl} alt='bannerImage' />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateEvents
import React  from 'react';
import Navigation from './components/Navbar/Navigation';
import {Route,Routes,Navigate} from "react-router-dom";
import Login from './components/Login/Login';
import ForgotPassword from './components/Login/ForgotPassword';
import ResetPassword from './components/Login/Resetpassword';
import Profile from './components/Profile/Profile';
import RedirectPost from './components/RedirectPost/RedirectPost';
import image from './constant/image';
import './App.css'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import AdminPost from './components/AdminPanel/RightSide/Pages/AdminPost';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import PrivacyPolicyVt from './components/PrivacyPolicy/PrivacyPolicyVt';
import ImageTest from './components/ImageTest';
import ImagePagination from './components/ImagePagination';
const App = () => {
   const dispatch = useDispatch();
   const { isLoaderOn } = useSelector(state => state.loader);
   dispatch({ type: "getImage" });
  return (
      <div className='App'>
         {
            isLoaderOn === true ?
            <img src = {image.loaderView} alt ='loader' id='spinnerClass'/>:null
         }
         <Routes>
            <Route path="/" element = {<Login/>}/>
            <Route path="/forgotpassword" element={<ForgotPassword/>} />
            <Route path="/resetpassword" element={<ResetPassword/>} />  
            <Route path="/profiledetails" element={<Profile/>} />   
            <Route path="admin/*" element={<Navigation/>} />  
            <Route path="/redirectpost" element={<RedirectPost/>} />
            <Route path= "privacypolicy" element = {<PrivacyPolicy/>}/>
            <Route path= "privacypolicyvt" element = {<PrivacyPolicyVt/>}/>
            {/* <Route path= "imageTest" element = {<ImageTest/>}/>
            <Route path= "imagePagination" element = {<ImagePagination/>}/> */}
            <Route path="*" element = {<AdminPost/>} />

         </Routes>
      </div>
  );
}

export default App 
import React from 'react'

const navBar = () => {
  return (
    <div className='d-flex  align-items-center'>
      <strong className='m-0 py-1'>Home</strong>
      <p className='m-0 py-1'>/Overview</p>
    </div>
  )
}

export default navBar
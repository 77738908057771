import axios from 'axios'
// import env from '../constant/env'

class Api {
  defaultHeader(object) {
    Object.keys(object).forEach(key => {
      axios.defaults.headers.common[key] = object[key]
    })
  }

  GET(endpoint, params) {
    return new Promise((resolve) => {
      axios({
        method: 'GET',
        url: this.normalizePath(endpoint),
        params,
        headers: { 'Content-Type': 'application/json' },
        validateStatus: function (status) {
          return status !== 404
        }
      })
        .then((response) => {
          resolve({success: 0, data: response.data})
        })
        .catch((error) => {
          resolve({ success: 1, message: 'Oops!  Something went wrong' })
        })
    })
  }

  POST(endpoint, params, headers) {
    return new Promise((resolve) => {
      axios({
        method: 'post',
        url: this.normalizePath(endpoint),
        data: JSON.stringify(params),
        headers: { 'Content-Type': 'application/json', ...headers },
        validateStatus: function (status) {
          return status !== 404
        }
      })
        .then((response) => {
          resolve({success: 0, data: response.data})
        })
        .catch((error) => {
          console.log('POST error:', error)
          resolve({ success: 1, message: 'Oops!  Something went wrong' })
        })
    })
  }

  DELETE(endpoint, params, headers) {
    return new Promise((resolve) => {
      axios({
        method: 'delete',
        url: this.normalizePath(endpoint),
        data: JSON.stringify(params),
        headers: { 'Content-Type': 'application/json', ...headers },
        validateStatus: function (status) {
          return status !== 404
        }
      })
        .then((response) => {
          resolve({success: 0, data: response.data})
        })
        .catch((error) => {
          console.log('POST error:', error)
          resolve({ success: 1, message: 'Oops!  Something went wrong' })
        })
    })
  }

  PUT(endpoint, params, headers) {
    return new Promise((resolve) => {
      axios({
        method: 'put',
        url: this.normalizePath(endpoint),
        data: JSON.stringify(params),
        headers: { 'Content-Type': 'application/json', ...headers },
        validateStatus: function (status) {
          return status !== 404
        }
      })
        .then((response) => {
          resolve({success: 0, data: response.data})
        })
        .catch((error) => {
          console.log('POST error:', error)
          resolve({ success: 1, message: 'Oops!  Something went wrong' })
        })
    })
  }
  POSTFORMDATA(endpoint, params, headers) {
    return new Promise((resolve) => {
      axios({
        method: 'post',
        url: this.normalizePath(endpoint),
        data:params,
        headers: { 'Content-Type': 'multipart/form-data', ...headers },
        validateStatus: function (status) {
          return status !== 404
        }
      })
        .then((response) => {
          resolve({success: 0, data: response.data})
        })
        .catch((error) => {
          console.log('POST error:', error)
          resolve({ success: 1, message: 'Oops!  Something went wrong' })
        })
    })
  }
  POSTBLOBDATA(endpoint, params, headers) {
    return new Promise((resolve) => {
      axios({
        method: 'post',
        url: this.normalizePath(endpoint),
        data:params,
        headers: { 'Content-Type': 'multipart/form-data', ...headers },
        responseType: 'blob',
        validateStatus: function (status) {
          return status !== 404
        }
      })
        .then((response) => {
          resolve({success: 0, data: response})
        })
        .catch((error) => {
          console.log('POST error:', error)
          resolve({ success: 1, message: 'Oops!  Something went wrong' })
        })
    })
  }

  POSTSHARELINK(screen,uid){
    return new Promise((resolve) => {
      axios({
        method: 'post',
        url: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAiJRwqmL8FXBGh_-v8P9gDFx6q0pP_VfY',
        data: {
          dynamicLinkInfo: {
            domainUriPrefix: 'https://medisetter.page.link',
            link: `https://medisetter.com/${screen}/${uid}`,
            // link: `https://medisetter.com/${screen}/${uid}`,
            androidInfo: {
              androidPackageName: 'com.medisetter',
            },
            iosInfo: {
              iosBundleId: "com.medisetter.medisetterapp",
              iosAppStoreId: "6448918087"
            },
          },
        },
        headers: {
          'Content-Type': 'application/json',
        },
        validateStatus: function (status) {
          return status !== 404
        }
      })
        .then((response) => {
          //
          resolve({success: 0, data: response.data})
        })
        .catch((error) => {
          resolve({ success: 1, message: 'Oops!  Something went wrong' })
        })
    })
  }




  // POSTFORMDATA(endpoint, params, headers = {}) {
  //   return new Promise((resolve) => {
  //     const data = new FormData()
  //     if (params) {
  //       Object.keys(params).forEach(key => {
  //         data.append(key, params[key])
  //       })
  //     }
  //     console.log('data: ', data);
  //     axios({
  //       method: 'post',
  //       url: this.normalizePath(endpoint),
  //       data: data,
  //       headers: { 'Content-Type': 'multipart/form-data', ...headers },
  //       validateStatus: function (status) {
  //         return status !== 404
  //       }
  //     })
  //       .then((response) => {
  //         resolve(response.data)
  //       })
  //       .catch((error) => {
  //         console.log('POSTFORMDATA error', error)
  //         resolve({ success: 0, message: 'Oops!  Something is wrong' })
  //       })
  //   })
  // }

  normalizePath(endpoint) {
   return `${process.env.REACT_APP_BASE_URL}${endpoint}`
  }
}

export default new Api()


import React from 'react'
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';

import {UserActivityData} from './UserActivityData';
import './UserActivity.css'
const UserActivityTable = () => {
  return (
    <>
        <div className='p-0 adminTableData'>
            <Container className='log-wrap' fluid style={{height: '100%'}} >
                    <section style={{height: '100%',overflow:'auto'}}>
                        <Table hover striped size="sm" role= 'button' className='table-responsive'>
                            <thead>
                                <tr>
                                    <th>View</th>
                                    <th>Post name</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>      
                                {
                                UserActivityData.map((item, index)=>{
                                    return (
                                        <tr key = {index} className=''>
                                            <td className=''><span className='viewsOnPost'>{item.view}</span></td>
                                            <td>{item.postname}</td>
                                            <td>{item.status}</td>
                                        </tr>
                                    )
                                })                      
                                }
                            </tbody>
                        </Table>   
                    </section>     
            </Container>
        </div>            
    </>
  )
}

export default UserActivityTable
import React, { useEffect, useState, useRef } from 'react';
import LeftSideBar from '../../../SideBar/LeftSideBar';
import { Row, Col, Container } from 'react-bootstrap'
import './PostAttributes.css';
import { useDispatch } from 'react-redux';
import { apiCall} from '../../../../../action/loginAction';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2';
const CreateAttributes = (props) => {
  const dispatch = useDispatch();
  var navigate = useNavigate();
  const email = localStorage.getItem('email');
  const [isUploadText, setIsUploadText] = useState('');
  const [title, setTitle] = useState('');
  const [bannerImage, setBannerImage] = useState({ fileName: null, media_id: null });
  const editor = useRef(null);
	const [content, setContent] = useState(null);
  const [attributeType, setAttributeType] = useState("Link")
  const [link, setLink] = useState(null)

  const checkFofLinkAndImage = async () => {
    if ( attributeType === "Link" &&  (link === '' || link === null)){
      Swal.fire({
          icon: 'error',
          text: 'Please fill the link',
          timer: 4000
      })
      return false
    }
    else if ( bannerImage.media_id === null ||  bannerImage.media_id=== ''){
      Swal.fire({
          icon: 'error',
          text: 'Please upload image',
          timer: 4000
      })
      return false
    }
    return true
  }
  const createAttribute = async(pg) =>{
    if ( attributeType === "Link" &&  (link === '' || link === null)){
      Swal.fire({
          icon: 'error',
          text: 'Please fill the link',
          timer: 4000
      })
      return 
    }
    else if ( bannerImage.media_id === null ||  bannerImage.media_id=== ''){
      Swal.fire({
          icon: 'error',
          text: 'Please upload image',
          timer: 4000
      })
      return 
    }else{
      if (isUploadText !== '' &&  title !== '' && bannerImage.media_id !== '') {
        var data = {
          is_create: true,
          category_description:isUploadText,
          category_title:title,
          category_image:bannerImage.media_id,
          content_language:'EN',
          category_type : attributeType == "Link" ? 'LINK' : 'CATEGORY',
          url : link
        };
        let res = await dispatch(apiCall(data,'/user/admin-category'));
        if (res.data.message.MSG_CODE === 100651) {
          navigate("/admin/attributes");
          empty();
        }else if (res.data.message.MSG_CODE === 100850){
            Swal.fire({
              icon: 'error',
              text: 'Attribute title should be unique',
              timer: 3000
          })
        }
        else {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please try again later',
                timer: 3000
            })
        }
      }else{
        if (title === null ||title === '' ) {
          Swal.fire({
              icon: 'error',
              text: 'Please fill attributes title',
              timer: 4000
          })
        }else if ( isUploadText === null ||  isUploadText === ''){
            Swal.fire({
                icon: 'error',
                text: 'Please fill description',
                timer: 4000
            })
        }
        else if ( attributeType === "Link" &&  link === ''){
          Swal.fire({
              icon: 'error',
              text: 'Please fill the link',
              timer: 4000
          })
        }
        else if ( bannerImage.media_id === null ||  bannerImage.media_id=== ''){
          Swal.fire({
              icon: 'error',
              text: 'Please upload event image',
              timer: 4000
          })
        }
      }
    }
    
  }
      // change height 
    const changeHeightTextArea = (id) => {
      setTimeout(() => {
          var textarea = document.querySelector(id);
          textarea.addEventListener("keydown", e => {
              let scroll_height = e.target.scrollHeight;
              textarea.style.height = `${scroll_height}px`;
          });
      }, 10);
  }
  const empty = () => {
    setBannerImage({ fileName : '', media_id: ''});
    setTitle('')
    setContent(null)
  }
  // upload api
  const uploadFile = async (e) => {
    setBannerImage({ fileName: e.name })
    const convertString = await convertBase64(e);
    const base64Array = convertString.split(",");
    const base64 = base64Array[1];
    var data = {
      email: email,
      file_path: "category",
      file_data: base64,
      file_name: e.name,
    };
    let res = await dispatch(apiCall(data,'/user/upload'));
    if (res.data.message.MSG_CODE === 100231) {
      setBannerImage({ media_id: res.data.data.media_id });
    } else if (res.data.message.MSG_CODE === 100580) {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please upload again',
        timer: 3000
      })
    }else if ( res.data.message.MSG_CODE === 100270 ){
      Swal.fire({
          icon: 'error',
          text: 'Not valid extension please upload again (accept only JPEG, PNG, JPG)',
          timer: 3000
      })
  } else if (res.data.message.MSG_CODE === 100010) {
      Swal.fire({
        icon: 'warning',
        text: 'image uploading failure',
        timer: 5000
      })
    }
  }
  // base64 conversion
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = (() => {
        resolve(fileReader.result);
      });
      fileReader.onerror = ((error) => {
        reject(error)
      });
    });
  };
  // redirect attribute page
  const closeCreateAttribute = () => {
    navigate("/admin/attributes");
    empty();
  }
  const onOptionChange = e => {
    console.log("catagory" , e.target.value)
    setAttributeType(e.target.value)
  }
  
  //pagination
  return (
    <>
      <section>
        <Row style={{ height: '100vh' }}>
          <Col className='' xxl={3} xl={3} lg={4} md={4} style={{ background: 'linear-gradient(90deg, #277AFC 0%, #27B3FC 100%)', height: '100%' }} >
            <LeftSideBar />
          </Col>
          <Col className='bg-prime p-0 eventScreen' xxl={9} xl={9} lg={9} md={8} >
            <Container className='log-wrap p-0 d-flex justify-content-center eventScreenPage' fluid style={{ height: '100vh' }}>
              <section className='w-75' >
                <div className='eventScreenHeading'>
                  <h4>Create Attributes / Links</h4>
                </div>
                <div className='eventScreenBox' style ={{height:'auto'}}>
                  <div className='my-1'>
                    <p>
                      Title
                    </p>
                    <input type="text" className="form-control" placeholder="Title"
                      aria-label="Title" aria-describedby="basic-addon1"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className='my-2'>
                    <p>
                      Description
                    </p>
                    <textarea
                        name="searchValue" className='postInputBox'
                        id='postTextInputBox'
                        rows="2" cols="200"
                        placeholder="What's happening"
                        value={isUploadText}
                        onChange={(e) => setIsUploadText(e.target.value)}
                        onKeyDown={changeHeightTextArea('#postTextInputBox')}
                    >
                    </textarea>
                  </div >
                  <div className='my-2'>
                    <p>Attributes Type </p>
                    <div className='mt-1'>
                    <input
                          
                          type="radio"
                          name="Attribute"
                          value="Catagory"
                          id="Catagory"
                          checked={attributeType === "Catagory"}
                          onChange={onOptionChange}
                        />
                        <label style={{marginLeft : '10px' , marginRight : '30px'}} htmlFor="regular">Catagory</label>

                        <input
                          type="radio"
                          name="Attribute"
                          value="Link"
                          id="Link"
                          checked={attributeType === "Link"}
                          onChange={onOptionChange}
                        />
                       
                        <label style={{marginLeft : '10px'}} htmlFor="medium">Link</label>
                        </div>
                        {
                          attributeType === "Link" ? 
                          <input type="text" className="form-control mt-2" placeholder="Link"
                      aria-label="Link" aria-describedby="basic-addon1"
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                    />
                          : null
                        }
                  </div>
                  <div className='my-2'>
                    <p>Banner image (jpeg and png )</p>
                    <input type="file" accept="image/png, image/jpeg" onChange={(event) => uploadFile(event.target.files[0])} />
                  </div>
                  <div className='my-4 text-end'>
                    <Button variant="primary" className='mx-2' style={{ height: '2.3rem' }} onClick={() => { createAttribute() }}>Create</Button>
                    <Button style={{ background: 'white', color: 'blue',height: '2.3rem' }} onClick={() => { closeCreateAttribute() }}>Cancel</Button>
                  </div>
                </div>
              </section>
            </Container>
          </Col>
        </Row>
      </section>
    </>
  )
}

export default CreateAttributes
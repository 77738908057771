import React, { useState, useEffect } from 'react';
import {getShareLink} from '../../constant/getShareLink'
import './RedirectPost.css'
const RedirectPost = () => {
    const queryParameters = new URLSearchParams(window.location.search)
    const url = queryParameters.get("url")
    const [text , setText] = useState('')
    useEffect(() => {
        localStorage.clear();
        generateLink()
    }, [])
    const generateLink  = async ()=>{
        //For anddoird
       let details = navigator.userAgent;     
      // let newURL = url.replace('https:','')
         setText(details)
         if(details.includes('Android')||details.includes('android')){
        if (details.indexOf("FBAN") > -1 || details.indexOf("FBAV") > -1) {
            //For facebook browser
          window.location.href = `intent://${url}#Intent;scheme=https;action=android.intent.action.VIEW;end;` 
         
        }else{
            //For facebook browser other than facebook
          window.location.href = `https://${url}#Intent;scheme=https;action=android.intent.action.VIEW;end;` 
        }
         }else{
            //For iOS
            window.location = url
        }
    }
   
    return (
        <>
        <div className='mainDiv'>
            {/* <h1>DEPLOYMENT NUMBER 34</h1>
            <br/>
            <h2 id='checking'></h2> */}
        {/* <div className="loader-2"></div> */}
        </div>
        </>
    )
}

export default RedirectPost
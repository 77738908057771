import React, { useState, useEffect } from 'react';
import './AllUserFeedData.css'
import InfiniteScroll from "react-infinite-scroll-component";
import MediaGrid from '../MediaGrid';
import Swal from 'sweetalert2';
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs'
import image from '../../../../../constant/image';
import Badge from 'react-bootstrap/Badge';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { apiCall } from '../../../../../action/loginAction';
import useDebounce from '../../../../../constant/useDebouncing';
import { useNavigate } from "react-router-dom";
import selectedTab from  "../../../../../GlobalFunctions/global"
import ReactPaginate from 'react-paginate';
const SearchPostData = (props) => {
  const dispatch = useDispatch();
  const { isCallData } = useSelector(state => state.channelData);
  const { searchFeedData, feedPermissions ,selectedTabId} = useSelector(state => state.feedData);
  const [isDeletePost, setIsDeletePost] = useState({ postID: '', indexOfPost: '' });
  const [searchedValue, setSearchedValue] = useState(props.searchedValue)
  const [feedData, setFeedData] = useState();
  const [mideaList, setMideaList] = useState([]);
  const [page, setPage] = useState(1);
  const [isSharePost, setIsSharePost] = useState(false);
  const [isShowAdminPost, setIsShowAdminPost] = useState('');
  const [permissions, setPermissions] = useState(props.permissions)
  const email = localStorage.getItem('email');
  const role = localStorage.getItem('role');
  const [searchPost, setSearchPost] = useState('');
  const [searchPostData, setSearchPostData] = useState([]);
  const debouceSearchTerm = useDebounce(searchPost, 300);
  const [isMoreData, setIsMoreData] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [newFeedData , setNewFeedData] = useState();
  const [isDataLoading , setIsDataLoading] = useState(false);

  
  const navigate = useNavigate();
  useEffect(() => {
    console.log("use effect called");
    getFeedData(1);
  }, []);

  // useEffect(() => {
  //   setFeedData(searchFeedData.feed)
  // }, [searchFeedData])
      //delete post
  useEffect(() => {
        if (isDeletePost.postID !== '') {
            deletePostinAdminPost(isDeletePost)
        }
    }, [isDeletePost])
  //   const getFeedlData = async (pg) => {

  //     var data = {
  //         get_general_feed: true,
  //         per_page: 10,
  //         page_number: pg,
  //         preferred_language: 'EN',
  //         email,
  //         role,
  //     };
  //     let res = await dispatch(apiCall(data, '/user/admin-feed'));
  //     if (searchPost.trim().length === 0) {
  //         dispatch({ type: "clearFeedData" });
  //     }
  //     if (res.data.message.MSG_CODE === 100161) {
  //         setFeedData(res.data.data);
  //         setTotalPage(res.data.pagination.total_page);
  //         if (res.data.pagination.total_page > 1) {
  //             setIsMoreData(true);
  //             setPage(pg + 1);
  //         }
  //     } else if (res.data.message.MSG_CODE === 100160) {
  //         Swal.fire({
  //             icon: 'error',
  //             text: 'Post Update Failure',
  //             timer: 3000
  //         })
  //     }
  // }
      // search post channel admin 
      const getSearchAdminData = async (pg, searchValue) => {
        var data = {
            get_all: true,
            search_data: searchedValue,
            per_page: 7,
            preferred_language: 'EN',
            page_number: pg
        }
        let res = await dispatch(apiCall(data, '/user/search-all', true));
        if (isCallData === true) {
            dispatch({ type: "clearCallChannelData" });
        }
        if (res.data.message.MSG_CODE === 100891) {
            if (searchedValue.trim().length === 0) {
                dispatch({ type: "clearFeedData" });
            } else {
                dispatch({ type: "getFeedData", ...res.data });
                dispatch({ type: "getChannelFeedData", ...res.data });
            }
        }
        else {
          console.log("Something went, please try again later")
        }
    }
      // delete post 
      const deletePostinAdminPost = async (deletePost) => {
        var data = {
            post_id: deletePost.postID,
            is_delete: true,
        };
        let res = await dispatch(apiCall(data, '/user/post'));
        if (res.data.message.MSG_CODE === 100321) {
            setPage(1);
            setMideaList([])
            setIsDeletePost({ postID: '', indexOfPost: '' })
            getSearchAdminData(1)
        } else if (res.data.message.MSG_CODE === 100390 || res.data.message.MSG_CODE === 100170) {
            Swal.fire({
                icon: 'error',
                text: 'Post Update Failure',
                timer: 3000
            })
        }
    }
    const getFeedData = async (pg) => {
      var data = {
          get_post: true,
          search_data: searchedValue,
          per_page: 10,
          preferred_language: 'EN',
          page_number: pg
      }
      let res = await dispatch(apiCall(data, '/user/search-all', true));
      console.log("res" , res.data)
      if (isCallData === true) {
          dispatch({ type: "clearCallChannelData" });
      }
      if (res.data.message.MSG_CODE === 100161) {
          if (searchedValue.trim().length === 0) {
            setNewFeedData([])
          } else {
            setNewFeedData(res.data.data)
            setTotalPage(res.data.pagination.total_page);
            setIsDataLoading(false);
          }
      }
      else {
        console.log("Something went, please try again later")
      }
  }
  const getNewFeedData = async (pg) => {
    var data = {
        get_post: true,
        search_data: searchedValue,
        per_page: 10,
        preferred_language: 'EN',
        page_number: pg
    }
    let res = await dispatch(apiCall(data, '/user/search-all', true));
    if (isCallData === true) {
        dispatch({ type: "clearCallChannelData" });
    }
    if (res.data.message.MSG_CODE === 100161) {
        if (searchedValue.trim().length === 0) {
          setNewFeedData([])
        } else {
          setNewFeedData([...newFeedData , ...res.data.data])
          setTotalPage(res.data.pagination.total_page);
          setIsDataLoading(false);
        }
    }
    else {
      console.log("Something went, please try again later")
    }
}
  const onEndOfPage = () => {
    console.log("Search post data end of page called")
    console.log("activeTab " , selectedTab.selectedTab)
   if (totalPage >= page && selectedTab.selectedTab === "post"){
    setIsDataLoading(true)
    setPage(page + 1)
    getNewFeedData(page + 1)
   }
  } 
  const handlePageClick = (data) =>{
    setIsDataLoading(true)
    setNewFeedData([])
    let currentPage = data.selected + 1 ;
    console.log("data.selected",data.selected)
    getFeedData(currentPage)
    setPage(currentPage)
  }
  return (
    <>
      <div>
        {
          newFeedData && newFeedData.length  > 0 ?
            // <InfiniteScroll
            //   dataLength={newFeedData.length}
            //   style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
            //   hasMore={true}
            //   next={() => onEndOfPage()}
            //   scrollableTarget="scrollableDiv"
            // >
              <div className='adminFeedData'>
                {
                  newFeedData.map((feed, index3) => {
                    return (
                      <div key={index3}>
                        <MediaGrid feedData={feed} indexOfFeed={index3} deletePost={setIsDeletePost} sharePost={setIsSharePost} showAdminPost={setIsShowAdminPost} permissions={permissions} />
                      </div>
                    )
                  })
                }
              </div>
            // </InfiniteScroll> 
            : 
              isDataLoading ? null :
              <h5 style={{ textAlign: 'center', marginTop: '2rem', color: 'steelblue' }}>
              No Data available
            </h5>
            
            
        }
        <div className='mt-3 mb-3'>
        {
                          totalPage > 1 && (
                            <ReactPaginate
                              className='pagination m-0'
                              breakLabel= {'...'}
                              nextLabel="next >"
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={2}
                              marginPagesDisplayed={2}
                              pageCount={totalPage}
                              previousLabel="< prev"
                              renderOnZeroPageCount={null}
                              containerClassName="pagination"
                              activeClassName={'active'}
                              pageClassName = {'page-item'}
                              previousClassName = {'page-item'}
                              previousLinkClassName = {'page-link'}
                              pageLinkClassName = {'page-link'}
                              nextClassName = {'page-item'}
                              nextLinkClassName = { 'page-link'}
                              breakClassName = {'page-item'}
                              breakLinkClassName = {'page-link'}
                             />
                          )
                        }
                        </div>
      </div>
    </>
  )
}

export default SearchPostData
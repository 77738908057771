import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AllUserFeedData from './AllUserFeedData';
import SearchUsers from './SearchUsers';
import SearchChannels from './SearchChannels';
import SearchPostData from './SearchPostData';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import './searchUserFeed.css'
import selectedTab from  "../../../../../GlobalFunctions/global"

const SearchUserFeed = (props) => {
  const dispatch = useDispatch();
  const { searchFeedData, feedPermissions , selectedTabId } = useSelector(state => state.feedData);
  let [key , setKey] = useState('all post');
  let [feedData, setFeedData] = useState(props.feedData);
  const [permissions, setPermissions] = useState(props.permissions)
  const [searchedValue , setSearchedValue] = useState(props.searchedValue)
  let permissionsLocal = JSON.parse(localStorage.getItem('role_permission')).menu_permission
  const [toShow,setToShow] = useState(false)
  useEffect(()=>{
    let checkPermission = permissionsLocal.includes("Users")
    setToShow(checkPermission)
  },[])
  // useEffect(() => {
  //   console.log("new key set",key )
  //   selectedTab.selectedTab = key
  // }, [key])
  useEffect(()=>{
    feedData = JSON.parse(JSON.stringify(searchFeedData));

  },[searchFeedData])
  useEffect(() => {
      setKey(selectedTabId)
  },[selectedTabId])
  const onSelectPress = (key) => {
    dispatch({ type: "selectedTabkey",key});
  } 
  return (
    <>
      {
        <div className='searchAdminPostDiv'>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => onSelectPress(k)}
            className="mb-3"
            justify
          >
            <Tab eventKey="all post" title="All" className=''>
              <AllUserFeedData searchedValue={searchedValue} feedData={feedData} permissions={permissions} />
            </Tab>
            <Tab eventKey="post" title="Post" className=''>
              <SearchPostData searchedValue={searchedValue} feedData={feedData} permissions={permissions} />
            </Tab>
            {
              toShow && <Tab eventKey="user" title="Users" className=''>
              <SearchUsers feedData={feedData} permissions={permissions} searchedValue={searchedValue}/>
            </Tab>
            }
            <Tab eventKey="channels" title="Channels" className=''>
              <SearchChannels feedData={feedData} permissions={permissions} searchedValue={searchedValue}/>
            </Tab>

          </Tabs>
        </div>
      }
    </>
  )
}

export default SearchUserFeed
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import image from '../../constant/image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2'
import 'react-quill/dist/quill.snow.css';
import './Profile.css'
import CloseButton from 'react-bootstrap/CloseButton';
import Modal from 'react-bootstrap/Modal';
import { apiCall } from '../../action/loginAction';
import PhoneInput from 'react-phone-input-2'
const Profile = () => {
    const [modalShow, setModalShow] = useState(false);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [location, setLocation] = useState('');
    const [mobileNumber, setMobileNumber] = useState(null);
    const [content, setContent] = useState('');
    const dispatch = useDispatch();
    const [userData, setUserData] = useState({})
    const [toShow , setToShow] = useState(false)
    const [toShow404 , setToShow404] = useState(false)
    const [apiCalled , setApiCalled] = useState(false)
    const [lang , setLang] = useState([])
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    const queryParameters = new URLSearchParams(window.location.search)
    const user_id = queryParameters.get("user_id")
    const getLanguage = queryParameters.get("language")
    const lang_change = {
        'en':{
            'primary_speciality_label':'Primary specialty',
            'secondary_speciality_label':'Secondary specialty',
            'other_speciality_label':'Other specialty',
            'studied_label':'Studied',
            'from_label':'from',
            'works_at_label':'Works at',
            'province_city_label':'Province/ City',
            'district_label':'District',
            'members_of_label':'Members of',
            'about_label':'About',
            'connect_label':'Connect'
        },
        'vi':{
            'primary_speciality_label':'chuyên môn chính',
            'secondary_speciality_label':'chuyên khoa phụ',
            'other_speciality_label':'khác chuyên môn',
            'studied_label':'đã học',
            'from_label':'từ',
            'works_at_label':'Làm việc tại',
            'province_city_label':'Tỉnh/Thành phố',
            'district_label':'Huyện',
            'members_of_label':'Thành viên của',
            'about_label':'Về',
            'connect_label':'Kết nối'
        }
    }
    useEffect(() => {
        getAllLanguages()
        getUserData()
    }, [])
    // model operation
    const openModel = () => {
        setModalShow(true);
    }
    const closeModel = () => {
        setModalShow(false);
        empty();    
    }
    // get user data
    const getUserData = async () => {
        console.log()
        var data = {
            get_doctor_info: true,
            user_id: user_id,
            preferred_language: getLanguage.toUpperCase()
        }
        let res = await dispatch(apiCall(data,'/user/book-appointment'));
        setApiCalled(true);
        if (res.data.message.MSG_CODE === 100441) {
            setUserData(res.data.data)
            setToShow(true)
            setToShow404(false)

        }else if (res.data.message.MSG_CODE === 100020){
            setToShow(false)
            setToShow404(true)

        }else if (res.data.message.MSG_CODE === 100080){
            setToShow(false)
            setToShow404(true)

        }else if (res.data.message.MSG_CODE === 100520){
            setToShow(false)
            setToShow404(true)

        }else if (res.data.message.MSG_CODE === 100500){
            setToShow(false)
            setToShow404(true)
        }else if (res.data.message.MSG_CODE === 101070){
            setToShow(false)
            setToShow404(false)
        }
    }
    const getAllLanguages = async () => {
        var data = {
            get_all_languages: true,
        
        }
        let res = await dispatch(apiCall(data,'/user/get-languages'));
        if (res.data.message.MSG_CODE === 100211) {
            if(getLanguage.toUpperCase() === 'EN'){
                setLang(res.data.data[0].labels)
            }else{
                setLang(res.data.data[1].labels)
            }
        }else if (res.data.message.MSG_CODE === 100020){
            Swal.fire({
                icon: 'error',
                text: 'error',
                timer: 3000
            })
        }
    }
    const empty = () => {
        setFullName('');
        setEmail('');
        setLocation('')
        setMobileNumber(null);
        setContent('')
    }
    // request for appointment
    const appointmentBook = async () => {
        if (email.trim().length !==0 && fullName.trim().length !==0 && mobileNumber !==null 
            && location.trim().length !==0 && content.trim().length !==0 ) {
            if (!regex.test(email)){
                Swal.fire({
                    icon: 'error',
                    text: 'Enter a valid email address',
                    timer: 3000
                })
                return
            }
            var data = {
                patient_name: fullName,
                patient_email: email,
                phone: '+'+mobileNumber,
                location: location,
                description: content,
                book_appointment:true,
                user_id:user_id
            }
            let res = await dispatch(apiCall(data,'/user/book-appointment'));
            if (res.data.message.MSG_CODE === 100571) {
                Swal.fire({
                    icon: 'success',
                    text: 'Appointment has been booked successfully',
                    timer: 3000
                })
                closeModel();
                empty();
            } else if (res.data.message.MSG_CODE === 100010) {
                Swal.fire({
                    icon: 'error',
                    text: 'Details not entered correctly',
                    timer: 3000
                })
            } else if (res.data.message.MSG_CODE === 100020) {
                Swal.fire({
                    icon: 'error',
                    text: 'User does not exist',
                    timer: 3000
                })
            } else if (res.data.message.MSG_CODE === 100520) {
                Swal.fire({
                    icon: 'error',
                    text: 'This user is not a doctor',
                    timer: 3000
                })
            }
        }else{
            Swal.fire({
                icon: 'error',
                text: 'Please fill out all required fields',
                timer: 3000
            })
        }

    }
    // change height 
    const changeHeightTextArea = (id) => {
        setTimeout(() => {
            var textarea = document.querySelector(id);
            if (textarea !== null && textarea !== undefined) {

                textarea.addEventListener("keydown", e => {
                    let scroll_height = e.target.scrollHeight;
                    textarea.style.height = `${scroll_height}px`;
                });
            }
        }, 10);
    }
    return (
        <>
            <Modal
                show={modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='justify-content-between'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
                    {lang['Personal Details label']}
                    </Modal.Title>
                    <CloseButton onClick={closeModel} />
                </Modal.Header>
                <Modal.Body>
                    <section>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12}>
                                <div className=''>
                                    <div className='my-2'>
                                        <div className='my-1'>
                                            <p  className='m-1'>
                                            {lang['Full name label']} <span className="redStar">&#42;</span>
                                            </p>
                                            <input type="text" className="form-control"
                                                placeholder="full name" aria-label="Title"
                                                aria-describedby="basic-addon1"
                                                value={fullName}
                                                onChange={(name) => setFullName(name.target.value)}
                                            />
                                        </div>
                                        <div className='my-1'>
                                            <p  className='m-1'>
                                            {lang['Email label']} <span className="redStar">&#42;</span>
                                            </p>
                                            <input type="text" className="form-control"
                                                placeholder="email address" aria-label="Title"
                                                aria-describedby="basic-addon1"
                                                value={email}
                                                onChange={(name) => setEmail(name.target.value)}
                                            />
                                        </div>
                                        <div className='my-1'>
                                            <p  className='m-1'>
                                            {lang['Location label']} <span className="redStar">&#42;</span>
                                            </p>
                                            <input type="text" className="form-control"
                                                placeholder="Location" aria-label="Title"
                                                aria-describedby="basic-addon1"
                                                value={location}
                                                onChange={(name) => setLocation(name.target.value)}
                                            />
                                        </div>
                                        <div className='my-1'>
                                            <p  className='m-1'>
                                            {lang['Phone number label']} <span className="redStar">&#42;</span>
                                            </p>
                                            <PhoneInput
                                            className = 'w-100'
                                            country={'vn'}
                                            placeholder="Enter phone number"
                                            value={mobileNumber}
                                            onChange={setMobileNumber} />
                                        </div>
                                        <div className='my-1'>
                                            <p  className='m-1'>
                                            {lang['Description label']} <span className="redStar">&#42;</span>
                                            </p>
                                            <textarea
                                                name="searchValue" className='channelDiscInputBox'
                                                id='channelDescriptionInputBox1'
                                                rows="2" cols="200"
                                                placeholder="Description..."
                                                value={content}
                                                onChange={(e) => setContent(e.target.value)}
                                                onKeyUp={changeHeightTextArea('#channelDescriptionInputBox1')}
                                            >
                                            </textarea>
                                        </div>
                                        <div className='my-2' style={{ float: 'right' }}>
                                            <Button className='mx-2' onClick={closeModel} style={{ background: 'white', color: 'blue' }}>{lang['Close label']}</Button>
                                            <Button onClick={() => appointmentBook()}>{lang['Submit label']}</Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </Modal.Body>
            </Modal>
            {
                userData.full_name && toShow && apiCalled ?
               ( <div className='Profile'>
                    <Container className='log-wrap p-0' style={{ height: '100%' }}>
                        <Row style={{ height: '100%' }}>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} className='ProfileDetails p-0' >
                                <section className=' ProfileDetailsLogo py-2 my-3 d-flex  justify-content-start align-items-center' style={{ textAlign: 'centre' }} role='button'>
                                    <span className='px-2'><img src={image.appIconLogin} alt='logo' style={{ height: '2em', width: '2.3em' }} /></span>
                                    <span className='mx-3' style={{ color: '#277afb', fontSize: '1.6rem' }}>Medisetter</span>
                                </section>
                                <Col className='ProfilePicture' xxl={12} xl={12} lg={12} md={12} sm={12}>
                                   {userData.primary_speciality && <h1 className="banner-heading"> {userData.primary_speciality} </h1>}
                                    {
                                        userData.profile_picture !== null ?
                                            <img className='ProfilePictureImg' role='button' src={userData.profile_picture} alt='logo' style={{}} /> :
                                            <img className='ProfilePictureImg' role='button' src={image.porfilePicture} alt='logo' style={{}} />
                                    }
                                </Col>
                                <Col className='ProfileInfo p-3 pt-5 mt-3' xxl={12} xl={12} lg={12} md={12} sm={12}>
                                    <Row className='ProfileConnectNdMessage'>
                                        <Col xxl={7} xl={7} lg={7} md={7} sm={8}>
                                        </Col>
                                        <Col xxl={3} xl={3} lg={3} md={3} sm={3}>
                                            <div className='d-flex align-items-center justify-content-end'>
                                                {/* <Button variant="light" className='mx-2'>Message</Button> */}
                                                <Button className='mx-2' onClick={() => { openModel() }}>{lang_change[getLanguage]['connect_label']}</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='ProfileGenralInfo justify-content-between'>
                                        <Col xxl={7} xl={7} lg={7} md={10} sm={12}  className = 'ProfileGenralInfoDiv'>
                                            <div className='ProfileName' >
                                                <h4 className='mx-2'>{userData.full_name}</h4>
                                            </div>

                                            <div className=''>
                                                {
                                                    userData.primary_speciality &&
                                                    <>
                                                        <div className='EducationalInst d-flex'>
                                                            <img src={image.specailityImage} className='microSiteImage' alt='logo'/><div><span>{lang['Primary specialty label']} :</span><span><strong className='mx-1'> {userData.primary_speciality}</strong></span></div>
                                                        </div>
                                                    </>
                                                }
                                                {
                                                     userData.secondary_speciality &&
                                                    <div className='EducationalInst d-flex'>
                                                        <img src={image.specailityImage} alt='logo' className='microSiteImage'/><div><span>{lang['Secondary specialty label']} :</span><span><strong className='mx-1'> {userData.secondary_speciality}</strong></span></div>
                                                    </div>
                                                }
                                            </div>
                                            {
                                                userData.other_interests &&
                                                <>
                                                    {
                                                        userData.other_interests.length>0 &&
                                                        <div className='EducationalInst d-flex'>
                                                            <img src={image.specailityImage} className='microSiteImage' alt='logo' />
                                                            <div>{lang['other label']} {lang['Specialty label']} :
                                                                {
                                                                    userData.other_interests && userData.other_interests.map((intrest, index) => {
                                                                        return (
                                                                            <span key={index}> <strong> {intrest}</strong>{userData.other_interests.length-1 !== index &&<>,</>}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        }
                                                </>
                                            }
                                            {
                                                userData.educational_information[0].educational_degree &&
                                                <Col>
                                                    <div className='EducationalInst d-flex'>
                                                    <img src={image.educationDegreeImage} alt='logo' className='microSiteImage'/><span className='mx-1'>{lang['Studied at label']} <strong>{userData.educational_information[0].educational_degree}</strong> {lang['from label']} <span className=''><strong>{userData.educational_information[0].educational_institute}</strong></span></span>
                                                    </div>
                                                </Col>
                                            }
                                            <div className=''>
                                               {
                                                    userData.place_of_work && <div className='EducationalInst d-flex'><img src={image.workPlaceImage} className='microSiteImage' alt='logo' /><div><span>{lang['Works at label']} </span><span className='mx-1'><strong>{userData.place_of_work}</strong></span></div></div>
                                               }  
                                            </div>  
                                            {
                                                userData.province_city && 
                                                <div className='EducationalInst d-flex'>
                                                    <img src={image.locationFieldIconImage} className='microSiteImage' alt='logo' />
                                                    <div><span> {lang['Province / City label']} :</span><strong className='mx-1'>{userData.province_city}</strong></div>
                                                </div>
                                            }
                                            {
                                                userData.district &&
                                                <div className='EducationalInst d-flex'>
                                                    <img src={image.locationFieldIconImage} className='microSiteImage' alt='logo'/>
                                                    <div><span>{lang['District label']} :</span><strong className='mx-1'>{userData.district}</strong></div>
                                                </div>
                                            }


                                            {
                                                userData.member_of_professional_organization &&  userData.member_of_professional_organization.length > 0 &&
                                                <div className='EducationalInst d-flex'>
                                                    <img src={image.checkListImage} className='microSiteImage' alt='logo'/>
                                                    <div> {lang['Member of label']}
                                                        {
                                                            userData.member_of_professional_organization && userData.member_of_professional_organization.map((member, index) => {
                                                                return (
                                                                    <span  key={index}> <strong> {member}</strong>{userData.member_of_professional_organization.length-1 !== index && <>,</>}</span>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            }    

                                        </Col>
                                    </Row>
                                    {
                                    userData.bio &&
                                    <Row className='ProfileAbout justify-content-between my-2'>
                                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} className='ProfileAboutDiv'>
                                            <div className='ProfileAbout px-2'>
                                                <h4>{lang['About label']}</h4>
                                                <p>{userData.bio}</p>
                                            </div>
                                        </Col>
                                        <Col xxl={3} xl={3} lg={3} md={3} sm={12}>
                                        </Col>
                                    </Row>
                                    }
                                </Col>

                            </Col>
                        </Row>
                    </Container>
                </div>): apiCalled && toShow404 ? (
                   <div className="section">
                   <h1 className="error">404</h1>
                   <div className="page">Ooops!!! The page you are looking for is not found</div>
                 </div>
                ):apiCalled && !toShow404 ?(
                    <>
                    <div className="page-center" >Ooops!!! The page you are looking for is Private</div>
                 </>
                ):<></>
            }
        </>
    )
}

export default Profile
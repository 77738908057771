import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import Badge from 'react-bootstrap/Badge';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CloseButton from 'react-bootstrap/CloseButton';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs'
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { MultiSelect } from "react-multi-select-component";
import image from '../../../../constant/image';
import 'react-quill/dist/quill.snow.css';
import './Channels.css'
import { useSelector } from 'react-redux';
import { apiCall } from '../../../../action/loginAction';
import { actionpermission } from '../../../../constant/commonFunctions'
import { FaLink } from "react-icons/fa";
import { getShareLink } from '../../../../constant/getShareLink';

const ChannelsFeed = (props) => {
    var navigate = useNavigate();
    const { channelFeedData } = useSelector(state => state.channelData);
    const [channelsData, setChannelsData] = useState([]);
    // get list of channel 
    useEffect(()=>{
        if (channelFeedData.groups) {
            setChannelsData(channelFeedData.groups)
        }else{
            setChannelsData(channelFeedData.data)
        }
    },[channelFeedData])
    // open indivisual channel
    const openIndivChannel = (channelData) => {
        navigate("/admin/channels/indivisualchannel ", { state: { data: channelData } });
    }
    // open and edit
    const openEditModel = (data) =>{
        props.sharePost(data)
    }
    const deleteChannel = (id) =>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this channel",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed === true) {
                props.deleteChannel(id)
            }
        })
    }

    const createLink = async (data) => {
          console.log("data.category_id", data.channel_id)
          let resultLink = await getShareLink('Channel',data.channel_id)
          navigator.clipboard
          .writeText(resultLink)
          .then(() => {
              alert("successfully copied");
          })
          .catch(() => {
              alert("something went wrong");
          });
    }
    return (
        <>
            {
                channelsData &&
                <ul className='channelsList p-0'>
                {
                    channelsData.map((channel, index) => {
                        return (
                            <li key={index} className='p-1 d-flex justify-content-between my-2'>
                                <div className='d-flex channelsInfo' onClick={() => { openIndivChannel(channel) }}>
                                    {
                                        channel.thumbnail_image !== null ?
                                            <div className=''>
                                                <img role='button' src={channel.thumbnail_image} alt='logo' className='channelsListLogo' />
                                            </div> :
                                            <div className=''>
                                                <img role='button' src={image.porfilePicture} alt='logo' className='channelsListLogo' />
                                            </div>
                                    }

                                    <div className='mx-3 py-2'>
                                        <div>
                                            <strong>
                                                {channel.channel_name}
                                            </strong>
                                            <span style={channel.channel_type === 'Private' ? { color: 'red' } : { color: 'green' }}>({channel.channel_type})</span>
                                            <span>
                                            {
                                            channel.specialities.length > 0 ?
                                                <span className='mx-3'>
                                                    specialities :{
                                                        channel.specialities.map((spon, index) => {
                                                            return (
                                                                <Badge pill bg="primary" className='m-2' key={index}>
                                                                    {spon.label_name }
                                                                </Badge>                                                                                            // <strong key={index}>{spon}</strong>
                                                            )
                                                        })
                                                    }
                                                </span> : null
                                        }
                                            </span>
                                        </div>
                                        <span>
                                            {channel.users_count}  subscribers
                                        </span>
                                        <span style={{marginLeft:'1rem'}}>
                                       Total Posts - {channel.post_count}
                                        </span>
                                        {
                                            channel.sponsor.length > 0 ?
                                                <span className='mx-3'>
                                                    sponsor :{
                                                        channel.sponsor.map((spon, index) => {
                                                            return (
                                                                <Badge pill bg="primary" className='m-2' key={index}>
                                                                    {spon}
                                                                </Badge>                                                                                            // <strong key={index}>{spon}</strong>
                                                            )
                                                        })
                                                    }
                                                </span> : null
                                        }
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <div onClick={() => createLink(channel)} style={{ color: '#277AFC', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='px-2'>
                                        <FaLink />
                                    </div>
                                    <div onClick={() => { openEditModel(channel) }} style={{ color: 'green', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='px-2'>
                                        <AiIcons.AiFillEdit />
                                    </div>
                                    <div onClick={() => deleteChannel(channel.channel_id)} style={{ color: 'red', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='px-2'>
                                        <AiIcons.AiFillDelete />
                                    </div>
                                </div>
                            </li>
                        )
                    })
                }
                </ul>
            }
        </>
    )
}

export default ChannelsFeed
export const UserActivityData = [
  {
    view :5200,
    postname : 'Lorem ipsum dolor sit amet, consectetur.',
    status :'Posted 3 days ago'
  },
  {
    view :5200,
    postname : 'Lorem ipsum dolor sit amet, consectetur.',
    status :'Posted 3 days ago'
  },
  {
    view :5200,
    postname : 'Lorem ipsum dolor sit amet, consectetur.',
    status :'Posted 3 days ago'
  },
  {
    view :5200,
    postname : 'Lorem ipsum dolor sit amet, consectetur.',
    status :'Posted 3 days ago'
  },
  {
    view :5200,
    postname : 'Lorem ipsum dolor sit amet, consectetur.',
    status :'Posted 3 days ago'
  },
  {
    view :5200,
    postname : 'Lorem ipsum dolor sit amet, consectetur.',
    status :'Posted 3 days ago'
  },
  {
    view :5200,
    postname : 'Lorem ipsum dolor sit amet, consectetur.',
    status :'Posted 3 days ago'
  },
  {
    view :5200,
    postname : 'Lorem ipsum dolor sit amet, consectetur.',
    status :'Posted 3 days ago'
  }
]
import React, { useEffect, useState, useRef } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import * as BsIcons from 'react-icons/bs'
import './Request.css'
import image from '../../../../constant/image';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import LeftSideBar from '../../SideBar/LeftSideBar';
import { useDispatch } from 'react-redux';
import { apiCall, approveUserBlobData } from '../../../../action/loginAction';
import Swal from 'sweetalert2';
import CloseButton from 'react-bootstrap/CloseButton';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import InputGroup from 'react-bootstrap/InputGroup';
import * as AiIcons from 'react-icons/ai';
import Form from 'react-bootstrap/Form';
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useNavigate } from "react-router-dom";
import {actionpermission} from '../../../../constant/commonFunctions'
import ReactPaginate from 'react-paginate';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import useDebounce from '../../../../constant/useDebouncing';
import Unauthorized from './Unauthorized';
const Request = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pendingUserList, setPendingUserList] = useState([])
  //model hide or show
  const [modalShow, setModalShow] = React.useState(false);
  const [profileDetails, setPorfileDetails] = useState({});
  const [reasonModalShow, setReasonModalShow] = useState(false);
  const [content, setContent] = useState('');
  const editor = useRef(null);
  const [email, setemail] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState({});
  const [isFirstTimeHit, setIsFirstTimeHit] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState("All users");
  const [perPage, setPerPage] = useState(10)
  const [permissions , setPermissions]=useState([])
  const [internalPermissions , setInternalPermissions] = useState([]);
  const [userTypeLog,setUserTypeLog] = useState("Admin");
  const debouceSearchTerm = useDebounce(searchValue, 300);
  const initialRender = useRef(true);
  const initialRender1 = useRef(true);
  const initialRender2 = useRef(true);

  let editData = localStorage.getItem('isEditUserData');
  let userType = localStorage.getItem('currentUserType');
  //get list when page is loaded
  useEffect(() => {
    let permissionsArray = actionpermission("Users")
    let internalPermissionsLocal = JSON.parse(localStorage.getItem("role_permission")).internal_permission.ID_View
    setInternalPermissions(internalPermissionsLocal)
    setPermissions(permissionsArray)
    if (editData === null) {
      getApproveList(page, perPage);
    }
  }, [])

  // filter on date
  // useEffect(() => {
  //   if (startDate !== null && isFirstTimeHit === true) {
  //     let newDate = formatDate(startDate);
  //     searchOnDate(newDate);
  //   } 

  // }, [page, searchValue, perPage, startDate])

  useEffect(()=>{
    // console.log(profileDetails.status,"status")
  },[profileDetails.status])
  // set user selected type 
  const setUserType = (val) => {
    localStorage.setItem('currentUserType', val);
    setSelectedUserType(val)
  }
  // on change of usertype
  useEffect(() => {
    if (initialRender1.current) {
      initialRender1.current = false;
      if (editData === true || editData === 'true') {
        if (userType) {
           setSelectedUserType(userType)
          
        }else{
          setSelectedUserType('All users')
          setTimeout(() =>{
            getApproveList(1, perPage);
          },300)
        }
        localStorage.setItem('isEditUserData', false);
      }else{
        getApproveList(page, perPage);
      }
    }else{
      if (searchValue.trim().length !==0) {
        localStorage.setItem('isEditUserData', false);
        getApproveList(page, perPage);
      }
    }
  }, [selectedUserType])
  //debounce
  useEffect(() => {
    if (initialRender.current) {
        initialRender.current = false;
    }else{
        if (debouceSearchTerm) {
          searchUser(debouceSearchTerm, page, perPage)
        }else{
            getApproveList(1, perPage);
            setSearchValue('')
        }
    }
}, [debouceSearchTerm, perPage,selectedUserType])
useEffect(() => {
  if (initialRender2.current) {
      initialRender2.current = false;
  }else{
        getApproveList(page, perPage);
  }
}, [page])
  //get data dd/mm/yyyy
  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
  }
  const formatDate = (date) => {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }
  // model operation
  const closeModel = () => {
    setModalShow(false);
  }
  const openModel = async (user) => {
    var data = {
      email: user,
      get_user_data: true,
    };
    let res = await dispatch(apiCall(data,'/user/approve'));
    if (res.data.message.MSG_CODE === 100441) {
      setPorfileDetails(res.data.data);
      setTimeout(() => {
        setModalShow(true);
      }, 500)
    } else if (res.data.message.MSG_CODE === 100020) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        text: "User does not exist",
        timer: 3000,
        showConfirmButton: false,
      })
    }
  }
  const swalApproverRequest = (user) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to accept",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, accept it!'
    }).then((result) => {
      if (result.isConfirmed) {
        approvedRequest(user)
      } else {
        return false
      }
    })
  }
  const openRejectionReasonModel = (email) => {
    setemail(email)
    setReasonModalShow(true)
  }
  const closeRejectionReasonModel = () => {
    setReasonModalShow(false)
    empty();
  }
  //approved user
  const approvedRequest = async (user) => {
    if (modalShow === true) {
      closeModel();
    }
    var data = {
      email: user,
      is_approval: true,
      approved: true,
      is_notify_user: true,
    };
    let res = await dispatch(apiCall(data,'/user/approve'));
    if (res.data.message.MSG_CODE === 100291) {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        text: 'User approved successfully and email has been send',
        timer: 4000,
        showConfirmButton: false,
      })
    }
    getApproveList(1, perPage)

  }
  // reject user
  const rejectRequest = async () => {
    var data = {
      email: email,
      rejection_reason: content,
      is_approval: false,
      approved: false,
      is_notify_user: true,
    };
    let res = await dispatch(apiCall(data,'/user/approve'));
    closeRejectionReasonModel();
    getApproveList(1, perPage);
    if (res.data.message.MSG_CODE === 100031) {
      Swal.fire({
        icon: 'success',
        text: 'The user has been rejected successfully and an email send to registered email address',
        timer: 4000,
        showConfirmButton: true,
      })
    } else if (res.data.message.MSG_CODE === 100500) {
      setPendingUserList([]);
      setPageData({});
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again!',
        timer: 4000
      })
    }
  }
  //get pending approval user list 
  const getApproveList = async (pg, perpg) => {
    var data = {
      get_pending_users: selectedUserType === 'Pending users' ? true : false,
      get_rejected_users: selectedUserType === 'Rejected users' ? true : false,
      get_approved_users: selectedUserType === 'Approved users' ? true : false,
      get_disabled_users: selectedUserType === 'Disabled users' ? true : false,
      get_all_users: selectedUserType === 'All users' ? true : false,
      per_page: perpg,
      page_number: pg,
      preferred_language: 'EN'
    };
    let res = await dispatch(apiCall(data,'/user/approve'));
    if (res.data.message.MSG_CODE === 100441) {
      setPendingUserList(res.data.data);
      setPageData(res.data.pagination);
      if (isFirstTimeHit === false) {
        setIsFirstTimeHit(true);
      }
    }
    else if (res.data.message.MSG_CODE === 100130){
          setUserTypeLog("Enterprise")
    }
    else if (res.data.message.MSG_CODE === 100500) {
      setPendingUserList([]);
      setPageData({});
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again!',
        timer: 4000
      })
    }
  }
  //empty data 
  const empty = () => {
    setContent('');
    setemail('');
    setPorfileDetails({});
    setSearchValue('');
    setPage(1)
    setStartDate(null)
  }
  // search user 
  const searchUser = async (value,pg, perpg) => {
    var data = {
      search_user: value,
      get_pending_users: selectedUserType === 'Pending users' ? true : false,
      get_rejected_users: selectedUserType === 'Rejected users' ? true : false,
      get_approved_users: selectedUserType === 'Approved users' ? true : false,
      get_all_users: selectedUserType === 'All users' ? true : false,
      per_page: perpg,
      page_number: pg,
      preferred_language: 'EN'
    }
    let res = await dispatch(apiCall(data,'/user/approve'));
    if (res.data.message.MSG_CODE === 100441) {
      setPage(1)
      setPendingUserList(res.data.data);
      setPageData(res.data.pagination);
    } else if (res.data.message.MSG_CODE === 100500) {
      setPendingUserList([]);
      setPageData({});
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again!',
        timer: 4000
      })
    }
  }
  const searchOnDate = async (newDate) => {
    var data = {
      date: newDate,
      get_pending_users: selectedUserType === 'Pending users' ? true : false,
      get_rejected_users: selectedUserType === 'Rejected users' ? true : false,
      get_approved_users: selectedUserType === 'Approved users' ? true : false,
      get_all_users: selectedUserType === 'All users' ? true : false,
      preferred_language: 'EN'
    }
    let res = await dispatch(apiCall(data,'/user/approve'));
    if (res.data.message.MSG_CODE === 100441) {
      setPendingUserList(res.data.data);
      setPageData(res.data.pagination);
    } else if (res.data.message.MSG_CODE === 100500) {
      setPendingUserList([]);
      setPageData({});
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again!',
        timer: 4000
      })
    }
  }
  // donwload csv list for users
  const downloadCsvList = async () => {
    var data = {
      download_approved_users_data: selectedUserType === 'Approved users' ? true : false,
      download_pending_users_data: selectedUserType === 'Pending users' ? true : false,
      download_rejected_users_data: selectedUserType === 'Rejected users' ? true : false,
      download_all_users_data: selectedUserType === 'All users' ? true : false,
      preferred_language: 'EN'
    };
    let res = await dispatch(apiCall(data,'/user/approve'));
    if (res.data.message.MSG_CODE === 100231) {
      downloadFile(res.data.data)
    } else if (res.data.message.MSG_CODE === 100721) {
      Swal.fire({
        icon: 'warning',
        text: 'No user available',
        timer: 4000
      })
    } else if (res.data.message.MSG_CODE === 100670) {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again!',
        timer: 4000
      })
    }
  }
  // download csv file
  const downloadFile = (urlData) => {
    var blob = new Blob([urlData], { type: 'text/csv;charset=utf-8;' })
    var link = document.createElement("a");
    var url = URL.createObjectURL(blob);
    link.setAttribute("href", urlData);
    link.setAttribute("download", "exportedFilenmae");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  // download pdf file
  const downloadPdfFile = async (email) => {
    var data = {
      email: email,
      download_user_details: true,
      preferred_language: 'EN'
    };
    let response = await dispatch(approveUserBlobData(data));
    let file = window.URL.createObjectURL(new Blob([response.data]));
    let a = document.createElement('a');
    a.download = 'myData.pdf';
    a.href = file;
    a.click()
  }
  //disable user 
  const swalDisableUser = (email) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to disable this user",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, accept it!'
    }).then((result) => {
      if (result.isConfirmed) {
        disableUser(email)
        profileDetails.status = 'DISABLED'
      } else {
        return false
      }
    })
  }
  const swalEnableUser = (email) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to Enable this user",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, accept it!'
    }).then((result) => {
      if (result.isConfirmed) {

        enableUser(email)
      } else {
        return false
      }
    })
  }
  const enableUser = async (email) => {
    var data = {
      enable_user: true,
      email: email
    }
    let res = await dispatch(apiCall(data,'/user/approve'));
    if (res.data.message.MSG_CODE === 100031) {
      getApproveList(1, perPage);
      profileDetails.status = 'APPROVED'

      Swal.fire({
        icon: 'success',
        text: 'User enabled successfully!',
        timer: 3000
      })
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again!',
        timer: 3000
      })
    }
  }
  const disableUser = async (email) => {
    var data = {
      disable_user: true,
      email: email
    }
    let res = await dispatch(apiCall(data,'/user/approve'));
    if (res.data.message.MSG_CODE === 100031) {
      getApproveList(1, perPage);
      Swal.fire({
        icon: 'success',
        text: 'User disable successfully!',
        timer: 3000
      })
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again!',
        timer: 3000
      })
    }
  }
  //pagination
  const handleChangePage = (page) => {
    setPage(page)
  }
  // pagination
  const handlePageClick = (data) =>{
    let currentPage = data.selected + 1 ;
    setPage(currentPage)
  }
  const navigateToEditUser = (item) => {
    const data = "data"
    localStorage.setItem('isEditUserData', true);
    navigate('/admin/pendingrequest/edituserdata', {
      state: {
        data: item
      }
    })
  }

  const deleteUserSwal = (dataemail) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this user ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed === true) {
        deleteUser(dataemail);
      }
    })
  }
  const deleteUser = async (dataemail) => {
    let data = {
      email: dataemail,
      delete_user: true
    }
    let res = await dispatch(apiCall(data, '/user/approve'));
    if (res.data.message.MSG_CODE === 100031) {
      Swal.fire({
        icon: 'success',
        text: 'User deleted successfully',
        timer: 2000
      })
      getApproveList(page, perPage);
    } else {
      Swal.fire({
        icon: 'error',
        text: 'USer not deleted pleas try again!',
        timer: 4000
      })
    }
  }
  return (
    <>
      <Modal
        {...profileDetails}
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
            Account Summary
          </Modal.Title>
          <CloseButton onClick={closeModel} />
        </Modal.Header>
        <Modal.Body>
          <section>
            <Row>
              <Col xxl={4} xl={4} lg={5} md={5} style={{ borderRight: '1px solid #b2cedb' }}>
                <div style={{ textAlign: 'center' }}>
                  {
                    profileDetails?.profile_picture === null ?
                      <img src={image.porfilePicture} alt='logo' style={{ height: '8rem', width: '8rem', borderRadius: '50%', marginRight: '1em' }} />
                      : <img src={profileDetails.profile_picture} alt='logo' style={{ height: '8rem', width: '8rem', borderRadius: '50%', marginRight: '1em' }} />
                  }
                </div>
                <div className='profileDetails' style={{ marginTop: '1.5em', paddingLeft: '2em' }}>
                  <span>Full Name</span>
                  <h6 className='m-0'>{profileDetails.full_name}</h6>
                </div>
                <div className='profileDetails' style={{ marginTop: '1em', paddingLeft: '2em' }}>
                  <span>Email Address</span>
                  <h6 className='m-0'>{profileDetails.email}</h6>
                </div>
              </Col>
              <Col xxl={7} xl={7} lg={7} md={7}>
                <div className='d-flex my-2'>
                  <Col className='profileDetails'>
                    <span>Phone number</span>
                    {

                      profileDetails.phone !== null && profileDetails.phone !== '' ?
                      <h6 className='m-0'>{profileDetails.phone}</h6>
                      : <h6 className='m-0'>Not available</h6>
                    }
                  </Col>
                  <Col className='profileDetails'>
                    <span>Role</span>
                    {
                      profileDetails.role !== null  && profileDetails.role !== ''?
                      <h6 className='m-0'>{profileDetails.role}</h6>
                      : <h6 className='m-0'>Not available</h6>
                    }
                  </Col>
                </div>
                <div className='d-flex my-2'>
                  <Col className='profileDetails'>
                    <span>Primary speciality</span>
                    {
                       profileDetails.primary_speciality !== null  && profileDetails.primary_speciality !== ''?
                        <h6 className='m-0'>{profileDetails.primary_speciality}</h6>
                        : <h6 className='m-0'>Not available</h6>
                    }
                  </Col>
                  <Col className='profileDetails'>
                    <span>Secondary speciality</span>
                    {
                      profileDetails.secondary_speciality !== null && profileDetails.secondary_speciality !== '' ?
                        <h6 className='m-0'>{profileDetails.secondary_speciality}</h6>
                        : <h6 className='m-0'>Not available</h6>
                    }
                  </Col>
                </div>
                <div className='d-flex my-2'>
                  <Col className='profileDetails'>
                    {
                      profileDetails.role === "STUDENT" && internalPermissions.includes("student_id") &&
                        <div>
                          <span>Student Id</span>
                          {
                            profileDetails.student_id_number !== null && profileDetails.student_id_number !== '' ? 
                            <h6 className='m-0'>{profileDetails.student_id_number}</h6>:<h6 className='m-0'>Not available</h6>
                          }
                        </div> 
                       
                    }
                     {
                      profileDetails.role === "DOCTOR" && internalPermissions.includes("professional_license") &&
                      <div>
                          <span>Professional license</span>
                          {
                            profileDetails.professional_license_number !== null && profileDetails.professional_license_number !== '' ? 
                            <h6 className='m-0'>{profileDetails.professional_license_number}</h6>:<h6 className='m-0'>Not available</h6>
                          }
                        </div>
                      }
                  </Col>
                </div>
                {
                  profileDetails.educational_information &&
                  <div className='d-flex my-2'>
                    <Col className='profileDetails'>
                        {
                          profileDetails.educational_information.map((educ, index) => {
                            return (
                              <div className='d-flex'>
                                <Col className='profileDetails'>
                                  <div>
                                    <span>Education Degree</span>
                                    {
                                      educ.educational_degree !== null && educ.educational_degree !== '' &&  educ.educational_degree !== undefined ?
                                        <h6 className='m-0'>{educ.educational_degree}</h6>
                                      : <h6 className='m-0'>Not available</h6>
                                    }
                                  </div>
                                </Col>
                                <Col className='profileDetails'>
                                  <span>Education Institute</span>
                                  {
                                    educ.educational_institute !== null && educ.educational_institute !== ''?
                                      <h6 className='m-0'>{educ.educational_institute}</h6>
                                    : <h6 className='m-0'>Not available</h6>
                                  }
                                </Col>
                              </div>
                            )
                          })
                        }
                    </Col>
                  </div>
                }
                <div className='d-flex my-2'>
                  <Col className='profileDetails'>
                    <span>District</span>
                    {
                      profileDetails.district !== null && profileDetails.district !== '' ?
                      <h6 className='m-0'>{profileDetails.district}</h6>
                      : <h6 className='m-0'>Not available</h6>
                    }
                  </Col>
                  <Col className='profileDetails'>
                    <span>City</span>
                    {
                      profileDetails.province_city !== null && profileDetails.province_city !== '' ?
                     <h6 className='m-0'>{profileDetails.province_city}</h6>
                     : <h6 className='m-0'>Not available</h6>
                    }
                  </Col>
                </div>
                {
                  profileDetails.other_information  &&
                  <div className='d-flex my-2'>
                    <Col className='profileDetails'>
                      <span>Other information</span>
                      {
                        profileDetails.other_information !== null && profileDetails.other_information !== '' ?
                        <h6 className='m-0'>{profileDetails.other_information}</h6>
                        : <h6 className='m-0'>Not available</h6>
                      }
                    </Col>
                  </div>
                }
                <div className='d-flex my-2'>
                  {
                    Object.keys(profileDetails).length !== 0 ?
                    <Col className='profileDetails'>
                    {
                      profileDetails.role === "STUDENT" && internalPermissions.includes("student_id") &&
                        <div>
                          <span>Student Id</span>
                          {
                            profileDetails.student_id_number !== null || profileDetails.student_id.length > 0?
                            <a href={profileDetails.student_id[profileDetails.student_id.length - 1]} target="_blank" rel="noreferrer" className='mx-2'>Preview</a>:<p>no data</p>
                          }
                        </div> 
                       
                    }
                     {
                      profileDetails.role === 'DOCTOR' && internalPermissions.includes("professional_license") &&
                      <div>
                          <span>Professional license</span>
                          {profileDetails.professional_license_number !== null || profileDetails.professional_license.length > 0? 
                             <a href={profileDetails.professional_license[profileDetails.professional_license.length - 1]} target="_blank" rel="noreferrer" className='mx-2'>Preview</a>:<p>no data</p>}
                        </div>
                      }

                  </Col> : null
                  }
                </div>
              </Col>
            </Row>
          </section>
        </Modal.Body>
        <Modal.Footer>
          {
            profileDetails.status === 'PENDING' ?
              <> 
                {/* removed approved button from pending users model popup */}
                <Button onClick={closeModel} style={{ background: 'white', color: 'blue' }}>Close</Button>
                
                {/* <Button onClick={() => swalApproverRequest(profileDetails.email)}>Approved</Button> */}
              </> : profileDetails.status === 'APPROVED' ?
                <>
                  <Button onClick={closeModel} style={{ background: 'white', color: 'blue' }}>Close</Button>
                  <Button onClick={() => swalDisableUser(profileDetails.email)}>Disable</Button>
                </> : profileDetails.status === 'DISABLED' ? 
                <>
                  <Button onClick={closeModel} style={{ background: 'white', color: 'blue' }}>Close</Button>
                  <Button onClick={() => swalEnableUser(profileDetails.email)}>Enable</Button>  
                </>  :
                <>
                  <Button onClick={closeModel} style={{ background: 'white', color: 'blue' }}>Close</Button>
                  <Button onClick={() => swalApproverRequest(profileDetails.email)}>Enable</Button>
                </> 
          }
        </Modal.Footer>
      </Modal>
      <Modal
        show={reasonModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='justify-content-between'
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
            Rejection Reason
          </Modal.Title>
          <CloseButton onClick={() => { closeRejectionReasonModel() }} />
        </Modal.Header>
        <Modal.Body>
          <section>
            <Row>
              <Col xxl={12} xl={12} lg={12} md={12}>
                <ReactQuill theme="snow"
                  ref={editor}
                  value={content}
                  tabIndex={1}
                  onChange={newContent => setContent(newContent)}
                />
              </Col>
            </Row>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => { rejectRequest() }}>Submit</Button>
        </Modal.Footer>
      </Modal>
      <section>
        <Row style={{ height: '100vh' }} xxl={12} xl={12} lg={12} md={12}>
          <Col className='' xxl={2} xl={2} lg={3} md={3} style={{ background: 'linear-gradient(90deg, #277AFC 0%, #27B3FC 100%)', height: '100%' }} >
            <LeftSideBar />
          </Col>
          {
            userTypeLog === "Admin" ? (
              <Col className='bg-prime p-0 adminPannel' xxl={10} xl={10} lg={9} md={9}>
              <Container className='log-wrap p-0' fluid style={{ height: '100vh' }}>
                <React.Fragment>
                  <section className='px-3 pt-3' style={{ height: '100%' }} >
                    <div>
                      <h5>Manage users</h5>
                      <hr></hr>
                    </div>
                    <div className='my-2' >
                      <DropdownButton id="dropdown-basic-button" title={selectedUserType}>
                        {permissions.includes("Approved Users") && <Dropdown.Item onClick={() => setUserType("Approved users")} >Approved Users</Dropdown.Item>}
                        {permissions.includes("Pending Users") && <Dropdown.Item onClick={() => setUserType("Pending users")} >Pending Users</Dropdown.Item>}
                         {permissions.includes("Rejected Users") && <Dropdown.Item onClick={() => setUserType("Rejected users")} >Rejected Users</Dropdown.Item>}
                         {permissions.includes("Rejected Users") &&<Dropdown.Item onClick={() => setUserType("Disabled users")} >Disabled Users</Dropdown.Item>}
                         {permissions.includes("All Users") && <Dropdown.Item onClick={() => setUserType("All users")} >All Users</Dropdown.Item>}
                      </DropdownButton>
                    </div>
                    <>
                      <Row style={{ alignItems: 'center' }} xxl={12} xl={12} lg={12} md={12}>
                        <Col className='' xxl={4} xl={4} lg={5} md={4}>
                          <InputGroup className="">
                            <Form.Control
                              placeholder={selectedUserType}
                              aria-label="Events"
                              aria-describedby="basic-addon1"
                              value={searchValue}
                              onChange={(e) => setSearchValue(e.target.value)}
                              style={{ cursor: 'auto !important',paddingRight:'1.6rem' }}
                            />
                            {
                              searchValue.trim().length === 0 ?
                              <span role='button' className='searchIcon' onClick={() => { }}><BsIcons.BsSearch /></span> :
                              <span role='button' className='searchIcon' onClick={() => {setSearchValue('')}}><AiIcons.AiOutlineClose /></span>
                            }
                          </InputGroup>
                        </Col>
                        {/* <Col className='justify-content-between datePickerSelector' xxl={4} xl={4} lg={5} md={4}>
                          <DatePicker
                              placeholderText='DD-MM-YYYY'
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              dateFormat='dd/MM/yyyy'
                            />
                        </Col> */}
                        <Col xxl={1} xl={1} lg={2} md={1} className='text-center'>
                          <DropdownButton id="dropdown-basic-button" title={perPage} size="sm">
                            <Dropdown.Item onClick={() => setPerPage(10)} >10</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPerPage(25)} >25</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPerPage(50)} >50</Dropdown.Item>
                          </DropdownButton>
                        </Col>
                        {
                          permissions.includes("Download list") &&
                        <Col className='text-end px-4 flex-grow-1' xxl={3} xl={3} lg={5} md={4}>
                          <Button variant="primary" style={{ height: '2.3rem' }} onClick={() => { downloadCsvList() }}>Download list</Button>
                        </Col>
                        }
                      </Row>
                      <div className='tableClass'>
                        <Table hover striped size="sm" className='table-responsive'>
                          <thead>
                            <tr>
                              <th className='' >Sr No</th>
                              <th className='' >Name</th>
                              <th className='' >Email</th>
                              <th className=''>Primary Specialty</th>
                               <th className=''>Role</th>
                              {
                                selectedUserType === 'Pending users' ?
                                  <th className=''>Action</th>
                                  : null
                              }
                            {
                                selectedUserType === 'Approved users' ?
                                  <th className=''>View</th>
                                  : null
                              }
                              <th className='text-center'> Edit</th>
                              <th className='text-center'> Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              pendingUserList.map((item, index) => {
                                return (
                                  <tr key={index} className='' role='button'>
                                    <td className='mx-2'>{index+1}</td>
                                    <td className='' onClick={() => openModel(item.email)}>
                                      <div className='d-flex align-items-center'>
                                        <img role='button' src={image.porfilePicture} alt='logo' style={{ height: '2rem', width: '2rem', borderRadius: '50%', marginRight: '1em' }} />
                                        {
                                          item.role !== 'ENTERPRISE'?
                                          <span className='viewsOnPost'>{item.full_name}</span>:
                                          <span className='viewsOnPost'>{item.company_name}</span>
                                        }
                                      </div>
                                    </td>
                                    <td className='' onClick={() => openModel(item.email)}>{item.email}</td>
                                    <td className='' onClick={() => openModel(item.email)}>{item.primary_speciality}</td>
                                    <td className='' onClick={() => openModel(item.email)}>{item.role}</td>
                                    {
                                      selectedUserType === 'Pending users' ?
                                        <td role='button'><div className='d-flex'><span style={{ color: 'green' }} onClick={() => { swalApproverRequest(item.email) }}><BsIcons.BsCheckCircleFill /></span><span className='requestAction' style={{ color: 'red' }} onClick={() => { openRejectionReasonModel(item.email) }}><BsIcons.BsXCircleFill /></span></div></td>
                                        : null
                                    }
                                    {
                                      selectedUserType === 'Approved users' ?
                                      <>
                                      {
                                        item.role === 'DOCTOR'?
                                        <td className='text-center'><a href = {item.url} target ='_blank'><AiOutlineEye /></a></td>
                                        :  <td className=''></td>
                                      }
                                      </>:null
  
                                    }
                                    {(item.role === 'DOCTOR' || item.role === 'STUDENT' )? (<td className='text-center' onClick={()=>navigateToEditUser(item)}>
  
                                      <AiIcons.AiFillEdit />
                                      </td>):<td className='text-center'> - </td>
                                      }
  
                                      <td className='text-center' style={{ color: 'red' }} onClick={() => deleteUserSwal(item.email)}>
                                        <AiIcons.AiFillDelete />
                                      </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>
                        {
                          pageData.total_page > 1 && (
                            <ReactPaginate
                              className='pagination m-0'
                              breakLabel= {'...'}
                              nextLabel="next >"
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={2}
                              marginPagesDisplayed={2}
                              pageCount={pageData.total_page}
                              previousLabel="< prev"
                              renderOnZeroPageCount={null}
                              containerClassName="pagination"
                              activeClassName={'active'}
                              pageClassName = {'page-item'}
                              previousClassName = {'page-item'}
                              previousLinkClassName = {'page-link'}
                              pageLinkClassName = {'page-link'}
                              nextClassName = {'page-item'}
                              nextLinkClassName = { 'page-link'}
                              breakClassName = {'page-item'}
                              breakLinkClassName = {'page-link'}
                             />
                          )
                        }
                      </div>
                    </>
                  </section>
                </React.Fragment>
              </Container>
            </Col>
            ) : (
              <Col className='bg-prime p-0 channelScreen' xxl={10} xl={10} lg={9} md={9} style={{ height: '100%' }}>
                     <Unauthorized/>
                </Col>
            )
          }
    
        </Row>
      </section>
    </>
  )
}

export default Request
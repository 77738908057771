import React,{useState} from 'react';
import './login.css';
import {useDispatch} from 'react-redux';
import { apiCall } from '../../action/loginAction';
import { useNavigate } from "react-router-dom";
import image from '../../constant/image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Swal from 'sweetalert2'
import Spinner from 'react-bootstrap/Spinner';
const ForgotPassword = () => {
    var navigate = useNavigate();
    const dispatch = useDispatch();
    //email state and key
    const [email,setEmail] = useState("");
    const [phoneEmailError, setPhoneEmailError] = useState('null');
    const [showPhoneEmailString, setShowPhoneEmailString] = useState(false);

    //login with otp
    const [isOtpRecieved, setisOtpRecieved] = useState(false);
    const [verificationCodeValue, setVerificationCodeValue] = useState('');
    const [referenceCode, setReferenceCode] = useState('');

    //spinner
    const[showSpinner,setShowSpinner] = useState(false)

    //add email value to email state
    const addEmail = (email) => {
        setPhoneEmailError(() => (null));
        setShowPhoneEmailString(false);
        setEmail(email);
    }
    //on forgot password
    const onForgotPassword =  async(e) =>{
        e.preventDefault();
        if ( email === ''){
          setPhoneEmailError('Please enter email eddress');
          setShowPhoneEmailString(true);
        }else if (email !== ''){
            let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (reg.test(email) === false) {
              Swal.fire({
                icon: 'error',
                text: 'enter proper email!',
              })
              return
            }
            var data = {  
                email:email,
                is_send_email: true,
              };
            let res = await dispatch(apiCall(data,'/user/forgot-password'));
            if (res.data.message.MSG_CODE === 100191){
                Swal.fire({
                    icon: 'success',
                    text: 'Email send successfully',
                })
                setReferenceCodeValue(res.data.data.reference_id)
            }
            else if (res.data.message.MSG_CODE === 100020){
                Swal.fire({
                    icon: 'error',
                    text: 'User does not exist',
                })
            }
        }
    }
    const onConfirmCode = async(e) => {
        e.preventDefault();
        setShowSpinner(true);
        let data = {  
            email:email,
            is_verify_code: true,
            code: Number(verificationCodeValue),
            reference_id : referenceCode,

        };
        let res = await dispatch(apiCall(data,'/user/forgot-password'));
        setShowSpinner(false);
        if (res.data.message.MSG_CODE === 100201){
            Swal.fire({
                icon: 'success',
                text: 'Code is verified successfully',
            })
            redirectConfirmPass();
        }else if (res.data.message.MSG_CODE === 100250){
            Swal.fire({
                icon: 'error',
                text: 'Please entre valid code',
            })
        }else if (res.data.message.MSG_CODE === 100340){
            Swal.fire({
                icon: 'warning',
                text: 'OTP is already validated',
            })
        }
    }
    //set reference for otp verifaction
    const setReferenceCodeValue = (refid)=>{
        setReferenceCode(refid);
        setisOtpRecieved(true);
    }
      //redirect forgot password 
    const redirectConfirmPass = () => {
        navigate("/resetpassword ",{state:{userEmailId:email,referenceCodeValue:referenceCode,verificationCode:verificationCodeValue}});
    }

  return (
    <div className='Login'> 
        <div className='loginImage d-flex align-items-center' > 
            <Container className='log-wrap ' style={{ height: '57vh'}}>
                <div className='loginCard sm-12 w-100'>
                    <Row className='d-flex align-items-center justify-content-center' sm={12}>
                        <Col sm={4} className = 'loginBox d-flex align-items-center justify-content-around'>
                            <form className="Auth-form w-75">
                                <div className="Auth-form-content">
                                    <h3 style ={{color:'#1d60de'}}>Forgot Password</h3>
                                    <div className="form-group mt-3">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        className="form-control mt-1 lh-lg"
                                        placeholder="Enter email"
                                        disabled= {isOtpRecieved === true} 
                                        value={email}
                                        onChange={(email) => addEmail(email.target.value)}
                                    />
                                    {
                                        showPhoneEmailString === true ?
                                        <label className='mt-2' style={{ color: "red" }}>{phoneEmailError}</label>:
                                        null
                                    }
                                    </div>
                                    {
                                    isOtpRecieved === true?
                                    <div className="form-group mt-3">
                                        <label>Verifcation Code</label>
                                        <input
                                            maxLength={4}
                                            type="number"
                                            name="number"
                                            id="number"
                                            className="form-control mt-1 lh-lg"
                                            placeholder="Enter code"
                                            value={verificationCodeValue}
                                            onChange={(verificationCodeValue) =>{ 
                                                if(verificationCodeValue.target.value.length === 7) return false;
                                                setVerificationCodeValue(verificationCodeValue.target.value)}}
                                        />
                                    </div>:null
                                    }
                                    
                                    <div className="d-flex align-items-center justify-content-between my-3">
                                        {
                                            isOtpRecieved === false ?
                                            <button type="submit" className="btn btn-primary w-100 p-2" onClick={onForgotPassword}>
                                                Continue
                                            </button>:
                                            <button type="submit" className="btn btn-primary w-100 p-2" onClick={onConfirmCode}>
                                                Confirm
                                            </button>
                                        }
                                    </div>
                                    {
                                        showSpinner === true?
                                        <Spinner animation="border" variant="info" />:
                                        null
                                    }
                                </div>
                            </form>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row sm={12} style ={{textAlign:'center'}}> 
                        <Col>
                        <img src={image.loginPgLeftImg} alt= 'logo' style={{ height:'30vh',width:'17vw'}}/>
                        </Col>
                        <Col>
                        <img src={image.loginPgRightImg} alt= 'logo' style={{height:'30vh',width:'17vw'}}/>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    </div>
  )
}

export default ForgotPassword
import React, { useState, useRef, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import LeftSideBar from '../../SideBar/LeftSideBar'
import Button from 'react-bootstrap/Button';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import image from '../../../../constant/image';

import Swal from 'sweetalert2';
import 'react-quill/dist/quill.snow.css';
import './UserInfoEdit.css';
import { apiCall } from '../../../../action/loginAction';
import { MultiSelect } from 'react-multi-select-component';
import Multiselect from 'multiselect-react-dropdown';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import * as AiIcons from 'react-icons/gi';
import * as BiIcons from 'react-icons/bs';

const UserInfoEdit = (props) => {
    const { state } = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [userData, setUserData] = useState({})
    const [emailData, setEmailData] = useState('')
    const [OriginalEmailData, setOriginalEmailData] = useState('')
    const [fullName, setFullName] = useState('')
    const [specialitiesList, setSpecialitiesList] = useState([]);
    const [mideaList, setMideaList] = useState([]);
    let [primarySpeciality, setPrimarySpeciality] = useState([]);
    let [secondarySpeciality, setSecondarySpeciality] = useState([]);
    const [educationDegreeList, setEducationDegreeList] = useState([]);
    let [educationalDegree, setEducationalDegree] = useState([]);
    const [studentId , setStudentId] = useState('')
    const [professionalLicenseNumber , setProfessionalLicenseNumber] = useState('')
    const [educationalInstituteList, setEducationalInstituteList] = useState([]);
    const [educationalInstitute, setEducationalInstitute] = useState([]);
    const [genderList, setGenderList] = useState([]);
    const [roleList,setRoleList] = useState([ 
        {
            "label": "STUDENT",
            "value": "STUDENT",
            "id": "1"
        },
        {
            "label": "DOCTOR",
            "value": "DOCTOR",
            "id": "2"
        },
        
    ])
    const [isImageUpload, setIsImageUpload] = useState(false);
    const [isImageUpload1, setIsImageUpload1] = useState(false);
    const [isImageUpload2, setIsImageUpload2] = useState(false);


    const email = localStorage.getItem('email');
    const role = localStorage.getItem('role');
    let currentRole = ''
    const [profilePhoto, setProfilePhoto] = useState(image.porfilePicture)
    const [profilePhotoMediaId, setProfilePhotoMediaId] = useState(null)
    const [cardPhoto, setCardPhoto] = useState([])
    const [cardPhotoMediaId, setCardPhotoMediaId] = useState([])
    const [cardPhoto1, setCardPhoto1] = useState([])
    const [cardPhotoMediaId1, setCardPhotoMediaId1] = useState([])
    const [memberOfProfessionalOrg, setMemberOfProfessionalOrg] = useState([])
    const [avgPatients, setAvgPatients] = useState(null)
    let [gender, setGender] = useState([]);
    let [selectedRole , setSelectedRole] = useState([])
    const [placeOfWorkList, setPlaceOfWorkList] = useState([]);
    const [placeOfWork, setPlaceOfWork] = useState([]);
    let [profileDetails, setPorfileDetails] = useState({});
    const [provinceData, setProvinceData] = useState([]);
    let [provinceCity, setProvinceCity] = useState('');
    const [district, setDistrict] = useState([]);
    const [provinceCityData, setProvinceCityData] = useState([]);
    const [registrationPageData, setRegistrationPageData] = useState([])
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const initialRender = useRef(true);
    const initialRender1 = useRef(true);

    const [otherSpecialities, setOtherSpecialities] = useState([]);
    const [address, setAddress] = useState('');



    // get data
    useEffect(() => {
        setUserData(state)
        setFullName(state.data.full_name)
        setEmailData(state.data.email)
        setOriginalEmailData(state.data.email)
    }, [userData])
    useEffect(() => {
        getRegistrationPageData()
        getUserData(state.data.email)
    }, [])
    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            setProfileData()
        }

    }, [profileDetails])

    useEffect(() => {
        if (provinceCityData.length > 0) {
            let filteredDistrict = provinceCityData.filter(value => {
                return value === profileDetails.district

            })
            setDistrict(filteredDistrict)
        }
    }, [provinceCityData])
    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;

        } else {
            setProfileData()
        }
    }, [educationDegreeList])
    useEffect(()=>{
        if (initialRender1.current) {
            initialRender1.current = false;
            
        }else  if (!initialRender.current && selectedRole.length > 0 && selectedRole[0].value !== profileDetails.role) {
            Swal.fire({
                icon: 'warning',
                    text: 'Roles are being changed , Enter all required fields)',
                    timer: 3000,
                    showConfirmButton:true,
                    
              })
              console.log(profileDetails)
            if(selectedRole[0] && selectedRole[0].value === "DOCTOR"){
                
                setCardPhoto1(profileDetails.student_id)
                setCardPhotoMediaId1(profileDetails.student_id_media_id)
                setProfessionalLicenseNumber(profileDetails.student_id_number)
                profileDetails.role = selectedRole[0].value
                profileDetails = {...profileDetails , role : selectedRole[0].value }
            }else if( selectedRole[0] &&selectedRole[0].value === 'STUDENT'){
               
                setCardPhoto(profileDetails.professional_license)
                setCardPhotoMediaId(profileDetails.professional_license_media_id)
                setStudentId(profileDetails.professional_license_number)
                profileDetails.role = selectedRole[0].value
                profileDetails = {...profileDetails , role : selectedRole[0].value }
            }else{
    
            } 
        }
    },[selectedRole])

    const setProfileData = () => {

        let filteredPrimary = specialitiesList.filter(value => {
            return value.label === state.data.primary_speciality
        })
        let filteredSecondary = specialitiesList.filter(value => {
            return value.label === state.data.secondary_speciality
        })
        if (profileDetails.educational_information !== undefined) {

            let filterEducationDegree = educationDegreeList.filter(value => {
                return value.label === profileDetails.educational_information[0].educational_degree
            })
            let educationInstitute = educationalInstituteList.filter(value => {
                return value.label === profileDetails.educational_information[0].educational_institute
            })
            setEducationalInstitute(educationInstitute)
            setEducationalDegree(filterEducationDegree)
        }
        let filterPlaceWork = placeOfWorkList.filter(value => {
            return value.label === profileDetails.place_of_work

        })
        let filteredProvinceCity = provinceData.filter(value => {
            return value === profileDetails.province_city

        })
        let photo = profileDetails.profile_picture
        if (photo === null) {
            setProfilePhoto(image.porfilePicture)
        } else {
            setProfilePhoto(profileDetails.profile_picture)

        }
        let filteredGender = genderList.filter(value => {
            return value.value === profileDetails.gender

        })
        let otherInt = specialitiesList.filter(value => {
            return profileDetails?.other_interests?.includes(value.label)
        })
        setOtherSpecialities(otherInt)
        setGender(filteredGender)
        setProvinceCity(filteredProvinceCity)
        setPrimarySpeciality(filteredPrimary)
        setSecondarySpeciality(filteredSecondary)
        setPlaceOfWork(filterPlaceWork)
        onProvinceClick(filteredProvinceCity)
        setMemberOfProfessionalOrg(profileDetails.member_of_professional_organization)
        setAddress(profileDetails.address)
        setAvgPatients(profileDetails.average_patients_per_day)
        setProfilePhotoMediaId(profileDetails.profile_picture_media_id)   
        let filteredRole = roleList.filter(value => {
            // console.log(value.label , profileDetails.role , profileDetails)
            return value.label === profileDetails.role

        })
        setSelectedRole(filteredRole)
        // console.log(filteredRole[0] ,profileDetails.role)
        if(profileDetails.role === 'STUDENT'){
            setCardPhoto(profileDetails.student_id)
            setCardPhotoMediaId(profileDetails.student_id_media_id)
            setStudentId(profileDetails.student_id_number)
        }else  if(profileDetails.role === 'DOCTOR'){
            // console.log(profileDetails.professional_license)
            setCardPhoto1(profileDetails.professional_license)
            setCardPhotoMediaId1(profileDetails.professional_license_media_id)
            setProfessionalLicenseNumber(profileDetails.professional_license_number)
        }
        // currentRole = filteredRole.value
        // console.log(cardPhoto , cardPhotoMediaId)

    }
    // push to sponspor array 
    const sponsporArrayPush = (e) => {
        if (e.key !== 'Enter') return
        const value = e.target.value
        if (!value.trim()) return
        if (profileDetails.sponsor === undefined) {
            setMemberOfProfessionalOrg([...memberOfProfessionalOrg, value])
        } else {
            setPorfileDetails({ ...profileDetails, sponsor: [...profileDetails.sponsor, value] })
        }
        e.target.value = ''
    }
    const removeTag = (index) => {
        setMemberOfProfessionalOrg(memberOfProfessionalOrg.filter((el, i) => i !== index))
    }
    const uploadFile = async (file, type) => {
        if (isImageUpload === true && type === 'image') {
            mideaList.pop();
        }
        let arrayMediaId = [];
        let isUploadingCompletedSuccessfully = false
        for (let i = 0; i < file.length; i++) {
            let convertString = await convertBase64(file[i]);
            let base64Array = convertString.split(",");
            let base64 = base64Array[1];
            var data = {
                email: email,
                file_path: "channel",
                file_data: base64,
                file_name: file[i].name,
            };
            let res = await dispatch(apiCall(data, '/user/upload'));
            if (res.data.message.MSG_CODE === 100231) {
                setProfilePhoto(res.data.data.url)
                setProfilePhotoMediaId(res.data.data.media_id)
            } else if (res.data.message.MSG_CODE === 100270) {
                Swal.fire({
                    icon: 'error',
                    text: 'Not valid extension please upload again (accept only JPEG, PNG, JPG)',
                    timer: 3000
                })
                break
            } else if (res.data.message.MSG_CODE === 100580 || res.data.message.MSG_CODE === 100010 || res.data.message.MSG_CODE === 100270) {
                Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong please upload again',
                    timer: 3000
                })
                break
            }
            if (i === file.length - 1) {
                isUploadingCompletedSuccessfully = true
            }
        }
        if (isUploadingCompletedSuccessfully === true) {
            if (type === 'image') {
                setIsImageUpload(true)
            }
            setMideaList([...mideaList, ...arrayMediaId]);
        }
    }
    const changePL = (value)=>{
        console.log(value)
    }
    const changeStudentId = (value)=>{
        console.log(value)
    }
    
    const uploadFile1 = async (file, type) => {
        
        let arrayMediaId = [];
        let isUploadingCompletedSuccessfully = false
        for (let i = 0; i < file.length; i++) {
            let convertString = await convertBase64(file[i]);
            let base64Array = convertString.split(",");
            let base64 = base64Array[1];
            var data = {
                email: email,
                file_path: "user_info/student_id",
                file_data: base64,
                file_name: file[i].name,
            };
            let res = await dispatch(apiCall(data, '/user/upload'));
            if (res.data.message.MSG_CODE === 100231) {
                setCardPhoto([res.data.data.url])
                setCardPhotoMediaId([res.data.data.media_id])
            } else if (res.data.message.MSG_CODE === 100270) {
                Swal.fire({
                    icon: 'error',
                    text: 'Not valid extension please upload again (accept only JPEG, PNG, JPG)',
                    timer: 3000
                })
                break
            } else if (res.data.message.MSG_CODE === 100580 || res.data.message.MSG_CODE === 100010 || res.data.message.MSG_CODE === 100270) {
                Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong please upload again',
                    timer: 3000
                })
                break
            }
            if (i === file.length - 1) {
                isUploadingCompletedSuccessfully = true
            }
        }
        if (isUploadingCompletedSuccessfully === true) {
            if (type === 'image') {
                setIsImageUpload1(true)
            }
        }
    }
    const uploadFile2 = async (file, type) => {
        
        let arrayMediaId = [];
        let isUploadingCompletedSuccessfully = false
        for (let i = 0; i < file.length; i++) {
            let convertString = await convertBase64(file[i]);
            let base64Array = convertString.split(",");
            let base64 = base64Array[1];
            var data = {
                email: email,
                file_path:  "user_info/professional_license",
                file_data: base64,
                file_name: file[i].name,
            };
            let res = await dispatch(apiCall(data, '/user/upload'));
            if (res.data.message.MSG_CODE === 100231) {
                setCardPhoto1([res.data.data.url])
                setCardPhotoMediaId1([res.data.data.media_id])
            } else if (res.data.message.MSG_CODE === 100270) {
                Swal.fire({
                    icon: 'error',
                    text: 'Not valid extension please upload again (accept only JPEG, PNG, JPG)',
                    timer: 3000
                })
                break
            } else if (res.data.message.MSG_CODE === 100580 || res.data.message.MSG_CODE === 100010 || res.data.message.MSG_CODE === 100270) {
                Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong please upload again',
                    timer: 3000
                })
                break
            }
            if (i === file.length - 1) {
                isUploadingCompletedSuccessfully = true
            }
        }
        if (isUploadingCompletedSuccessfully === true) {
            if (type === 'image') {
                setIsImageUpload2(true)
            }
        }
    }
    // base64 conversion
    const convertBase64 = (file) => {
        console.log("here")
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = (() => {
                resolve(fileReader.result);
            });
            fileReader.onerror = ((error) => {
                reject(error)
            });
        });
    };
    const checkSpecialtiy = () => {
        return true
    }
    const UpdateUserInfoData = async () => {
        console.log(cardPhotoMediaId1 , cardPhotoMediaId)
        if(selectedRole[0] &&  selectedRole[0].value === 'DOCTOR' &&( placeOfWork.length === 0 || professionalLicenseNumber.length === 0 || cardPhotoMediaId1.length === 0)){
            Swal.fire({
                icon: 'error',
                text: 'Enter all required fields',
                timer: 4000
            })
        } else if(selectedRole[0] &&  selectedRole[0].value === 'STUDENT' &&(  studentId.length === 0 || cardPhotoMediaId.length === 0)){
            Swal.fire({
                icon: 'error',
                text: 'Enter all required fields',
                timer: 4000
            })
        }else if (fullName.trim().length !== 0 && emailData && primarySpeciality !== null && primarySpeciality.length !== 0 && profileDetails.phone && provinceCity[0] && district[0] && educationalDegree.length !== 0 && educationalInstitute.length !== 0 && emailData && emailRegex.test(emailData) && gender.length !== 0 && gender[0].id ) {
            if (profileDetails.alternate_email.length > 0 && !emailRegex.test(profileDetails.alternate_email)) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please enter valid alternate email',
                    timer: 4000
                })
                return
            }

            let includeingPrimary = []
            let includeingSecondary = []

            if (primarySpeciality[0]?.value && otherSpecialities.length > 0) {
                includeingPrimary = otherSpecialities.filter(spec => {
                    return spec.value === primarySpeciality[0].value && spec.label !== 'Other specialties'
                })
            }
            if (secondarySpeciality[0]?.value && otherSpecialities.length > 0) {
                includeingSecondary = otherSpecialities.filter(spec => {
                    return spec.value === secondarySpeciality[0].value && spec.label !== 'Other specialties'
                })
            }
            if (includeingPrimary.length > 0) {
                Swal.fire({
                    icon: 'error',
                    text: 'Your other interest should be different from Primary Specialties ',
                    timer: 4000
                })
                return
            }
            if (includeingSecondary.length > 0) {
                Swal.fire({
                    icon: 'error',
                    text: 'Your other interest should be different from Secondary Specialties',
                    timer: 4000
                })
                return
            }
            let educational_information_obj = {
                educational_degree: educationalDegree[0].value,
                educational_institute: educationalInstitute[0].value
            }
            let otherSpecId = otherSpecialities.map(other => {
                return other.value
            })
            let data = {
                edit_user_data: true,
                email: OriginalEmailData,
                full_name: fullName,
                user_email: emailData,
                primary_speciality: primarySpeciality[0].value,
                secondary_speciality: secondarySpeciality[0] ? secondarySpeciality[0].value : null,
                phone: profileDetails.phone,
                province_city: provinceCity[0],
                district: district[0],
                educational_information: [
                    educational_information_obj
                ],
                
                alternate_email: profileDetails.alternate_email,
                profile_picture: profilePhotoMediaId,
                other_interests: otherSpecId.length > 0 ? otherSpecId : [],
                gender: gender[0].id,
                address,
                member_of_professional_organization: memberOfProfessionalOrg,
                average_patients_per_day: Number(avgPatients),
                bio: profileDetails.bio,
                role:selectedRole[0].value,


            };
            console.log(cardPhotoMediaId1 , cardPhotoMediaId)
                let obj = {
                    place_of_work: placeOfWork[0]?.label,
                    professional_license:cardPhoto1,
                    professional_license_media_id:cardPhotoMediaId1,
                    professional_license_number:professionalLicenseNumber,
                    student_id:cardPhoto,
                    student_id_media_id:cardPhotoMediaId,
                    student_id_number:studentId
                }
                data = {...data , ...obj}
           
            // console.log("data" , data)
            let res = await dispatch(apiCall(data, '/user/approve'));
            if (res.data.message.MSG_CODE === 100431) {
                Swal.fire({
                    icon: 'success',
                    text: 'User has been updated successfully',
                    timer: 3000
                })
                navigate("/admin/pendingrequest")
            }
            else if (res.data.message.MSG_CODE === 100490) {
                Swal.fire({
                    icon: 'error',
                    text: 'Profile Update Failed',
                    timer: 4000
                })
            }

        } else {
            if (fullName === null || fullName.trim().length === 0) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please fill full name',
                    timer: 4000
                })
                return
            }
            if (emailData === null || emailData.trim().length === 0) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please fill email',
                    timer: 4000
                })
                return
            }
            if (primarySpeciality === null || primarySpeciality.length === 0) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please select primary speciality',
                    timer: 4000
                })
                return
            }
            if (secondarySpeciality[0] && secondarySpeciality[0].value === primarySpeciality[0].value) {
                Swal.fire({
                    icon: 'error',
                    text: 'You cannot set same primary and secondary speciality',
                    timer: 4000
                })
                return
            }

            if (profileDetails.phone === null || profileDetails.phone.length === 0) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please enter phone number',
                    timer: 4000
                })
                return
            }
            if (provinceCity === null || provinceCity.length === 0) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please select province',
                    timer: 4000
                })
                return
            }
            if (district === null || district.length === 0) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please select district',
                    timer: 4000
                })
                return
            }
            if (educationalDegree.length === 0 || educationalDegree === null) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please select education degree',
                    timer: 4000
                })
                return
            }
            if (educationalInstitute.length === 0 || educationalInstitute === null) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please select education institution',
                    timer: 4000
                })
                return
            }
            
            if (emailData === null || emailData.length === 0) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please enter email',
                    timer: 4000
                })
                return
            }
            if (gender.length === 0) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please select gender',
                    timer: 4000
                })
                return
            }
           


        }
    }
    const onProvinceClick = (val) => {
        if (val.length > 0) {

            let newVal = val[val.length - 1]

            setProvinceCity([newVal])
            let newdata = []
            val.forEach((value) => {
                newdata = [...newdata, ...registrationPageData.provinces[newVal]];
            })
            const uniqueAges = [...new Set(newdata)]
            setProvinceCityData(uniqueAges)
        } else {
            setProvinceCity('')
            setProvinceCityData([])

        }
    }
    const getRegistrationPageData = async () => {
        var data = {
            preferred_language: 'EN',
        };
        let res = await dispatch(apiCall(data, '/user/get-registration-page-info'));
        if (res.data.message.MSG_CODE === 100281) {
            setRegistrationPageData(res.data.data)
            let newSpecArray = [];
            for (let i = 0; i < res.data.data.specialities.length; i++) {
                newSpecArray.push({ 'label': res.data.data.specialities[i].label_name, 'value': res.data.data.specialities[i].identifier_id })
            }
            setSpecialitiesList(newSpecArray);



            let newSpecArrayGender = [];
            for (let i = 0; i < res.data.data.gender.length; i++) {
                newSpecArrayGender.push({ 'label': res.data.data.gender[i].label_name, 'value': res.data.data.gender[i].label_name, 'id': res.data.data.gender[i].identifier_id })
            }
            setGenderList(newSpecArrayGender);
            // console.log(genderList,"list" , roleList)
            //Education Degree
            let educationalDegreesArray = [];
            for (let i = 0; i < res.data.data.educational_degrees.length; i++) {
                educationalDegreesArray.push({ 'label': res.data.data.educational_degrees[i].label_name, 'value': res.data.data.educational_degrees[i].identifier_id })
            }
            setEducationDegreeList(educationalDegreesArray);
            //Gender
            let gendersArray = [];
            for (let i = 0; i < res.data.data.gender.length; i++) {
                gendersArray.push({ 'label': res.data.data.gender[i].label_name, 'value': res.data.data.gender[i].identifier_id })
            }

            //Universities
            let universitiesArray = [];
            for (let i = 0; i < res.data.data.universities.length; i++) {
                universitiesArray.push({ 'label': res.data.data.universities[i].label_name, 'value': res.data.data.universities[i].identifier_id })
            }
            setEducationalInstituteList(universitiesArray)
            // work place
            let workPlaceArray = [];
            for (let i = 0; i < res.data.data.workplaces.length; i++) {
                workPlaceArray.push({ 'label': res.data.data.workplaces[i], 'value': res.data.data.workplaces[i] })
            }
            setPlaceOfWorkList(workPlaceArray);
            let newarray = res.data.data.provinces;
            newarray = Object.keys(newarray);
            setProvinceData(newarray);
            setProfileData()
        } else {
            Swal.fire({
                icon: 'error',
                text: 'Post Update Failure',
                timer: 3000
            })
        }
    }
    const goBack = () => {
        navigate('/admin/pendingrequest')
    }
    if (primarySpeciality.length > 1) {
        primarySpeciality = primarySpeciality.slice(1);
    }
    if (educationalDegree.length > 1) {
        educationalDegree = educationalDegree.slice(1);
    }
    if (secondarySpeciality.length > 1) {
        secondarySpeciality = secondarySpeciality.slice(1);
    }
    if (gender.length > 1) {
        gender = gender.slice(1);
    }
    if (selectedRole.length > 1) {
        selectedRole = selectedRole.slice(1);
    }
    // get data
    const getUserData = async (user) => {
        var data = {
            email: user,
            get_user_data: true,
            preferred_language: 'EN'
        };
        let res = await dispatch(apiCall(data, '/user/approve'));
        if (res.data.message.MSG_CODE === 100441) {
            setPorfileDetails(res.data.data);
        } else if (res.data.message.MSG_CODE === 100020) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                text: "User does not exist",
                timer: 3000,
                showConfirmButton: false,
            })
        }
    }
    const setPhoneNumber = (e) => {
        setPorfileDetails({ ...profileDetails, phone: e })
    }
    const changeId = (e)=>{
        console.log(e)
    }
    const changePl = (e)=>{
        console.log(e.target.value)
        // setProfessionalLicenseNumber
    }
   const removeSpeciality = (index, value) => {
         let otherInt = otherSpecialities.filter(e => {
            return e.label !== value.label
        })
        setOtherSpecialities(otherInt)
        
   }
    const MultipleSpeciality = otherSpecialities.map((item, index) => 
    // <div key={index} style={{ marginRight : "15px" , marginTop : "10px" ,background:'#0096fb', whiteSpace: 'pre-wrap', borderRadius : "10px" , color : "#fff"}}>  

                    <div key={index} className='me-3 mt-2 rounded-3 text-light' style={{whiteSpace: 'pre-wrap',background:'#0096fb'}}>  
                        <span > {item.label}  </span> 
                        <span className='deletePost' onClick={() => { removeSpeciality(index , item) }} style={{ color: 'white', cursor: 'pointer' }} ><AiIcons.GiCancel />  </span>
                    </div>
            );
    return (
        <>
            <section>
                <Row style={{ height: '100vh' }}>
                    <Col className='' xxl={2} xl={2} lg={3} md={3} style={{ background: 'linear-gradient(90deg, #277AFC 0%, #27B3FC 100%)', height: '100%' }} >
                        <LeftSideBar />
                    </Col>
                    <Col className='bg-prime p-0 userInformation' xxl={10} xl={10} lg={9} md={9} style={{ overflow: 'auto' }}>
                        <Container className='log-wrap p-0 d-flex justify-content-center' fluid style={{ height: '100vh' }}>
                            <>
                                <section className='w-100'>
                                    <div className='editUserInfotScreenHeading'>
                                        <h4>Edit User Information </h4>
                                    </div>
                                    <Row className='editUserInfoScreenBoxEdit' xxl={8} xl={8} lg={8} md={8}>
                                        <Col className='profilePhotoDiv' xxl={5} xl={5} lg={5} md={5}>
                                            <div className=''><img src={profilePhoto} className='editProfilePhoto' alt='logo' /></div>

                                            <div className='d-flex justify-content-center AdminUploadOptionDiv' style={{ background: isImageUpload === true ? '#389ffe5c' : '', flex: 1 }}>
                                                <img className='mx-2' src={image.galleryImage} style={{ width: '1.4em' }} alt='galleryImage' />
                                                <input type="file" id="uploadImageInputDiv" name="avatar" accept=" image/png, image/jpeg" onChange={(event) => uploadFile(event.target.files, 'image')} />
                                                <span className='uploadOptionDivName'>Profile Picture</span>
                                            </div>
                                        </Col>
                                        {
                                        selectedRole[0] && selectedRole[0] &&  selectedRole[0].value && selectedRole[0] &&  selectedRole[0].value === 'STUDENT' &&
                                        <Col className='profilePhotoDiv' xxl={5} xl={5} lg={5} md={5}>
                                            <div className=''><img src={cardPhoto[cardPhoto.length - 1]} className='editProfilePhoto' alt='logo' /></div>

                                            <div className='d-flex justify-content-center AdminUploadOptionDiv' style={{ background: isImageUpload1 === true ? '#389ffe5c' : '', flex: 1 }}>
                                                <img className='mx-2' src={image.galleryImage} style={{ width: '1.4em' }} alt='galleryImage' loading="lazy"/>
                                                <input type="file" id="uploadImageInputDiv" name="avatar" accept=" image/png, image/jpeg" onChange={(event) => uploadFile1(event.target.files, 'image')} />
                                               
                                               <span className='uploadOptionDivName'>ID Picture</span>
                                               
                                               
                                            </div><span className="redStar">&#42;</span>
                                        </Col>
                                        }
                                         {
                                         selectedRole[0] && selectedRole[0] &&  selectedRole[0].value && selectedRole[0] &&  selectedRole[0].value === 'DOCTOR' &&
                                        <Col className='profilePhotoDiv' xxl={5} xl={5} lg={5} md={5}>
                                            <div className=''><img src={cardPhoto1[cardPhoto1.length - 1]} className='editProfilePhoto' alt='logo' loading="lazy"/></div>

                                            <div className='d-flex justify-content-center AdminUploadOptionDiv' style={{ background: isImageUpload2 === true ? '#389ffe5c' : '', flex: 1 }}>
                                                <img className='mx-2' src={image.galleryImage} style={{ width: '1.4em' }} alt='galleryImage' />
                                                <input type="file" id="uploadImageInputDiv" name="avatar" accept=" image/png, image/jpeg" onChange={(event) => uploadFile2(event.target.files, 'image')} />
                                               
                                               <span className='uploadOptionDivName'>License Picture</span>
                                               
                                               
                                            </div><span className="redStar">&#42;</span>
                                        </Col>
                                        }
                                           {
                                                profileDetails &&  (profileDetails.role === 'STUDENT' || profileDetails.role === 'DOCTOR') &&
                                           <Col className='my-2' xxl={5} xl={5} lg={5} md={5} >
                                            <h6>Role <span className="redStar">&#42;</span> </h6>
                                            {
                                                    <>
                                                        <MultiSelect
                                                              options={roleList}
                                                              value={selectedRole}
                                                              onChange={setSelectedRole}
                                                            labelledBy="Select"
                                                            hasSelectAll={false}
                                                        />
                                                    </> 
                                            }
                                        </Col>
                                        }
                                        <Col className='my-1' xxl={5} xl={5} lg={5} md={5} >
                                            <h6>
                                                Full Name <span className="redStar">&#42;</span>
                                            </h6>
                                            <input type="text" className="form-control" placeholder="Fullname"
                                                aria-label="Title" aria-describedby="basic-addon1"
                                                value={fullName}
                                                onChange={(e) => setFullName(e.target.value)}
                                            />
                                        </Col>
                                        <Col className='my-1' xxl={5} xl={5} lg={5} md={5} >
                                            <h6>
                                                Email <span className="redStar">&#42;</span>
                                            </h6>
                                            <input type="text" className="form-control" placeholder="Fullname"
                                                aria-label="Title" aria-describedby="basic-addon1"
                                                value={emailData}
                                                onChange={(e) => setEmailData(e.target.value)}
                                            />
                                        </Col>
                                        <Col className='my-2' xxl={5} xl={5} lg={5} md={5} >
                                            <h6>Gender <span className="redStar">&#42;</span> </h6>
                                            {
                                                genderList !== undefined ?
                                                    <>
                                                        <MultiSelect
                                                            options={genderList}
                                                            value={gender}
                                                            onChange={setGender}
                                                            labelledBy="Select"
                                                            hasSelectAll={false}
                                                        />
                                                    </> : null
                                            }
                                        </Col>
                                        <Col className='my-2' xxl={5} xl={5} lg={5} md={5} >

                                        <h6>Primary Specialty <span className="redStar">&#42;</span> </h6>

                                            {
                                                specialitiesList !== undefined ?
                                                    <>
                                                        <MultiSelect
                                                            options={specialitiesList}
                                                            value={primarySpeciality}
                                                            onChange={setPrimarySpeciality}
                                                            labelledBy="Select"
                                                            hasSelectAll={false}
                                                        />
                                                    </> : null
                                            }
                                        </Col>
                                        <Col className='my-2' xxl={5} xl={5} lg={5} md={5} >

                                        <h6>Secondary Specialty </h6>

                                            {
                                                specialitiesList !== undefined ?
                                                    <>
                                                        <MultiSelect
                                                            options={specialitiesList}
                                                            value={secondarySpeciality}
                                                            onChange={setSecondarySpeciality}
                                                            labelledBy="Select"
                                                            hasSelectAll={false}

                                                        />
                                                    </> : null

                                            }
                                        </Col>
                                        <Col className='my-2' xxl={5} xl={5} lg={5} md={5} >

                                        <h6>Other Interests of Specialty</h6>
                                            {

                                                specialitiesList !== undefined ?
                                                    <>
                                                        <MultiSelect
                                                            options={specialitiesList}
                                                            value={otherSpecialities}
                                                            onChange={setOtherSpecialities}
                                                            labelledBy="Select"
                                                            hasSelectAll={false}

                                                        />
                                                    </> : null

                                            }
                                              {/* <div style={{display : 'flex' , flexDirection : 'row' , flexWrap : 'wrap'}}></div> */}
                                            <div className='d-flex flex-row flex-wrap'>
                                            {MultipleSpeciality}
                                           
                                            </div>
                                           
                                        </Col>
                                        <Col className='my-2 align-self-start' xxl={5} xl={5} lg={5} md={5} >
                                            <h6>Phone number <span className="redStar">&#42;</span></h6>
                                            {/* {
                                                profileDetails &&
                                                    <>
                                                        <input type="text" className="form-control" placeholder="Phone number"
                                                            aria-label="Title" aria-describedby="basic-addon1"
                                                            value={profileDetails.phone}
                                                            onChange={(e) => setPorfileDetails({ ...profileDetails, phone: e.target.value })}
                                                        />
                                                    </> 

                                            } */}
                                            {
                                                profileDetails &&
                                                <PhoneInput

                                                    country={'vn'}
                                                    placeholder="Enter phone number"
                                                    value={profileDetails.phone}
                                                    onChange={(e) => setPhoneNumber(e)} />
                                            }
                                        </Col>
                                        <Col className='my-2' xxl={5} xl={5} lg={5} md={5} >

                                        <h6>Alternate Email Address</h6>

                                            {
                                                profileDetails &&
                                                <>
                                                    <input type="text" className="form-control" placeholder="Alternate email"
                                                        aria-label="Title" aria-describedby="basic-addon1"
                                                        value={profileDetails.alternate_email}
                                                        onChange={(e) => setPorfileDetails({ ...profileDetails, alternate_email: e.target.value })}
                                                    />
                                                </>

                                            }
                                        </Col>
                                        <Col className='my-2' xxl={5} xl={5} lg={5} md={5} >
                                            <h6>Address</h6>
                                            {
                                                profileDetails &&
                                                <>
                                                    <input type="text" className="form-control" placeholder="Address"
                                                        aria-label="Title" aria-describedby="basic-addon1"
                                                        value={address}
                                                        onChange={(e) => setAddress(e.target.value)}
                                                    />
                                                </>

                                            }
                                        </Col>
                                        <Col className='my-2' xxl={5} xl={5} lg={5} md={5} >
                                            <h6>Educational Degree <span className="redStar">&#42;</span></h6>
                                            {
                                                profileDetails.educational_information &&
                                                <>
                                                    <MultiSelect
                                                        options={educationDegreeList}
                                                        value={educationalDegree}
                                                        onChange={setEducationalDegree}
                                                        labelledBy="Select"
                                                        hasSelectAll={false}
                                                    />
                                                </>
                                            }
                                        </Col>
                                        <Col className='my-2' xxl={5} xl={5} lg={5} md={5} >
                                            <h6>Educational Institute <span className="redStar">&#42;</span></h6>
                                            {
                                                profileDetails.educational_information &&
                                                <MultiSelect
                                                    options={educationalInstituteList}
                                                    value={educationalInstitute}
                                                    onChange={setEducationalInstitute}
                                                    labelledBy="Select"
                                                    hasSelectAll={false}
                                                />
                                            }
                                        </Col>
                                        <Col className='my-2 position-relative' xxl={5} xl={5} lg={5} md={5} >
                                            <h6>Province/City <span className="redStar">&#42;</span></h6>

                                            {
                                                provinceData.length > 0 ?
                                                    <Multiselect
                                                        isObject={false}
                                                        options={provinceData}
                                                        selectedValues={provinceCity}
                                                        onSelect={(e) => onProvinceClick(e)}
                                                        onRemove={(e) => onProvinceClick(e)}
                                                        selectionLimit={1}
                                                    /> : <select className="form-control selectpicker searchWrapper" id="select-country" data-live-search="true">
                                                        <option key='noCity'>select city</option>
                                                    </select>

                                            }
                                            <span style={{ position: 'absolute', right: '1rem' }} className='customDropdown'>&#8964;</span>
                                        </Col>
                                        <Col className='my-2 position-relative' xxl={5} xl={5} lg={5} md={5} >
                                            <h6>District <span className="redStar">&#42;</span></h6>
                                            {
                                                provinceCityData.length > 0 ?
                                                    <Multiselect
                                                        isObject={false}
                                                        options={provinceCityData}
                                                        selectedValues={district}

                                                        onSelect={(e) => setDistrict(e)}
                                                        onRemove={(e) => setDistrict(e)}
                                                        selectionLimit={1}
                                                    /> : <select className="form-control selectpicker" id="select-country" data-live-search="true">
                                                        <option key='noCity'>select District</option>
                                                    </select>

                                            }
                                            <span style={{ position: 'absolute', right: '1rem' }} className='customDropdown'>&#8964;</span>
                                        </Col>
                                        {/* <Col className='my-2' xxl={5} xl={5} lg={5} md={5} >
                                            <h6>Address</h6>
                                            {
                                                profileDetails && 
                                                    <>
                                                        <input type="text" className="form-control" placeholder="Address..."
                                                            aria-label="Title" aria-describedby="basic-addon1"
                                                            value={profileDetails.address}
                                                            onChange={(e) => setPorfileDetails({ ...profileDetails, address: e.target.value })}
                                                        />
                                                    </> 

                                            }
                                        </Col> */}
                                        <Col className='my-2 position-relative' xxl={5} xl={5} lg={5} md={5} >
                                            <p className='m-0'>Member of professional organisations:- <span style={{ fontSize: '12px' }}>(please press enter to add your ember of professional organisations in list)</span>  </p>
                                            {memberOfProfessionalOrg?.length > 0 ?
                                                <span>[{
                                                    memberOfProfessionalOrg.map((sponsor, index) => {
                                                        return (
                                                            <div className='tagsItem' key={index}>
                                                                <span>{sponsor}</span>
                                                                <span className='close' onClick={() => { removeTag(index) }}> &#x2717;</span>
                                                            </div>
                                                        )
                                                    })
                                                }]</span> : null
                                            }
                                            <input type="text" className="form-control"
                                                placeholder="Member of professional organisations " aria-label="Title"
                                                aria-describedby="basic-addon1"
                                                onKeyDown={sponsporArrayPush}
                                            />
                                        </Col>

                                        <Col className='my-1 align-self-start' xxl={5} xl={5} lg={5} md={5} >
                                            <h6>
                                                Average patients per day
                                            </h6>
                                            <input type="number" className="form-control" placeholder="Avg patients per day"
                                                aria-label="Title" aria-describedby="basic-addon1"
                                                value={avgPatients}
                                                onChange={(e) => setAvgPatients(e.target.value)}
                                            />
                                        </Col>
                                        {
                                         selectedRole[0] && selectedRole[0] &&  selectedRole[0].value && selectedRole[0] &&  selectedRole[0].value === 'DOCTOR' &&
                                        <Col className='my-2' xxl={5} xl={5} lg={5} md={5} >

                                                <>
                                                <h6>Place of work<span className="redStar">&#42;</span></h6>
                                                {
                                                    profileDetails &&
                                                    <MultiSelect
                                                        options={placeOfWorkList}
                                                        value={placeOfWork}
                                                        onChange={setPlaceOfWork}
                                                        labelledBy="Select"
                                                        hasSelectAll={false}
                                                    />
                                                 }
                                                 </>
                                  

                                        </Col>
                                        }            
                                     
                                        {
                                            selectedRole[0] && selectedRole[0] &&  selectedRole[0].value && selectedRole[0] &&  selectedRole[0].value === 'STUDENT' &&

                                        <Col className='my-2' xxl={5} xl={5} lg={5} md={5} >
                                            <h6>Student Id <span className="redStar">&#42;</span></h6>
                           
                                                <>
                                                    <input type="text" className="form-control" placeholder="Student Id number"
                                                        aria-label="Title" aria-describedby="basic-addon1"
                                                        value={studentId}
                                                        onChange={(e) => setStudentId(e.target.value)}
                                                    />
                                                </>

                                           
                                        </Col>
                                    }

                                         {
                                               selectedRole[0] && selectedRole[0] &&  selectedRole[0].value && selectedRole[0] &&  selectedRole[0].value === 'DOCTOR' &&
                                        <Col className='my-2' xxl={5} xl={5} lg={5} md={5} >
                                            <h6>Professional License Number <span className="redStar">&#42;</span></h6>
                           
                                                <>
                                                    <input type="text" className="form-control" placeholder="Student Id number"
                                                        aria-label="Title" aria-describedby="basic-addon1"
                                                        value={professionalLicenseNumber}
                                                        onChange={(e) => setProfessionalLicenseNumber(e.target.value)}
                                                    />
                                                </>

                                           
                                        </Col>
                                    }
                                            <Col className='my-2' xxl={5} xl={5} lg={5} md={5} >
                                                <h6>Bio</h6>
                                                {
                                                    profileDetails &&
                                                    <>
                                                        <input type="text" className="form-control" placeholder="Bio..."
                                                            aria-label="Title" aria-describedby="basic-addon1"
                                                            value={profileDetails.bio}
                                                            onChange={(e) => setPorfileDetails({ ...profileDetails, bio: e.target.value })}
                                                        />
                                                    </>
                                                }
                                        </Col>
                                        {/* <Col className='my-2' xxl={5} xl={5} lg={5} md={5} >
                                            <h6>Role <span className="redStar">&#42;</span> </h6>
                                            {
                                                roleList !== undefined ?
                                                    <>
                                                        <MultiSelect
                                                            options={roleList}
                                                            value={selectedRole}
                                                            onChange={setSelectedRole}
                                                            labelledBy="Select"
                                                            hasSelectAll={false}
                                                        />
                                                    </> : null
                                            }
                                        </Col> */}
                                        {/* <Col className='my-2' xxl={5} xl={5} lg={5} md={5} >
                                            <h6>Student ID number</h6>
                                            {
                                                profileDetails && 
                                                    <>
                                                        <input type="number" className="form-control" placeholder="Alternate email"
                                                            aria-label="Title" aria-describedby="basic-addon1"
                                                            value={profileDetails.student_id_number}
                                                            onChange={(e) => setPorfileDetails({ ...profileDetails, student_id_number: e.target.value })}
                                                        />
                                                    </> 
                                            }
                                        </Col> */}
                                        <Col className='my-2' style={{ float: 'right' }} xxl={10} xl={10} lg={10} md={10}>
                                            <Button className='mx-2' onClick={goBack} style={{ background: 'white', color: 'blue' }}>Cancel</Button>
                                            <Button variant="primary" style={{ height: '2.3rem' }} onClick={() => { UpdateUserInfoData() }} >Update</Button>
                                        </Col>
                                    </Row>
                                </section>
                            </>
                        </Container>
                    </Col>
                </Row>
            </section>
        </>
    )
}
export default UserInfoEdit
import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import MediaClicks from './MediaClicks'
import MediaLikes from './MediaLikes'
import MediaViews from './MediaViews'
const MediaInteraction = () => {
  return (
    <>
      <div className='p-0' style={{height:'100%'}}>
          <Container className='log-wrap p-0 w-100 my-1' fluid  style ={{height:'100%'}}>
          <React.Fragment>
            <Row lg md sm xl = {12} className = 'w-100 py-3 align-items-center justify-content-around' style ={{height:'100%'}}>
              <Col xxl={3} xl={3} lg={4} md ={4} style ={{height:'6rem'}} className = 'my-2' role ='button'>
                <MediaClicks/>
              </Col >
              <Col xxl={3} xl={3} lg={4} md ={4} style ={{height:'6rem'}} className = 'my-2' role ='button' >
                <MediaViews/>
              </Col>
              <Col xxl={3} xl={3} lg={4} md ={4} style ={{height:'6rem'}} className = 'my-2' role ='button'>
                <MediaLikes/>
              </Col>
              {/* <Col lg = {3} xl = {2} md={6} style ={{height:'6rem'}} className = 'my-2' role ='button'>
                <MediaComments/>
              </Col> */}
            </Row>
          </React.Fragment>
          </Container>
      </div>
    </>

  )
}

export default MediaInteraction
import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import CloseButton from 'react-bootstrap/CloseButton';
import * as AiIcons from 'react-icons/ai';
import ReactPaginate from 'react-paginate';
import {apiCall } from '../../../../../action/loginAction';
import './AddPermission.css'
import Badge from 'react-bootstrap/Badge';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import {actionpermission} from '../../../../../constant/commonFunctions'
import { useSelector } from 'react-redux';

const AddPermission = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10)
  const [permissionList, setPermissionList] = useState([]);
  const [pageData, setPageData] = useState({});
  const [permissionModal, setPermissionModal] = useState(false);
  const [editPermissionModal, setEditPermissionModal] = useState(false);
  const [permissionData, setPermissionData] = useState({});
  const animatedComponents = makeAnimated();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedMenuOption, setSelectedMenuOption] = useState(null);
  const [selectedAddUserOption, setSelectedAddUserOption] = useState(null);
  const [selectedChannelsOption, setSelectedChannelsOption] = useState(null);
  const [selectedIdOption, setSelectedIdOption] = useState(null);
  const [editPermissionData, setEditPermissionData] = useState({});
  const [updatedListnData, setUpdatedListnData] = useState({});
  const [editPermissionId, setEditPermissionId] = useState(null);
  const userPermissions = JSON.parse(localStorage.getItem('role_permission'))
  const [permissions , setPermissions]=useState([])
  const [rolePermissionName , setRolePermissionName]=useState('')
  const { isUpdateRole } = useSelector(state => state.role);
  const {isDelete} =  useSelector(state => state.role);

  useEffect(() => {
    getPermissionData()
    let permissionsArray = actionpermission("User Management")
    setPermissions(permissionsArray)
   if (isUpdateRole === true) {
    dispatch({ type: "newRoleList"});
   }
   if (isDelete === true){
    dispatch({ type: "newPermissionList"});
   }
  }, [page,isUpdateRole,isDelete])
  //get role data
  const getPermissionData = async () => {
    var data = {
      get_permission: true,
      per_page: perPage,
      page_number: page,
      preferred_language: 'EN'
    };
   let res = await dispatch(apiCall(data,'/user/permission-management'));
    if (res.data.message.MSG_CODE === 100811) {
      let newArray = res.data.data;
      newArray = newArray.map(v => ({ ...v, isActive: false }))
      setPermissionList(newArray);
      setPageData(res.data.pagination);
    }else  if (res.data.message.MSG_CODE === 100130){
     
    }
    else {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again!',
        timer: 4000
      })
    }
  }
  //pagination
  const handlePageClick = (data) =>{
    let currentPage = data.selected + 1 ;
    setPage(currentPage)
  }
  //empty 
  const empty = () => {
    setPermissionData({})
    setPermissionModal(false);
    setEditPermissionModal(false)
    setSelectedMenuOption(null)
    setSelectedOption(null)
    setSelectedAddUserOption(null)
    setSelectedChannelsOption(null)
    setSelectedIdOption(null)
    setEditPermissionData({})
    setEditPermissionId(null)
    setUpdatedListnData({})
    setRolePermissionName('')
  }
  // show modal
  const showPermissionModal = (data,name) => {
    setRolePermissionName(name)
    setPermissionData(data)
    setTimeout(() => {
      setPermissionModal(true)
    }, 200)
  }
  // show edit modal
  const showEditPermissionModal = (permissionDataVal,id, name) => {
    setRolePermissionName(name)
    setUpdatedListnData(permissionDataVal);
    setEditPermissionId(id)
    let data = structuredClone(permissionDataVal)
    let updateActionList = [];
    let updateMenuList = [];
    let updateUseruList = [];
    let updateChannelList = [];
    let updateIdList = [];

    let optionForActionList = userPermissions.action_permission.filter(function (obj) { return data.action_permission.indexOf(obj) === -1; });
    let optionForMenuList = userPermissions.menu_permission.filter(function (obj) { return data.menu_permission.indexOf(obj) === -1; });
    let optionForAddUserList = data.internal_permission.ADD_USER === undefined ? userPermissions.internal_permission.ADD_USER : userPermissions.internal_permission.ADD_USER.filter(function (obj) { return data.internal_permission.ADD_USER.indexOf(obj) === -1; });
    let optionForChannelList = data.internal_permission.Channels === undefined ? userPermissions.internal_permission.Channels : userPermissions.internal_permission.Channels.filter(function (obj) { return data.internal_permission.Channels.indexOf(obj) === -1; });
    let optionForIDList = data.internal_permission.ID_View === undefined ? userPermissions.internal_permission.ID_View : userPermissions.internal_permission.ID_View.filter(function (obj) { return data.internal_permission.ID_View.indexOf(obj) === -1; });


    for (let x in data.action_permission) {
      updateActionList.push({
        value: data.action_permission[x],
        label: data.action_permission[x]
      })
    }
    for (let y in data.menu_permission) {
      updateMenuList.push({
        value: data.menu_permission[y],
        label: data.menu_permission[y]
      })
    }
    if (data.internal_permission.ADD_USER && data.internal_permission.ADD_USER.length > 0) {
      for (let y in data.internal_permission.ADD_USER) {
        updateUseruList.push({
          value: data.internal_permission.ADD_USER[y],
          label: data.internal_permission.ADD_USER[y]
        })
      }
      data.internal_permission.ADD_USER = updateUseruList;
      setSelectedAddUserOption(updateUseruList)
    }
    if (data.internal_permission.Channels && data.internal_permission.Channels.length > 0) {
      for (let y in data.internal_permission.Channels) {
        updateChannelList.push({
          value: data.internal_permission.Channels[y],
          label: data.internal_permission.Channels[y]
        })
      }
      data.internal_permission.Channels = updateChannelList;
      setSelectedChannelsOption(updateChannelList)
    }
    if (data.internal_permission.ID_View && data.internal_permission.ID_View.length > 0) {
      for (let y in data.internal_permission.ID_View) {
        updateIdList.push({
          value: data.internal_permission.ID_View[y],
          label: data.internal_permission.ID_View[y]
        })
      }
      data.internal_permission.ID_View = updateIdList;
      setSelectedIdOption(updateIdList)
    }
    data.action_permission = updateActionList;
    data.menu_permission = updateMenuList;
    setSelectedOption(updateActionList)
    setSelectedMenuOption(updateMenuList)

    let new_array_action = structuredClone(updateActionList);
    let new_array_menu = structuredClone(updateMenuList);
    var new_array_add_user = structuredClone(updateUseruList);
    var new_array_channel_list = structuredClone(updateChannelList);
    var new_array_id_list = structuredClone(updateIdList);
    if (optionForActionList && optionForActionList.length > 0) {
      for (let i in optionForActionList) {
        new_array_action.push({
          value: optionForActionList[i],
          label: optionForActionList[i]
        })
      }
    }
    if (optionForMenuList && optionForMenuList.length > 0) {
      for (let j in optionForMenuList) {
        new_array_menu.push({
          value: optionForMenuList[j],
          label: optionForMenuList[j]
        })
      }
    }
    if (optionForAddUserList && optionForAddUserList.length > 0) {
      for (let j in optionForAddUserList) {
        new_array_add_user.push({
          value: optionForAddUserList[j],
          label: optionForAddUserList[j]
        })
      }
    }
    if (optionForChannelList && optionForChannelList.length > 0) {
      for (let j in optionForChannelList) {
        new_array_channel_list.push({
          value: optionForChannelList[j],
          label: optionForChannelList[j]
        })
      }
    }
    if (optionForIDList && optionForIDList.length > 0) {
      for (let j in optionForIDList) {
        new_array_id_list.push({
          value: optionForIDList[j],
          label: optionForIDList[j]
        })
      }
    }
    data['new_action_permission'] = new_array_action;
    data['new_menu_permission'] = new_array_menu;
    data['new_array_add_user'] = new_array_add_user;
    data['new_array_channel_list'] = new_array_channel_list;
    data['new_array_id_list'] = new_array_id_list;
    setEditPermissionData(data)
    setTimeout(() => {
      setEditPermissionModal(true)
    }, 300)
  }
  // updat permission
  const updatePermission = async () => {
    if (editPermissionData.action_permission && editPermissionData.action_permission.length >= 0) {
      updatedListnData.action_permission = []
      for (let x in selectedOption) {
        updatedListnData.action_permission.push(selectedOption[x].value);
      }
    }
    if (editPermissionData.menu_permission && editPermissionData.menu_permission.length >= 0) {
      updatedListnData.menu_permission = []
      for (let x in selectedMenuOption) {
        updatedListnData.menu_permission.push(selectedMenuOption[x].value);
      }
    }
    if (editPermissionData.internal_permission.ADD_USER && editPermissionData.internal_permission.ADD_USER.length >= 0) {
      updatedListnData.internal_permission.ADD_USER = []
      for (let x in selectedAddUserOption) {
        updatedListnData.internal_permission.ADD_USER.push(selectedAddUserOption[x].value);
      }
    }
    if (editPermissionData.internal_permission.Channels && editPermissionData.internal_permission.Channels.length >= 0) {
      updatedListnData.internal_permission.Channels = []
      for (let x in selectedChannelsOption) {
        updatedListnData.internal_permission.Channels.push(selectedChannelsOption[x].value);
      }
    }
    if (editPermissionData.internal_permission.ID_View && editPermissionData.internal_permission.ID_View.length >= 0) {
      updatedListnData.internal_permission.ID_View = []
      for (let x in selectedIdOption) {
        updatedListnData.internal_permission.ID_View.push(selectedIdOption[x].value);
      }
    }
    var data = {
      is_edit_permission: true,
      permissions: updatedListnData,
      role_id:editPermissionId,
      preferred_language: 'EN'
    };
   let res = await dispatch(apiCall(data,'/user/permission-management'));
    if (res.data.message.MSG_CODE === 100831) {
      Swal.fire({
        icon: 'success',
        text: 'Permission updated successfully!',
        timer: 4000
      })
       empty();
       getPermissionData();
    }else  if (res.data.message.MSG_CODE === 100130){
     
    }
    else {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again!',
        timer: 4000
      })
    }

  }
  return (
    <>
      {
        permissionList.length > 0 &&
        <section className='px-3 pt-3 p-4 w-100' style={{ height: '100%', backgroundColor: '' }} >
          <div className='tableRolePermissionClass'>
            <Table hover striped size="sm" className='table-responsive'>
              <thead>
                <tr>
                  <th className='w-25 px-2' >ID</th>
                  <th className='w-25' >Role</th>
                  <th className='w-25' >Permissions</th>
                  {permissions.includes('Add Permission') && <th className='w-25' >Edit</th>}
                </tr>
              </thead>
              <tbody>
                {
                  permissionList.map((role, index) => {
                    return (
                      <>
                        <tr key={index} className='roleItem' >
                          <td className='w-25'>
                            <div className='d-flex align-items-center px-2'>
                              <span className=''>{role.role_id}</span>
                            </div>
                          </td>
                          <td className=' w-25'>{role.role_name}</td>
                          <td role="button" className=' w-25  text-primary'><span onClick={() => showPermissionModal(role.permissions,role.role_name)} >View</span></td>
                          {permissions.includes('Add Permission') &&  <td className='w-25 '>
                            <span className='editIcon mx-2' onClick={() => showEditPermissionModal(role.permissions,role.role_id, role.role_name)}>
                              <AiIcons.AiFillEdit />
                            </span>
                          </td>}
                        </tr>
                      </>
                    )
                  })
                }
              </tbody>
            </Table>
            {
              pageData.total_page > 1 && (
                <ReactPaginate
                  className='pagination'
                  breakLabel= {'...'}
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={2}
                  pageCount={pageData.total_page}
                  previousLabel="< prev"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  activeClassName={'active'}
                  pageClassName = {'page-item'}
                  previousClassName = {'page-item'}
                  previousLinkClassName = {'page-link'}
                  pageLinkClassName = {'page-link'}
                  nextClassName = {'page-item'}
                  nextLinkClassName = { 'page-link'}
                  breakClassName = {'page-item'}
                  breakLinkClassName = {'page-link'}
                />
              )
            }
          </div>
        </section>
      }
      <Modal
        show={permissionModal}
        size="lg"
        centered
        backdrop="static"
        className='justify-content-between'
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '0.5em' }}>
            Role Permission of {rolePermissionName}
          </Modal.Title>
          <CloseButton onClick={() => empty()} />
        </Modal.Header>
        <Modal.Body>
          <>
            <div className='my-1'>
              <div>
                <h6 className='mb-1 mx-2'>
                  Action Permissions
                </h6>
                <div>
                  {
                    permissionData.action_permission && permissionData.action_permission.map((action, index) => {
                      return (
                        <>
                          <Badge align="center" bg="primary" className='mx-2 my-1 py-2 roleBadge' key={index}>
                            {action}
                          </Badge>
                        </>
                      )
                    })
                  }
                </div>
              </div>
              <div>
                <h6 className='mb-1 mt-3 mx-2'>
                  Menu Permissions
                </h6>
                <div>
                  {
                    permissionData.menu_permission && permissionData.menu_permission.map((action, index) => {
                      return (
                        <>
                          <Badge align="center" bg="primary" className='mx-2 my-1 py-2 roleBadge' key={index}>
                            {action}
                          </Badge>
                        </>
                      )
                    })
                  }
                </div>
              </div>
              <div>
                <h6 className='mb-1 mt-3 mx-2'>
                  Internal Permissions
                </h6>
                {
                  permissionData.internal_permission &&
                  <div>
                    {
                      permissionData.internal_permission.ADD_USER && permissionData.internal_permission.ADD_USER.length > 0 &&
                      <div>
                        <span className='internalPermission  mx-2'>Add User</span>
                        <div>
                          {
                            permissionData.internal_permission.ADD_USER && permissionData.internal_permission.ADD_USER.map((user, index) => {
                              return (
                                <>
                                  <Badge align="center" bg="primary" className='mx-2 my-1 py-2 roleBadge' key={index}>
                                    {user}
                                  </Badge>
                                </>
                              )
                            })
                          }
                        </div>
                      </div>
                    }
                    {
                      permissionData.internal_permission.Channels && permissionData.internal_permission.Channels.length > 0 &&
                      <div>
                        <span className='internalPermission mx-2'>Channels</span>
                        <div>
                          {
                            permissionData.internal_permission.Channels && permissionData.internal_permission.Channels.map((channel, index) => {
                              return (
                                <>
                                  <Badge align="center" bg="primary" className='mx-2 my-1 py-2 roleBadge' key={index}>
                                    {channel}
                                  </Badge>
                                </>
                              )
                            })
                          }
                        </div>
                      </div>
                    }
                    {
                      permissionData.internal_permission.ID_View && permissionData.internal_permission.ID_View.length > 0 &&
                      <div>
                        <span className='internalPermission mx-2'>ID View</span>
                        <div>
                          {
                            permissionData.internal_permission.ID_View && permissionData.internal_permission.ID_View.map((id, index) => {
                              return (
                                <>
                                  <Badge align="center" bg="primary" className='mx-2 my-1 py-2 roleBadge' key={index}>
                                    {id}
                                  </Badge>
                                </>
                              )
                            })
                          }
                        </div>
                      </div>
                    }
                  </div>
                }

              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
      <Modal
        show={editPermissionModal}
        size="lg"
        centered
        backdrop="static"
        className='justify-content-between'
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '0.5em' }}>
            Role Permission of {rolePermissionName}
          </Modal.Title>
          <CloseButton onClick={() => empty()} />
        </Modal.Header>
        <Modal.Body>
          <>
            <div className='my-1'>
              <div>
                <h6 className='mb-1 mx-2'>
                  Action Permissions
                </h6>
                <div>
                  {
                    editPermissionData.action_permission &&
                    <Select
                      closeMenuOnSelect={false}
                      defaultValue={selectedOption}
                      onChange={setSelectedOption}
                      components={animatedComponents}
                      isMulti
                      options={editPermissionData.new_action_permission}
                    />
                  }
                </div>
              </div>
              <div>
                <h6 className='mb-1 mt-3 mx-2'>
                  Menu Permissions
                </h6>
                <div>
                  {
                    editPermissionData.menu_permission &&
                    <Select
                      options={editPermissionData.new_menu_permission}
                      value={selectedMenuOption}
                      onChange={setSelectedMenuOption}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isSearchable={true}
                      isMulti
                    />
                  }
                </div>
              </div>
              <div>
                <h6 className='mb-1 mt-3 mx-2'>
                  Internal Permissions
                </h6>
                {
                  editPermissionData.internal_permission &&
                  <div>
                    {
                      editPermissionData.internal_permission.ADD_USER && editPermissionData.internal_permission.ADD_USER.length >= 0 &&
                      <div>
                        <span className='internalPermission'>Add User</span>
                        <div>
                          {
                            editPermissionData.internal_permission.ADD_USER &&
                            <Select
                              options={editPermissionData.new_array_add_user}
                              value={selectedAddUserOption}
                              onChange={setSelectedAddUserOption}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              isSearchable={true}
                              isMulti
                            />
                          }
                        </div>
                      </div>
                    }
                    {
                      editPermissionData.internal_permission.Channels && editPermissionData.internal_permission.Channels.length >= 0 &&
                      <div>
                        <span className='internalPermission mx-2'>Channels</span>
                        <div>
                          {
                            editPermissionData.internal_permission.Channels &&
                            <Select
                              options={editPermissionData.new_array_channel_list}
                              value={selectedChannelsOption}
                              onChange={setSelectedChannelsOption}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              isSearchable={true}
                              isMulti
                            />
                          }
                        </div>
                      </div>
                    }
                    {
                      editPermissionData.internal_permission.ID_View && editPermissionData.internal_permission.ID_View.length >= 0 &&
                      <div>
                        <span className='internalPermission mx-2'>ID View</span>
                        <div>
                          {
                            editPermissionData.internal_permission.ID_View &&
                            <Select
                              options={editPermissionData.new_array_id_list}
                              value={selectedIdOption}
                              onChange={setSelectedIdOption}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              isSearchable={true}
                              isMulti
                            />
                          }
                        </div>
                      </div>
                    }
                  </div>
                }

              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn btn-primary' onClick={() => updatePermission()}> Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddPermission
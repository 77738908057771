import imageCompression from "browser-image-compression";

const imageSize = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const promise = new Promise((resolve, reject) => {
      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          const height = this.height;
          const width = this.width;
          resolve({ width, height });
        };
  
        image.onerror = reject;
      };
    });
  
    return promise;
  };

  const NumberOfIterations = async (fileSize) => {
    return fileSize > 3 ?  15 : 10
   }
 export const compressionImage = async (file)=>{
     const originalFileSize = file.size/1024/1024
     const imageDimensions = await imageSize(file);
     const options = {
       maxSizeMB: 0.5,
       maxWidthOrHeight:
         imageDimensions?.width,
       useWebWorker: true,
       maxIteration : await NumberOfIterations(originalFileSize)
     };
     const compressedImg = await imageCompression(file, options); 
     return compressedImg;
 }


import React from 'react'
import TopNavBar from './TopNavBar'
import Container from 'react-bootstrap/Container'
import './RightSide.css'
import MediaInteraction from '../MediaInteraction/MediaInteraction'
import UserActivity from '../Activity/UserActivity'
const RightAdminPannel = () => {
  return (
    <>  
                <Container className='log-wrap p-0' fluid>
                        <section className='py-2 topNavBar' style={{height:'7vh'}}>
                            <TopNavBar/>
                        </section>
                        <div className='' style={{height:'90vh'}}>
                            <section className ='' style={{paddingLeft:'4em',marginTop:''}}> 
                                <MediaInteraction/>
                            </section>
                            <section  className ='adminPostData' style={{paddingLeft:'4em'}}>
                                <UserActivity/>       
                            </section>
                        </div>
                </Container>
    </>
    
  )
}

export default RightAdminPannel
import React, { useEffect, useState, useRef } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LeftSideBar from '../../SideBar/LeftSideBar';
import { useDispatch } from 'react-redux';
import { apiCall } from '../../../../action/loginAction';
import Swal from 'sweetalert2';
import './Analytics.css'
import { useNavigate } from "react-router-dom";
import {actionpermission} from '../../../../constant/commonFunctions'
const Analytics = () => {
  const dispatch = useDispatch();
  const [analyticsDataList, setAnalyticsDataList] = useState({})
  const [statisticsDataList, setStatisticsDataList] = useState({})
  const navigate = useNavigate();
  const [isDataRecieved, setIsDataRecieved] = useState(false);
  const [permissions , setPermissions]=useState([])
  const email = localStorage.getItem('email')
  const role = localStorage.getItem('role')


  //get list when page is loaded
  useEffect(() => {
    getAnalytics()
    getStatistics()
    let permissionsArray = actionpermission("Analytics")
    setPermissions(permissionsArray)

  }, [])
  //get analytics data 
  const getAnalytics = async () => {
    var data = {
      get_user_stats: true,
      email,
      role
    };
    let res = await dispatch(apiCall(data,'/user/user-stats'));
    setIsDataRecieved(true)
    if (res.data.message.MSG_CODE === 100731) {
      setAnalyticsDataList(res.data.data)
    }
    else {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again!',
        timer: 4000
      })
    }
  }
  const getStatistics = async () => {
    var data = {
      get_post_stats: true,
      email,
      role
    };
    let res = await dispatch(apiCall(data,'/user/user-stats'));
    if (res.data.message.MSG_CODE === 100751) {
      setStatisticsDataList(res.data.data)
    }
    else {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong please try again!',
        timer: 4000
      })
    }
  }
  const navigateToPostStatistics = ()=>{
    navigate('/admin/analytics/post-statistics')
  }
  return (
    <>
      <section>
        <Row style={{ height: '100vh' }} xxl={12} xl={12} lg={12} md={12}>
          <Col className='' xxl={2} xl={2} lg={3} md={3} style={{ background: 'linear-gradient(90deg, #277AFC 0%, #27B3FC 100%)', height: '100%' }} >
            <LeftSideBar />
          </Col>
          <Col className='bg-prime p-0 adminPannel' xxl={10} xl={10} lg={9} md={9}>
            <Container className='log-wrap p-0' fluid style={{ height: '100vh' }}>
              <>

                  <section className='px-3 pt-3 p-4' style={{ height: '100%' , overflow:'scroll' }} >
                    <div>
                      <h5>Analytics</h5>
                      <hr></hr>
                    </div>
                    {
                    isDataRecieved &&
                      <div >
                        {
                          permissions.includes('Statistics') &&

                        <div >

                        <h6>Summary Across All Campaigns</h6>
                        <div className='statisticsDataDiv'>
                          <Row className='p-2'>
                            <Col className='my-2'  xxl={3} xl={3} lg={4} md={4}  >
                              <div className='analyticsDiv d-flex justify-content-center align-items-center ' >
                                <div></div>
                                <div>
                                  <span className='countspan'>{statisticsDataList.admin_posts}</span>
                                    <p>Admin posts</p>
                                </div>
                              </div>
                            </Col>
                            { role !=='ENTERPRISE' &&
                              <Col xxl={3} xl={3} lg={4} md={4} className='my-2' >
                              <div className='analyticsDiv d-flex justify-content-center align-items-center' >
                                <div></div>
                                <div>
                                  <span className='countspan'>{statisticsDataList.all_groups}</span>
                                  <p>All groups</p>
                                </div>
                              </div>
                            </Col>}
                            {role !=='ENTERPRISE' && <Col xxl={3} xl={3} lg={4} md={4} className='my-2'>
                              <div className='analyticsDiv d-flex justify-content-center align-items-center' >
                                <div></div>
                                <div>
                                  <span className='countspan'>{statisticsDataList.all_posts}</span>
                                  <p>All posts</p>
                                </div>
                              </div>
                            </Col>}
                            <Col xxl={3} xl={3} lg={4} md={4} className='my-2'>
                              <div className='analyticsDiv d-flex justify-content-center align-items-center' >
                                <div></div>
                                <div>
                                  <span className='countspan'>{statisticsDataList.comment_counts}</span>
                                  <p>Comment counts</p>
                                </div>
                              </div>
                            </Col>
                            <Col xxl={3} xl={3} lg={4} md={4} className='my-2'>
                              <div className='analyticsDiv d-flex justify-content-center align-items-center' >
                                <div></div>
                                <div>
                                  <span className='countspan'>{statisticsDataList.like_counts}</span>
                                  <p>Like counts</p>
                                </div>
                              </div>
                            </Col>
                            <Col xxl={3} xl={3} lg={4} md={4} className='my-2'>
                              <div className='analyticsDiv d-flex justify-content-center align-items-center' >
                                <div></div>
                                <div>
                                  <span className='countspan'>{statisticsDataList.share_counts}</span>
                                  <p>Share counts</p>
                                </div>
                              </div>
                            </Col>
                            <Col xxl={3} xl={3} lg={4} md={4} className='my-2'>
                              <div className='analyticsDiv d-flex justify-content-center align-items-center' >
                                <div></div>
                                <div>
                                  <span className='countspan'>{statisticsDataList.view_counts}</span>
                                  <p>Click counts</p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        </div>
                        }
                        {
                          permissions.includes('Active Members') &&

                        <div>

                        <h6>Active Members</h6>
                        <div className='analyticDataDiv'>
                          <Row className='p-2'>
                            <Col className='my-2' xxl={3} xl={3} lg={4} md={4} >
                              <div className='analyticsDiv d-flex justify-content-center align-items-center ' >
                                <div></div>
                                <div>
                                  <span className='countspan'>{analyticsDataList.last_1_day_login}</span>
                                  <p>Last 1 day unique login</p>
                                </div>
                              </div>
                            </Col>
                            <Col xxl={3} xl={3} lg={4} md={4} className='my-2' >
                              <div className='analyticsDiv d-flex justify-content-center align-items-center' >
                                <div></div>
                                <div>
                                  <span className='countspan'>{analyticsDataList.last_7_day_login}</span>
                                  <p>Last 7 day unique login</p>
                                </div>
                              </div>
                            </Col>
                            <Col xxl={3} xl={3} lg={4} md={4} className='my-2' >
                              <div className='analyticsDiv d-flex justify-content-center align-items-center' >
                                <div></div>
                                <div>
                                  <span className='countspan'>{analyticsDataList.last_15_day_login}</span>
                                  <p>Last 15 day unique login</p>
                                </div>
                              </div>
                            </Col>
                            <Col xxl={3} xl={3} lg={4} md={4} className='my-2'>
                              <div className='analyticsDiv d-flex justify-content-center align-items-center' >
                                <div></div>
                                <div>
                                  <span className='countspan'>{analyticsDataList.last_30_day_login}</span>
                                  <p>Last 30 day unique login</p>
                                </div>
                              </div>
                            </Col>
                            <Col xxl={3} xl={3} lg={4} md={4} className='my-2'>
                              <div className='analyticsDiv d-flex justify-content-center align-items-center' >
                                <div></div>
                                <div>
                                  <span className='countspan'>{analyticsDataList.active_users}</span>
                                  <p>Active users</p>
                                </div>
                              </div>
                            </Col>
                            <Col xxl={3} xl={3} lg={4} md={4} className='my-2'>
                              <div className='analyticsDiv d-flex justify-content-center align-items-center' >
                                <div></div>
                                <div>
                                  <span className='countspan'>{analyticsDataList.logged_in_users}</span>
                                  <p>Cumulative unique login till date</p>
                                </div>
                              </div>
                            </Col>
                            <Col xxl={3} xl={3} lg={4} md={4} className='my-2'>
                              <div className='analyticsDiv d-flex justify-content-center align-items-center' >
                                <div></div>
                                <div>
                                  <span className='countspan'>{analyticsDataList.register_users}</span>
                                  <p>Registered users</p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        </div>
                        }
                      </div>
                    }
                  </section>
              </>
            </Container>
          </Col>
        </Row>
      </section>
    </>
  )
}

export default Analytics
import React from 'react'
import Container from 'react-bootstrap/Container';
import './MediaInteraction.css'
import image from '../../../constant/image';
const MediaClicks = () => {
  return (
    <>
      <div className='p-0' style ={{height:'100%'}}>
        <Container className='log-wrap mediaBox d-flex align-items-center' fluid style ={{height:'100%'}}>
          <div className='mediaBoxContent'>
            <div className='d-flex'>
              <img role = 'button' src={image.adminPannelClick} alt= 'logo' className='mediaIconImg'/>
              <span className='my-1'>Click</span>
            </div>
            <div className='d-flex'style={{marginLeft:'1em'}}>
              <strong>10,000</strong>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default MediaClicks